import apiInstance from './maininstance';
import { from, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

export interface ApiResult {
    status: string;
    data: UserAPIData;
}

export interface UserRank {
    summonerId: string;
    summonerName: string;
    leaguePoints: number;
    rank: string;
    wins: number;
    losses: number;
    veteran: boolean;
    inactive: boolean;
    freshBlood: boolean;
    hotStreak: boolean;
}

export interface UserAPIData {
    user_data: UserData;
    score_data: ScoreData;
}

export interface ScoreData {
    leagueId: string;
    queueType: string;
    tier: string;
    rank: string;
    summonerId: string;
    summonerName: string;
    leaguePoints: number;
    wins: number;
    losses: number;
    veteran: boolean;
    inactive: boolean;
    freshBlood: boolean;
    hotStreak: boolean;
}

export interface UserData {
    id: string;
    accountId: string;
    puuid: string;
    name: string;
    profileIconId: number;
    revisionDate: number;
    summonerLevel: number;
    userProfile: string;
}

//lol-chess.net/api/user-ranks?lang=ko&tier=challenger
export function fetchUserRanks(params: Record<string, any>) {
    return from(apiInstance.get('/user-ranks', { params })).pipe(pluck('data'));
}

export function fetchUser(params: { nickname: string; lang: string }): Observable<ApiResult> {
    return from(
        apiInstance
            .get('/get-user', {
                params,
            })
            .catch((error) => {
                throw error.response;
            }),
    ).pipe(pluck('data'));
}
