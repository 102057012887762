import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import createRootReducer, { rootEpic } from './module/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { initActions } from './module/init';
import GlobalStyle from './component/GlobalStyle';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
//import { createBrowserHistory } from 'history';
//import { routerMiddleware, ConnectedRouter } from 'connected-react-router';

//export const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();
//const reduxrouterMiddleware = routerMiddleware(history);
// export const store = createStore(createRootReducer(), composeWithDevTools(applyMiddleware(epicMiddleware)));
export const store = createStore(createRootReducer(), applyMiddleware(epicMiddleware));
epicMiddleware.run(rootEpic);
store.dispatch(initActions.getinitdata());
ReactDOM.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <App />
                <GlobalStyle />
            </Provider>
        </DndProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
