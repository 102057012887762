import React from 'react';
import styled from 'styled-components';
// import Logo from '../../lib/style/logo.svg';
// import Logo from '../../lib/style/logoe_2.svg';
import Logo from '../../lib/style/logo_3.svg';

import AppStore from '../../lib/style/appstore.svg';
import PlayStore from '../../lib/style/playstore.svg';

const FooterBlock = styled.footer`
    margin-top: 5rem;
    width: 100%;
    height: 6rem;
    background: #1c1e23;
    position: relative;
    bottom: 0;
`;

const BoxBlock = styled.div`
    position: relative;
    margin-left: 10rem;
`;

const LogoBox = styled.div`
    position: absolute;
    left: 11.4rem;
    top: 1.5rem;
`;

const StoreBox = styled.div`
    display: flex;
    position: absolute;
    left: 70.8rem;
    top: 1.9rem;
`;

const Footer = () => {
    return (
        <FooterBlock>
            <BoxBlock>
                <LogoBox>
                    <a href="/">
                        <img src={Logo} alt="" />
                    </a>
                </LogoBox>
                {/* <Logo /> */}
                <StoreBox>
                    <a href="https://apps.apple.com/app/id1521193473" target="_blank">
                        <img src={AppStore} alt="" />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.teamlier.loltftpass&hl=ko"
                        target="_blank"
                    >
                        <img src={PlayStore} alt="" />
                    </a>
                </StoreBox>
            </BoxBlock>
        </FooterBlock>
    );
};

export default Footer;
