import React from 'react';
import UserInfoErrorContainer from '../../container/user/UserInfoErrorContainer';
import UserInfoErrorPageLayout from '../../component/layout/UserInfoErrorPageLayout';
import { RouteComponentProps } from 'react-router-dom';

const UserInfoErrorPage = ({ match, location }: RouteComponentProps) => {
    const { code } = match.params as any;

    return (
        <UserInfoErrorPageLayout>
            <UserInfoErrorContainer code={code} />
        </UserInfoErrorPageLayout>
    );
};

export default UserInfoErrorPage;
