import React from 'react';
import styled from 'styled-components';
import { SynergeType, Champion } from '../../lib/api/lolcheserver/InitdataApi';
// import { SynergeImageComponent } from '../common/Image';
import palette from '../../lib/style/palette';
import RecommendChampionImageonMouseEvent from '../../container/common/RecommendChampionImageonMouseEvent';
import { SynergeImgHexa } from '../common/HexaImg';

type extendsClassType = SynergeType & { parsedchampion_list: Champion[]; effects: any };
interface CommonSynergeComponentProps {
    synergeList: extendsClassType[];
}

interface SynergeCardProps {
    synerge: extendsClassType;
}

const CommonSynergeBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 15.8rem);
    grid-auto-rows: flex;
    gap: 1rem;
    /* @media (min-width: 768px) {
        width: 50%;
        .flex-item {
            width: 20%;
        }
    } */
`;

const SynergeCardBlock = styled.div`
    padding: 0.8rem 1.2rem;
    margin-bottom: 1em;
    width: 15.8rem;
    background-color: ${palette.GRAY04};
    border-radius: 0.4rem;
`;

const SynergeNameBlock = styled.div`
    display: flex;
    width: 100%;
    margin: 0.4rem 0rem;
    /* padding: 0.6rem 1.2rem; */
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    p {
        color: white;
        font-size: 0.9rem;
    }
`;

const SynergeName = styled.p`
    color: white;
    font-size: 0.9rem;
    margin-left: 0.4rem;
`;

const SynergeChampionBlock = styled.div``;

const SynergeChampionTitle = styled.p`
    font-size: 0.7rem;
    color: white;
    opacity: 0.4;
`;

const SynergeChampionImageBlock = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
`;

const SynergeChampionImageItem = styled.li`
    margin-right: 0.5rem;
`;

const CirCleBlock = styled.div`
    width: 1.5rem;
    height: 1.5rem;
`;

const SynergeCountBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 0.4rem;
    font-size: 0.6rem;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0.4);
`;

const DesciptionContent = styled.p`
    font-size: 0.7rem;
    color: white;
    line-height: 1.1rem;
    opacity: 0.8;
`;

const SynergeDescription = styled.div``;

const SynergeDescriptionTitle = styled.p`
    margin-bottom: 0.4rem;
`;

const SynergeEffect = styled.div`
    margin-top: 1rem;
    ul li {
        display: flex;
    }
`;

const SynergeEffectTitle = styled.p`
    margin-bottom: 0.4rem;
`;

const SynergeCard: React.FC<SynergeCardProps> = ({ synerge }) => {
    return (
        <SynergeCardBlock className="flex-item synerge-page1">
            <SynergeNameBlock>
                {/* <SynergeImageComponent src={synerge.image} synergetier={10} /> */}
                {/* <Hexa width={20} color={synergeTierColor[10]}> */}
                {/*     <img src={synerge.image} alt="" /> */}
                {/* </Hexa> */}
                <SynergeImgHexa width={20} tier={10} image={synerge.image} />
                <SynergeName>{synerge.name}</SynergeName>
            </SynergeNameBlock>
            <SynergeChampionBlock>
                <SynergeChampionTitle>챔피언</SynergeChampionTitle>
                <SynergeChampionImageBlock>
                    {synerge.parsedchampion_list.map((champion, index) => (
                        <SynergeChampionImageItem key={index} className="champion-image-item">
                            <RecommendChampionImageonMouseEvent
                                champion={champion}
                                width="2rem"
                                heigth="2rem"
                                isNoChampionName={true}
                            />
                        </SynergeChampionImageItem>
                    ))}
                </SynergeChampionImageBlock>
            </SynergeChampionBlock>
            <SynergeDescription>
                <SynergeDescriptionTitle>특징</SynergeDescriptionTitle>
                <DesciptionContent className="description-content">{synerge.description}</DesciptionContent>
            </SynergeDescription>
            <SynergeEffect className="synerge-effect">
                <SynergeEffectTitle className="block-title">발동포인트</SynergeEffectTitle>
                <ul>
                    {synerge.effects.map((a: any, index: number) => (
                        <li key={index}>
                            <CirCleBlock>
                                <SynergeCountBlock>{a[0]}</SynergeCountBlock>
                            </CirCleBlock>
                            <DesciptionContent>{a[1]}</DesciptionContent>
                        </li>
                    ))}
                </ul>
            </SynergeEffect>
        </SynergeCardBlock>
    );
};

const CommonSynergeComponent: React.FC<CommonSynergeComponentProps> = ({ synergeList }) => {
    return (
        <CommonSynergeBlock>
            {synergeList.map((synerge, index) => (
                <SynergeCard synerge={synerge} key={index} />
            ))}
        </CommonSynergeBlock>
    );
};

export default CommonSynergeComponent;
