import styled from 'styled-components';
import palette from '../../lib/style/palette';

const RecommendMetaPageLayout = styled.div`
    display: flex;
    justify-content: center;
    // margin-top: 2rem;
    // background-color: ${palette.GRAY01};
    min-height: 50rem;
    /* @media (min-width: 768px) {
        justify-content: center;
        align-items: center;
        
    } */
`;

export default RecommendMetaPageLayout;
