import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/style/palette';
import downarrow from '../../lib/style/MainIcon/ic_chevron_down.svg';
import { CustomSelectType } from '../../module/user';

interface NationSelectProps {
    lang: CustomSelectType;
    changeLangEvent: (lang: CustomSelectType) => void;
    setNationSelectOpen: (isopen: boolean) => void;
    setTierSelectOpen: (isopen: boolean) => void;
    isNationSelectOpen: boolean;
}

const DropDownContainer = styled('div')`
    background: ${palette.GRAY04};
    border: 1px solid ${palette.GRAY06};
    box-sizing: border-box;
    border-radius: 4px;
`;

const DropDownHeader = styled('div')`
    padding: 0.5rem 0.9rem;
    height: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NationName = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
`;

const DownArrowBlock = styled.div`
    display: flex;
    margin-left: 0.1rem;
    & > img {
        width: 0.7rem;
        height: 0.7rem;
    }

    // align-items: center;
`;

const DropDownListContainer = styled('div')`
    position: absolute;
    z-index: 100;
    width: 10.5em;
`;

const DropDownList = styled('ul')`
    background: #2c3038;
    box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 10rem;
`;

const ListItem = styled('li')`
    list-style: none;
    margin-bottom: 0.8em;
    display: flex;
    justify-content: center;
    &:hover {
        background: #24272e;
    }
`;

//const options = ['KR', 'NA', 'BR', 'EUNE', 'EUW', 'JP', 'LAN', 'LAS', 'OCE', 'TR', 'RU'];
// const options = ['KR', 'NA'];
// ['jju', '', 'br1', 'oc1', '', '', 'eun1', 'euw1', 'tr1', 'la1', 'la2']
const options = [
    {
        displayname: 'KR',
        value: 'kr',
    },
    {
        displayname: 'NA',
        value: 'na1',
    },
    {
        displayname: 'JP',
        value: 'jp1',
    },
    {
        displayname: 'RU',
        value: 'ru1',
    },
    {
        displayname: 'BR',
        value: 'br1',
    },
    {
        displayname: 'OC',
        value: 'oc1',
    },
    {
        displayname: 'EUN',
        value: 'eun1',
    },
    {
        displayname: 'EUW',
        value: 'euw1',
    },
    {
        displayname: 'TR',
        value: 'tr1',
    },
    {
        displayname: 'LA1',
        value: 'la1',
    },
    {
        displayname: 'LA2',
        value: 'la2',
    },
] as CustomSelectType[];

const NationSelect: React.FC<NationSelectProps> = ({
    lang,
    changeLangEvent,
    isNationSelectOpen,
    setNationSelectOpen,
    setTierSelectOpen,
}) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const [selectedOption, setSelectedOption] = useState(null);

    const toggling = () => {
        setNationSelectOpen(!isNationSelectOpen);
        setTierSelectOpen(false);
    };

    const onOptionClicked = (option: CustomSelectType) => () => {
        changeLangEvent(option);
        setNationSelectOpen(false);
    };

    return (
        <DropDownContainer>
            <DropDownHeader onClick={toggling}>
                <NationName>{lang.displayname}</NationName>
                <DownArrowBlock>
                    <img src={downarrow} alt="" />
                </DownArrowBlock>
            </DropDownHeader>
            {isNationSelectOpen && (
                <DropDownListContainer className="liston">
                    <DropDownList>
                        {options.map((option) => (
                            <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                {option.displayname}
                            </ListItem>
                        ))}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    );
};

export default NationSelect;
