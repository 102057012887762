import React, { useEffect } from 'react';
import SynergePageLayout from '../../component/layout/SynergePageLayout';
import SynergeContainer from '../../container/synerge/SynergeContainer';
import SynergeTabLinkContainer from '../../container/synerge/SynergeTabLinkContainer';
import styled from 'styled-components';
import Meta from '../../component/common/Meta';
import ReactGa from 'react-ga';

// const SynergeTitleBlock = styled.div`
//     float: left;
//     margin-bottom: 1.2rem;
// `;
// const SynergeTitle = styled.p`
//     font-family: Noto Sans KR;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 24px;
//     line-height: 36px;
//     letter-spacing: -0.4px;
//     color: #ffffff;
//     margin-top: 1.6rem;
// `;

function SynergePage() {
    const metaData = {
        title: '시너지',
        description:
            '시너지 광신도 나무정령 닌자 대장군 무모한자 선지자 신성 영혼 용의영혼 우두머리 우화 추방자 행운 선봉대 신비술사 싸움꾼 암살자 요술사 조율자 처형자 학살자 황제 흡수자',
    };
    useEffect(() => {
        const pathName = window.location.pathname;
        ReactGa.initialize('G-49LQN3JGJ7');
        ReactGa.set({ page: pathName });
        ReactGa.pageview(pathName);
    });
    return (
        <SynergePageLayout className="synerge-page-layout">
            <Meta data={metaData} />
            {/* <SynergeTitleBlock> */}
            {/*     <SynergeTitle>시너지</SynergeTitle> */}
            {/* </SynergeTitleBlock> */}
            <div>
                <SynergeTabLinkContainer />
                <SynergeContainer />
            </div>
        </SynergePageLayout>
    );
}

export default SynergePage;
