import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import UserInfoComponent from '../../component/user/UserInfoComponent';
import { userActions } from '../../module/user';

const UserInfoContainer: React.FC<{ nickname: string; lang: string }> = ({ nickname, lang }) => {
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.fetchuser({ nickname, lang }));

        return () => {
            dispatch(userActions.inituser());
        };
    }, [dispatch]);

    return <UserInfoComponent user={user} />;
};

export default UserInfoContainer;
