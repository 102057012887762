import React, { useEffect } from 'react';
import RecommendMetaDetailComponent from '../../component/recommend/RecommendMetaDetailComponent';
import { useDispatch, useSelector } from 'react-redux';
import { recommendActions } from '../../module/recommendmeta';
import { RootState } from '../../module/rootReducer';
import * as R from 'ramda';
import { postLike } from '../../lib/api/lolcheserver/video';

const RecommendMetaDetailContainer: React.FC<any> = ({ id }) => {
    const {
        recommendmeta: { recommendMetaDetail },
        item: { indexedItemMap },
        init: { current_version },
    } = useSelector((root: RootState) => root);
    const championMap = R.indexBy((a: any) => a.position, recommendMetaDetail.champions);
    const hasitemChampionList = recommendMetaDetail.champions.filter((a) => a.items.length);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(recommendActions.fetchRecommendDetail(id));
        return () => {
            dispatch(recommendActions.resetrecommendDetail());
        };
    }, [id, dispatch]);

    const clickLikeButton = () => {
        dispatch(recommendActions.clickLikeButton(id));
    };

    return (
        <RecommendMetaDetailComponent
            recommendDetail={recommendMetaDetail}
            championMap={championMap}
            hasitemChampionList={hasitemChampionList}
            itemMap={indexedItemMap}
            currentVersion={current_version}
            clickLikeButton={clickLikeButton}
        />
    );
};

export default RecommendMetaDetailContainer;
