import Bronze from './UserTier/Bronze.svg';
import Silver from './UserTier/Silver.svg';
import Gold from './UserTier/Gold.svg';
import Platinum from './UserTier/Platinum.svg';
import Diamond from './UserTier/Diamond.svg';
import Master from './UserTier/Master.svg';
import Grandmaster from './UserTier/Grandmaster.svg';
import Challenger from './UserTier/Challenger.svg';

const Tier = {
    BRONZE: Bronze,
    SILVER: Silver,
    GOLD: Gold,
    PLATINUM: Platinum,
    DIAMOND: Diamond,
    MASTER: Master,
    GRANDMASTER: Grandmaster,
    CHALLENGER: Challenger,
} as any;

export default Tier;
