import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import SynergePage from './SynergePage';

function SynergeRoutePage({ match }: RouteComponentProps) {
    return (
        <>
            <Route path={`${match.path}`} component={SynergePage} />
        </>
    );
}

export default SynergeRoutePage;
