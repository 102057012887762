import { createAction, createReducer } from '../lib/reduxHelper';

export interface LoadingState {
    isLoading: boolean;
}

export enum ActionType {
    SHOWLOADING = 'loading/showloading',
    HIDELOADING = 'loading/hideloading',
}

export const loadingActions = {
    showLoading: () => createAction(ActionType.SHOWLOADING),
    hideLoading: () => createAction(ActionType.HIDELOADING),
};

type Action = ReturnType<typeof loadingActions[keyof typeof loadingActions]>;

const intialState: LoadingState = {
    isLoading: false,
};

const reducer = createReducer<LoadingState, Action>(intialState, {
    [ActionType.SHOWLOADING]: (state) => {
        state.isLoading = true;
    },
    [ActionType.HIDELOADING]: (state) => {
        state.isLoading = false;
    },
});

export default reducer;
