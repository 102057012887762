import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchChampionComponent from '../../component/champion/SearchChampionComponent';
import FindRecommendMetaComponent from '../../component/main/findrecommend/FIndRecommendMeta';
import { championActions } from '../../module/champion';
import { recommendActions } from '../../module/recommendmeta';
import { RootState } from '../../module/rootReducer';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';

const FindRecommendContainer = () => {
    const {
        recommendmeta: { searchRecommendList, displayLength },
        champion: { searchChampionList },
    } = useSelector((rootState: RootState) => rootState);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return () => {
            dispatch(championActions.searchChamPionListdebounce(''));
        };
    }, []);

    const click = useCallback(
        (id: number) => {
            dispatch(recommendActions.clickChampionImage(id));
        },
        [dispatch],
    );

    const search = useCallback(
        (query: string) => {
            dispatch(championActions.searchChamPionListdebounce(query));
        },
        [dispatch],
    );

    const goDetail = (id: number) => {
        history.push(`/recommendMeta/${id}`);
    };

    return (
        <FindRecommendMetaComponent recommendMetaList={R.take(displayLength, searchRecommendList)} goDetail={goDetail}>
            <SearchChampionComponent searchedChampionList={searchChampionList} onserch={search} onclick={click} />
        </FindRecommendMetaComponent>
    );
};

export default FindRecommendContainer;
