import { from } from 'rxjs';
import apiInstance from './maininstance';
import { pluck } from 'rxjs/operators';

export function fetchNoticeList() {
    return from(apiInstance.get(`/notification`)).pipe(pluck('data'), pluck('notice_list'));
}

export function fetchNotice(id: number) {
    return from(apiInstance.get(`/notification/${id}`)).pipe(pluck('data'));
}
