import React from 'react';
import { useSelector } from 'react-redux';
import ItemDescriptionComponent from '../../../component/item/ItemDescriptionComponent';
import { RootState } from '../../../module/rootReducer';

function ItemDescriptionContainer() {
    const { itemDescription, isitemDescriptionOpen } = useSelector((rootState: RootState) => rootState.item);

    return (
        <ItemDescriptionComponent
            src={itemDescription.image}
            title={itemDescription.name}
            infomation={itemDescription.description || ''}
            isitemDescriptionOpen={isitemDescriptionOpen}
        />
    );
}

export default ItemDescriptionContainer;
