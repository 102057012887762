import { createAction, createReducer } from '../lib/reduxHelper';
import { Observable } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { VideoDetail, fetchVideoDetail } from '../lib/api/lolcheserver/video';

export interface VideoDetailState {
    id: number;
    videoDetail: VideoDetail;
}

export enum ActionType {
    FETCHVIDEODETAIL = 'video/fetchvideodetail',
    SETVIDEO = 'video/setvideo',
}

export const videoActions = {
    setvideo: (data: VideoDetail) => createAction(ActionType.SETVIDEO, data),
    fetchvideodetail: (id: number) => createAction(ActionType.FETCHVIDEODETAIL, id),
};

type Action = ReturnType<typeof videoActions[keyof typeof videoActions]>;

const intialState: VideoDetailState = {
    id: 0,
    videoDetail: {
        curation_video: {
            channel: '',
            channel_img: '',
            id: 0,
            main_img: '',
            published_at: '',
            title: '',
            url: '',
            video_id: '',
            view_cnt: 0,
        },
        extra_videos: [],
        related_videos: [],
    },
};

const fetchVideoDetailEpic = (
    action$: Observable<ReturnType<typeof videoActions['fetchvideodetail']>>,
    // state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FETCHVIDEODETAIL),
        switchMap((action) => {
            return fetchVideoDetail(action.payload);
        }),
        map((data) => {
            return videoActions.setvideo(data);
        }),
    );

const reducer = createReducer<VideoDetailState, Action>(intialState, {
    [ActionType.SETVIDEO]: (state, action) => {
        state.videoDetail = action.payload;
    },
    [ActionType.FETCHVIDEODETAIL]: (state, action) => {
        state.id = action.payload;
    },
});

export const videoEpics = combineEpics(fetchVideoDetailEpic);

export default reducer;
