import React from 'react';
import styled from 'styled-components';
import { Champion } from '../../lib/api/lolcheserver/InitdataApi';
import RecommendChampionImageonMouseEvent from '../../container/common/RecommendChampionImageonMouseEvent';
import ic_search from '../../lib/style/MainIcon/ic_search.png';
import palette from '../../lib/style/palette';

interface SearchChampionProps {
    searchedChampionList: Champion[];
    onclick: (id: number) => void;
    onserch: (value: string) => void;
}

const SearchChampionBlock = styled.div`
    // height: 10rem;
    div > input {
        color: white;
        border: none;
        background-color: ${palette.GRAY01};
        outline: none;
        height: 2.35rem;
        font-size: 1rem;
    }

    .meta-title {
        display: flex;
    }

    margin-bottom: 1rem;
`;

const SearchInput = styled.input`
    font-size: 1.2rem;
    line-height: 2.3rem;
    letter-spacing: -0.02rem;
    color: ${palette.WHITE01};
    text-shadow: 0rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
    width: 100%;

    &::placeholder {
        font-size: 1.2rem;
        line-height: 2.3rem;
        letter-spacing: -0.02rem;
        color: ${palette.WHITE01};
    }
`;

const SearchImage = styled.img`
    width: 2.35rem;
    height: 2.35rem;
    margin-right: 1.643rem;
`;

const ChampionImageList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    // // height: 75px;
    // & > div {
    //     margin-right: 0.5rem;
    // }
`;

const SearchChampionComponent: React.FC<SearchChampionProps> = ({ searchedChampionList, onclick, onserch }) => {
    const search = (e: React.KeyboardEvent<HTMLInputElement>) => {
        onserch(e.currentTarget.value);
    };
    return (
        <SearchChampionBlock>
            <div className="meta-title">
                <SearchImage src={ic_search} />
                <SearchInput type="text" onKeyUp={search} placeholder="어떤 챔피언을 찾고 있나요?" />
            </div>
            <ChampionImageList>
                {searchedChampionList.map((champion, index) => {
                    const click = (e: React.MouseEvent) => {
                        onclick(champion.id);
                    };
                    return <RecommendChampionImageonMouseEvent champion={champion} onClick={click} key={index} />;
                })}
            </ChampionImageList>
        </SearchChampionBlock>
    );
};
export default React.memo(
    SearchChampionComponent,
    (prev, next) => prev.searchedChampionList === next.searchedChampionList,
);
