import React from 'react';
import YouTube from 'react-youtube';

interface YouTubeProps {
    size: {
        height: string;
        width: string;
    };
    id: string;
}

const YouTubeComponent: React.FC<YouTubeProps> = ({ size, id }) => {
    const _onReady = (event: any) => {
        event.target.pauseVideo();
    };
    return <YouTube videoId={id} onReady={_onReady} opts={size} />;
};

export default YouTubeComponent;
