import React from 'react';
import styled from 'styled-components';
import VideoDetailComponent from './VideoDetailComponent';
import { VideoDetail } from '../../lib/api/lolcheserver/video';
import VideoSliderList from '../main/recommendVideo/VideoSliderList';

interface TotalVideoDetailComponentProps {
    videoDetail: VideoDetail;
    clickListVideo: (id: number) => void;
}

const TotalVideoBlock = styled.ul`
    display: flex;
    flex-direction: column;
`;

const RelatedVideoBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 1.6rem;
`;

const RelatedTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.4rem;
    // text-align: center;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-bottom: 1.2rem;
`;

const ExtraBlock = styled.div``;

const ExtraTitleBlock = styled.div``;

const ExtraTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    // text-align: center;
    letter-spacing: -0.4px;
    color: #328ffd;
    margin-bottom: 0.1rem;
`;

const ExtraSubTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    // text-align: center;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-bottom: 1.2rem;
`;

const TotalVideoDetailComponent: React.FC<TotalVideoDetailComponentProps> = ({ videoDetail, clickListVideo }) => {
    return (
        <TotalVideoBlock>
            <VideoDetailComponent videoDetail={videoDetail.curation_video} />
            <RelatedVideoBlock>
                <RelatedTitle>관련영상 {videoDetail.related_videos.length}</RelatedTitle>
                {/* <RelatedVideoListBlock> */}
                <VideoSliderList videoList={videoDetail.related_videos} clickVideo={clickListVideo} />
                {/* </RelatedVideoListBlock> */}
            </RelatedVideoBlock>
            <ExtraBlock>
                <ExtraTitleBlock>
                    <ExtraTitle>다른 조합 영상</ExtraTitle>
                    <ExtraSubTitle>이 조합은 어때요?</ExtraSubTitle>
                </ExtraTitleBlock>
                <VideoSliderList videoList={videoDetail.extra_videos} clickVideo={clickListVideo} />
            </ExtraBlock>
        </TotalVideoBlock>
    );
};

export default React.memo(
    TotalVideoDetailComponent,
    (prev, next) => prev.videoDetail.curation_video.id === next.videoDetail.curation_video.id,
);
