import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import { overlayActions } from '../../module/overlay';

const OverLayBlock = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
`;

const OverLayContainer = () => {
    const { isOverlay } = useSelector((root: RootState) => root.overlay);
    const dispatch = useDispatch();
    const closeOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
        dispatch(overlayActions.closeOverlaystart());
    };

    return isOverlay ? <OverLayBlock onClick={closeOverlay} /> : <></>;
};

export default OverLayContainer;
