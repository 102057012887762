import { createAction, createReducer } from '../lib/reduxHelper';
import { UserAPIData } from '../lib/api/lolcheserver/user';
import { Observable, of } from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { RootState } from './rootReducer';
import { mergeMap } from 'rxjs/operators';
import { overlayActions } from './overlay';
import { getLikeUserList, cancleLikeUser, cancleLatestUser, getlatestSearchUserList } from '../lib/api/localstorage';

export interface SearchInput {
    isOpen: boolean;
    isUserListOpen: boolean;
    favoritesUserList: UserAPIData[];
    latestUserList: UserAPIData[];
}

export enum ActionType {
    OPEN = 'searchInput/open',
    CLOSE = 'searchInput/close',
    OPENSEARCHUSER = 'searchInput/opensearchinput',
    CLOSESEARCHUSER = 'searchInput/closesearchuser',
    FOCUSINPUT = 'searchInput/focusinput',
    FOCUSOUT = 'searchInput/focusout',
    SETUSERLIST = 'searchInput/setuserlist',
    DELETEUSERLIST = 'searchInput/deleteuserlist',
    SETLATESTLIST = 'searchInput/setlatestuserlist',
    DELETELATESTUSERLIST = 'searchInput/deletelatestuserlist',
}

export const searchInputAction = {
    open: () => createAction(ActionType.OPEN),
    close: () => createAction(ActionType.CLOSE),
    opensearchuser: () => createAction(ActionType.OPENSEARCHUSER),
    closesearchuser: () => createAction(ActionType.CLOSESEARCHUSER),
    focusinput: () => createAction(ActionType.FOCUSINPUT),
    focusoutinput: () => createAction(ActionType.FOCUSOUT),
    setfavoritesUserList: (userList: UserAPIData[]) => createAction(ActionType.SETUSERLIST, userList),
    deletefavoritesUserList: (userdata: UserAPIData) => createAction(ActionType.DELETEUSERLIST, userdata),
    setlatestUserList: (userList: UserAPIData[]) => createAction(ActionType.SETLATESTLIST, userList),
    deletelatestUserList: (userdata: UserAPIData) => createAction(ActionType.DELETELATESTUSERLIST, userdata),
};

type Action = ReturnType<typeof searchInputAction[keyof typeof searchInputAction]>;

const intialState: SearchInput = {
    isOpen: false,
    isUserListOpen: false,
    favoritesUserList: [],
    latestUserList: [],
};

const focusInputEpic = (
    action$: Observable<ReturnType<typeof searchInputAction['focusinput']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FOCUSINPUT),
        mergeMap((data) => {
            const userList = getLikeUserList();
            const latestUserList = getlatestSearchUserList();
            return of(
                searchInputAction.open(),
                searchInputAction.opensearchuser(),
                searchInputAction.setfavoritesUserList(userList),
                searchInputAction.setlatestUserList(latestUserList),
                overlayActions.openOverlay(),
            );
        }),
    );

const focusOutEpic = (
    action$: Observable<ReturnType<typeof searchInputAction['focusinput']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FOCUSINPUT),
        mergeMap((data) => {
            return of(searchInputAction.closesearchuser());
        }),
    );

const reducer = createReducer<SearchInput, Action>(intialState, {
    [ActionType.OPEN]: (state, action) => {
        state.isOpen = true;
    },
    [ActionType.CLOSE]: (state, action) => {
        state.isOpen = false;
    },
    [ActionType.OPENSEARCHUSER]: (state, action) => {
        state.isUserListOpen = true;
    },
    [ActionType.CLOSESEARCHUSER]: (state, action) => {
        state.isUserListOpen = false;
    },
    [ActionType.FOCUSINPUT]: (state, action) => {
        // state = state;
    },
    [ActionType.FOCUSOUT]: (state, action) => {
        // state = state;
    },
    [ActionType.SETUSERLIST]: (state, action) => {
        state.favoritesUserList = action.payload;
    },
    [ActionType.DELETEUSERLIST]: (state, action) => {
        state.favoritesUserList = state.favoritesUserList.filter((userdata) => {
            return userdata.user_data.id !== action.payload.user_data.id;
        });
        cancleLikeUser(action.payload);
    },
    [ActionType.SETLATESTLIST]: (state, action) => {
        state.latestUserList = action.payload;
    },
    [ActionType.DELETELATESTUSERLIST]: (state, action) => {
        state.latestUserList = state.latestUserList.filter((userdata) => {
            return userdata.user_data.id !== action.payload.user_data.id;
        });
        cancleLatestUser(action.payload);
    },
});
export const searchInputEpic = combineEpics(focusOutEpic, focusInputEpic);
export default reducer;
