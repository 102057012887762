import { createAction, createReducer } from '../lib/reduxHelper';
import { RecommendMeta } from '../lib/api/lolcheserver/InitdataApi';
import { Observable, of } from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { RootState } from './rootReducer';
import { switchMap, map, tap, mapTo, mergeMap } from 'rxjs/operators';
import { _any } from '../lib/util';
import { fetchRecommendDetail } from '../lib/api/lolcheserver/recommend';
import * as R from 'ramda';
import { postLike } from '../lib/api/lolcheserver/video';
import { getLikeMetaList } from '../lib/api/localstorage';

export interface RecommendPageState {
    totalRecommendList: RecommendMeta[];
    searchRecommendList: RecommendMeta[];
    recommendMetaDetail: RecommendMeta & {
        isLike: boolean;
    };
    displayLength: number;
}

export enum ActionType {
    INITRECOMMENDLIST = 'recommend/initrecommendlist',
    SETSEARCHRECOMMENDLIST = 'recommend/setsearchrecommendlist',
    CLICKCHAMPIONIMAGE = 'recommend/clickchampionimage',
    FETCHRECOMMENDDETAIL = 'recommend/fetchrecommenddetail',
    SETRECOMMENDDETAIL = 'recommend/setrecommenddetail',
    SETDISPLAYLENGTH = 'recommend/setdisplaylength',
    RESETRECOMMENDDETAIL = 'recommend/resetrecommenddetail',
    CLICKLIKEBUTTON = 'recommend/clicklikebutton',
    SETISLIKE = 'recommend/SETISLIKE',
}

export const recommendActions = {
    setInitRecommendList: (recommendList: RecommendMeta[]) => createAction(ActionType.INITRECOMMENDLIST, recommendList),
    setSearchRecommendList: (recommendList: RecommendMeta[]) =>
        createAction(ActionType.SETSEARCHRECOMMENDLIST, recommendList),
    clickChampionImage: (id: number) => createAction(ActionType.CLICKCHAMPIONIMAGE, id),
    fetchRecommendDetail: (id: number) => createAction(ActionType.FETCHRECOMMENDDETAIL, id),
    setRecommendDetail: (recommendData: any) => createAction(ActionType.SETRECOMMENDDETAIL, recommendData),
    setDisplayLength: (length: number) => createAction(ActionType.SETDISPLAYLENGTH, length),
    resetrecommendDetail: () => createAction(ActionType.RESETRECOMMENDDETAIL),
    clickLikeButton: (recid: number) => createAction(ActionType.CLICKLIKEBUTTON, recid),
    setIsLike: (id: number) => createAction(ActionType.SETISLIKE, id),
};

type Action = ReturnType<typeof recommendActions[keyof typeof recommendActions]>;

const intialState: RecommendPageState = {
    totalRecommendList: [],
    searchRecommendList: [],
    recommendMetaDetail: {
        id: 0,
        title: '',
        champions: [],
        synerges: [],
        patch_version: '',
        source: '',
        priority: 0,
        is_active: false,
        like_cnt: 0,
        isLike: false,
    },
    displayLength: 6,
};

const clickChampionImageEpic = (
    action$: Observable<ReturnType<typeof recommendActions['clickChampionImage']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.CLICKCHAMPIONIMAGE),
        map((action) => {
            const filteredRecommend = R.pipe(R.prop('champions'), _any(R.pathEq(['champion', 'id'], action.payload)));
            const searchRecommendList = state.value.recommendmeta.totalRecommendList.filter((recommendmeta) =>
                filteredRecommend(recommendmeta),
            );
            return recommendActions.setSearchRecommendList(searchRecommendList);
        }),
    );

const fetchRecommendDetailEpic = (
    action$: Observable<ReturnType<typeof recommendActions['fetchRecommendDetail']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FETCHRECOMMENDDETAIL),
        switchMap((a) => {
            return fetchRecommendDetail(a.payload).pipe(
                tap((b) => {
                    if (!localStorage.getItem('' + a.payload)) {
                        localStorage.setItem('' + a.payload, JSON.stringify(b));
                    }
                }),
            );
        }),
        map((a) => {
            return recommendActions.setRecommendDetail(a);
        }),
    );

const clickLikeButtonEpic = (
    action$: Observable<ReturnType<typeof recommendActions['clickLikeButton']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.CLICKLIKEBUTTON),
        switchMap((action) => {
            return postLike(action.payload).pipe(
                tap((a) => {
                    const likeMetaList = getLikeMetaList();
                    likeMetaList.push(action.payload);
                    localStorage.setItem('likeMetaList', JSON.stringify(R.uniq(likeMetaList)));
                }),
                mapTo(action.payload),
            );
        }),
        mergeMap((id) => {
            return of(recommendActions.fetchRecommendDetail(id), recommendActions.setIsLike(id));
        }),
    );

const reducer = createReducer<RecommendPageState, Action>(intialState, {
    [ActionType.INITRECOMMENDLIST]: (state, action) => {
        const recommendList = action.payload;
        state.totalRecommendList = recommendList;
        state.searchRecommendList = recommendList;
    },
    [ActionType.SETSEARCHRECOMMENDLIST]: (state, action) => {
        state.searchRecommendList = action.payload;
    },
    [ActionType.CLICKCHAMPIONIMAGE]: (state, action) => {
        // state = state;
    },
    [ActionType.FETCHRECOMMENDDETAIL]: (state, action) => {
        // state = state;
    },
    [ActionType.SETRECOMMENDDETAIL]: (state, action) => {
        const likeMetaList = getLikeMetaList();
        state.recommendMetaDetail = Object.assign(action.payload, {
            isLike: likeMetaList.includes('' + action.payload.id),
        });
    },
    [ActionType.SETDISPLAYLENGTH]: (state, action) => {
        state.displayLength = action.payload;
    },
    [ActionType.RESETRECOMMENDDETAIL]: (state, action) => {
        state.recommendMetaDetail = intialState.recommendMetaDetail;
    },
    [ActionType.CLICKLIKEBUTTON]: (state, action) => {
        //
    },
    [ActionType.SETISLIKE]: (state, action) => {
        const likeMetaList = getLikeMetaList();
        state.recommendMetaDetail.isLike = likeMetaList.includes(action.payload);
    },
});

export const recommendEpics = combineEpics(clickChampionImageEpic, fetchRecommendDetailEpic, clickLikeButtonEpic);
export default reducer;
