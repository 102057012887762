import { from, of } from 'rxjs';
import apiInstance from './maininstance';
import { pluck } from 'rxjs/operators';

export function fetchRecommendDetail(id: number) {
    // const detail = localStorage.getItem('' + id);
    // if (detail) {
    //     return of(JSON.parse(detail));
    // }

    return from(apiInstance.get(`/meta/${id}`)).pipe(pluck('data'));
}

export function patchLike(id: number) {
    const detail = localStorage.getItem('' + id);
    if (detail) {
        return of(JSON.parse(detail));
    }

    return from(apiInstance.get(`/meta/${id}`)).pipe(pluck('data'));
}
