import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Chat } from '../../module/chat';
import palette from '../../lib/style/palette';
import send from '../../lib/style/MainIcon/ic_send.png';

const ChatComponentBlock = styled.div`
    position: absolute;
    top: 3rem;
    right: 10px;
    border-left: 1px solid ${palette.GRAY10};
    border-right: 1px solid ${palette.GRAY10};
    border-bottom: 1px solid ${palette.GRAY10};
    width: 19rem;
    background: ${palette.GRAY04};
    box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.16);
`;

const ChatHeaderBlock = styled.header`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 1rem;
`;

const ChatHeaderContent = () => {
    return <ChatHeaderBlock>채팅</ChatHeaderBlock>;
};

const ChatImg = styled.img`
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.3rem;
    border: 1px solid #313843;
    box-sizing: border-box;
    border-radius: 79px;
`;

const ChatInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChatNameAndTimeBlock = styled.ul`
    display: flex;
    height: 0.9rem;
    margin-bottom: 0.3rem;
`;

const ChatUserDiffTodayTime = styled.li`
    font-size: 0.6rem;
    color: ${palette.GRAY10};
    line-height: 17px;
    letter-spacing: -0.4px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
`;

const ChatContentOtherBlock = styled.div`
    background: ${palette.GRAY06};
    color: white;
    padding: 6px 0px 0px 12px;
    border-radius: 2px 12px 12px 12px;
`;

const ChatContentMineBlock = styled.div<{ isRight: boolean }>`
    background: ${palette.GRAY06};
    color: white;
    padding: 6px 0px 0px 12px;
	// margin-left:1.9rem;
    // ${(props) => (props.isRight ? 'margin-right:1.9rem;' : 'margin-left:1.9rem')}
`;

const ChatContentBlock: React.FC<{ chat: Chat }> = ({ chat }) => {
    return chat.ismine ? (
        <ChatContentMineBlock isRight={chat.chatposition === 'right'}>
            <ChatContent>{chat.userMessage}</ChatContent>
        </ChatContentMineBlock>
    ) : (
        <ChatContentOtherBlock>
            <ChatContent>{chat.userMessage}</ChatContent>
        </ChatContentOtherBlock>
    );
};

const ChatContent = styled.p`
    max-width: 8.25rem;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #ffffff;
    white-space: normal;
    opacity: 0.9;
`;

const ChatItemBlock = styled.div<{ hasProfile: boolean }>`
    display: flex;
    width: 100%;
    .right {
        margin-left: auto;
    };
 	margin-top:0.8rem;
    // margin-top: ${(props) => (props.hasProfile ? '0.8rem' : 0)};
`;

const ChatforMoveBlock = styled.div`
    display: flex;
`;

const ChatUserName = styled.li`
    font-size: 0.6rem;
    margin-right: 0.3rem;
    line-height: 17px;
    letter-spacing: -0.4px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
`;

const ChatNameAndTime: React.FC<{ userName: string; userdiffTodayTime: string }> = ({
    userdiffTodayTime,
    userName,
}) => {
    return (
        <ChatNameAndTimeBlock>
            <ChatUserName>{userName}</ChatUserName>
            <ChatUserDiffTodayTime>{userdiffTodayTime}</ChatUserDiffTodayTime>
        </ChatNameAndTimeBlock>
    );
};

const ChatTotalInfo: React.FC<any> = ({ chat }) => {
    return (
        <ChatInfoBlock>
            <ChatNameAndTime userdiffTodayTime={chat.userdiffTodayTime} userName={chat.userName}></ChatNameAndTime>
            <ChatContentBlock chat={chat} />
        </ChatInfoBlock>
    );
};

const ChatLeftItem: React.FC<{ chat: any }> = ({ chat }) => {
    return (
        <ChatItemBlock hasProfile={chat.hasProfile}>
            <ChatforMoveBlock>
                <ChatImg src={chat.userImg} />
                <ChatTotalInfo chat={chat}></ChatTotalInfo>
            </ChatforMoveBlock>
        </ChatItemBlock>
    );
};

const ChatUserNameRight = styled.li`
    font-size: 0.6rem;
    margin-right: 0.3rem;
    line-height: 17px;
    letter-spacing: -0.4px;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    margin-left: auto;
`;

const ChatNameAndTimeRight: React.FC<{ userName: string; userdiffTodayTime: string }> = ({
    userdiffTodayTime,
    userName,
}) => {
    return (
        <ChatNameAndTimeBlock>
            <ChatUserNameRight>{userName}</ChatUserNameRight>
            <ChatUserDiffTodayTime>{userdiffTodayTime}</ChatUserDiffTodayTime>
        </ChatNameAndTimeBlock>
    );
};

const ChatTotalInfoRight: React.FC<any> = ({ chat }) => {
    return (
        <ChatInfoBlock>
            <ChatNameAndTimeRight
                userdiffTodayTime={chat.userdiffTodayTime}
                userName={chat.userName}
            ></ChatNameAndTimeRight>
            <ChatContentBlock chat={chat} />
        </ChatInfoBlock>
    );
};

const ChatRightItem: React.FC<{ chat: any }> = ({ chat }) => {
    return (
        <ChatItemBlock hasProfile={chat.hasProfile}>
            <ChatforMoveBlock className="right">
                <ChatTotalInfoRight chat={chat}></ChatTotalInfoRight>
                <ChatImg src={chat.userImg} />
            </ChatforMoveBlock>
        </ChatItemBlock>
    );
};

const ChatListBlock = styled.div`
    height: 30.25rem;
    overflow: scroll;
    overflow-x: hidden;
    padding: 1.6rem 1.2rem 0.8rem 1.2rem;
    border-bottom: 1px solid ${palette.GRAY10};

    &::-webkit-scrollbar {
        width: 1em;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${palette.GRAY03};
        outline: 1px solid slategrey;
    }
`;
const ChatList: React.FC<{ chat: any[] }> = ({ chat }) => {
    const chatBlock = useRef(null) as any;
    useEffect(() => {
        const { current } = chatBlock;
        current.scroll({ top: current.scrollHeight });
        if (chatBlock) {
            chatBlock.current.addEventListener('DOMNodeInserted', (event: any) => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight });
            });
        }
        return () => {
            if (chatBlock) {
                chatBlock.current.removeEventListener('DOMNodeInserted', (event: any) => {
                    const { currentTarget: target } = event;
                    target.scroll({ top: target.scrollHeight });
                });
            }
        };
    }, []);
    return (
        <ChatListBlock ref={chatBlock}>
            {chat.map((chatdata: Chat, index) =>
                chatdata.ismine ? (
                    <ChatRightItem chat={chatdata} key={index} />
                ) : (
                    <ChatLeftItem chat={chatdata} key={index} />
                ),
            )}
        </ChatListBlock>
    );
};

const ChatInputBoxBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    padding: 16px 24px 16px 24px;
    height: 5rem;
`;

const ChatInputArea = styled.textarea`
    background: #24272e;
    border-radius: 59px;
    width: 80%;
    height: 2.1rem;
    background-color: ${palette.GRAY03};
    color: white;
    padding: 10px 16px;
    flex: 1 1 0px;
    &:focus {
        outline: none;
    }
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 22px;
    /* identical to box height, or 157% */

    letter-spacing: -0.4px;

    /* White/04 */

    // color: #707888;
    color: white;
    overflow: hidden;
`;

const SendImgBlock = styled.div`
    width: 1.9rem;
    height: 1.9rem;
    background: #24272e;
    border-radius: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    img {
        width: 0.7rem;
        height: 0.7rem;
    }
`;

const ChatInputBox: React.FC<{ addchat: (message: string) => void }> = ({ addchat }) => {
    const [chatdata, setchatdata] = useState('');
    const changeInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setchatdata(e.currentTarget.value);
    };
    const clickSend = (e: any) => {
        setchatdata('');
        addchat(chatdata);
    };

    const pressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setchatdata('');
            addchat(chatdata);
        }
    };

    return (
        <ChatInputBoxBlock>
            <ChatInputArea
                rows={20}
                placeholder="메세지를 입력하세요"
                value={chatdata}
                onChange={changeInput}
                onKeyPress={pressEnter}
            />
            <SendImgBlock onClick={clickSend}>
                <img src={send} alt="" />
            </SendImgBlock>
        </ChatInputBoxBlock>
    );
};

const ChatComponent: React.FC<{ chatList: Chat[]; addchat: (message: string) => void }> = ({ chatList, addchat }) => {
    // https://lolchess-public.s3.ap-northeast-2.amazonaws.com/ko/3.5/champion/jarvaniv.png
    return (
        <ChatComponentBlock>
            <ChatHeaderContent />
            <ChatList chat={chatList} />
            <ChatInputBox addchat={addchat} />
        </ChatComponentBlock>
    );
};

export default ChatComponent;
