import styled from 'styled-components';
import palette from '../../lib/style/palette';

const ItemPageLayout = styled.div`
    display: flex;
    // margin-top: 2rem;
    flex-direction: column;
    min-height: 45rem;
    position: relative;
    left: 10%;
    // .flex-item {
    //     margin: 1em 1em;
    // }

    // background-color: ${palette.GRAY01};
    // @media (min-width: 768px) {
    //     justify-content: center;
    //     align-items: center;
    //     .item-page2 {
    //         width: 469px;
    //     }
    // }
`;

export default ItemPageLayout;
