import React from 'react';
import styled from 'styled-components';
import { Notice } from '../../module/notice';
import NoticeListComponent from './NoticeComponent';
import NoticeLatest from './NoticeLastestComponent';

const NoticeListTotalBlock = styled.div`
    display: flex;
`;

const NoticeMainTitle = styled.h2`
    margin-bottom: 1.6rem;
    color: #ffffff;
`;

const NoticeTotalListComponent: React.FC<{
    noticeList: Notice[];
    noticenum: number;
    clickNotice: (index: number) => void;
}> = ({ noticeList, noticenum, clickNotice }) => {
    return (
        <>
            <NoticeMainTitle>공지사항</NoticeMainTitle>
            <NoticeListTotalBlock>
                <NoticeListComponent noticeList={noticeList} clickNotice={clickNotice} />
                <NoticeLatest notice={noticeList.length ? noticeList[noticenum] : ({} as Notice)} />
            </NoticeListTotalBlock>
        </>
    );
};

export default NoticeTotalListComponent;
