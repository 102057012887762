import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/style/palette';
import downarrow from '../../lib/style/MainIcon/ic_chevron_down.svg';
import ChallengerSvg from '../../lib/style/UserTier/Challenger.svg';
import GrandMasterSvg from '../../lib/style/UserTier/Grandmaster.svg';
import MasterSvg from '../../lib/style/UserTier/Master.svg';
import { CustomSelectType } from '../../module/user';

interface TierSelectProps {
    tier: CustomSelectType;
    changeTierEvent: (lang: CustomSelectType) => void;
    setNationSelectOpen: (isopen: boolean) => void;
    setTierSelectOpen: (isopen: boolean) => void;
    isTierSelectOpen: boolean;
}

const DropDownContainer = styled('div')`
    background: ${palette.GRAY04};
    border: 1px solid ${palette.GRAY06};
    box-sizing: border-box;
    border-radius: 4px;
`;

const DropDownHeader = styled('div')`
    padding: 0.5rem 0.9rem;
    height: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TierName = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
`;

const TierImgBlock = styled.div`
    display: flex;
    align-items: center;
`;

const DownArrowBlock = styled.div`
    display: flex;
    & > img {
        width: 0.7rem;
        height: 0.7rem;
    }
`;

const DropDownListContainer = styled('div')`
    position: absolute;
    z-index: 100;
    width: 10.5em;
`;

const DropDownList = styled('ul')`
    background: #2c3038;
    box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 10rem;
`;

const ListItem = styled('li')`
    list-style: none;
    margin-bottom: 0.8em;
    display: flex;
    justify-content: center;
    &:hover {
        background: #24272e;
    }
`;

const options = [
    { displayname: 'Challenger', image: ChallengerSvg, value: 'challenger' },
    { displayname: 'GrandMaster', image: GrandMasterSvg, value: 'grandmaster' },
    { displayname: 'Master', image: MasterSvg, value: 'master' },
];

const TierSelect: React.FC<TierSelectProps> = ({
    tier,
    changeTierEvent,
    isTierSelectOpen,
    setNationSelectOpen,
    setTierSelectOpen,
}) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const [selectedOption, setSelectedOption] = useState({ name: 'Challenger', image: ChallengerSvg });

    const toggling = () => {
        setTierSelectOpen(!isTierSelectOpen);
        setNationSelectOpen(false);
    };

    const onOptionClicked = (option: CustomSelectType) => () => {
        changeTierEvent(option);
        setTierSelectOpen(false);
    };

    return (
        <DropDownContainer>
            <DropDownHeader onClick={toggling}>
                <TierImgBlock>
                    <img src={tier.image || ChallengerSvg} alt="" />
                </TierImgBlock>
                <TierName>{tier.displayname}</TierName>
                <DownArrowBlock>
                    <img src={downarrow} alt="" />
                </DownArrowBlock>
            </DropDownHeader>
            {isTierSelectOpen && (
                <DropDownListContainer className="liston">
                    <DropDownList>
                        {options.map((option) => (
                            <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                <img src={option.image} alt="" />
                                <TierName>{option.displayname}</TierName>
                            </ListItem>
                        ))}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    );
};

export default TierSelect;
