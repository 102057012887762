import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RecommendMetaDetailContainer from '../../container/recommend/RecommendMetaDetail';
import RecommendMetaDetailPageLayout from '../../component/layout/RecommendMetaDetailPageLayout';
import Meta from '../../component/common/Meta';

const RecommendDetailPage = ({ match }: RouteComponentProps) => {
    const params = match.params as any;
    const metaData = {
        title: '추천메타 상세보기',
        description: '추천 영상, 추천 메타, 추천메타 검색, 챔피언 검색, 챔피언 추천메타 검색',
    };

    return (
        <RecommendMetaDetailPageLayout>
            <Meta data={metaData} />
            <RecommendMetaDetailContainer id={params.id} />
        </RecommendMetaDetailPageLayout>
    );
};

export default withRouter(RecommendDetailPage);
