import { curry } from '.';
import { SynergeType, Champion, RecommendMeta } from '../api/lolcheserver/InitdataApi';
import * as R from 'ramda';

/**
 *
 * @param champion
 * @param synegeGetter
 * Champion -> Champion(syneges 추가)
 */
export const parsedSynergeChampion = curry((synegeGetter: (id: number) => SynergeType, champion: Champion) => {
    return { ...champion, synerges: [...champion.synerge.class, ...champion.synerge.origin].map(synegeGetter) };
});
/**
 * Champion[] -> Champion[](syneges 추가)
 *
 */
export const parsedSynergeChampionList = (synegeGetter: (id: number) => SynergeType, championList: Champion[]) => {
    return R.map(parsedSynergeChampion(synegeGetter), championList) as Champion[];
};

export const partitionEmptyorChampionArray = R.partition(R.isEmpty);
/**
 * RecommendMeta[] -> RecommendMeta[](champion에 Synerge 추가된)
 */

export const parsedSynergeChampionOfRecommendMeta: any = (
    synergeGetter: (id: number) => SynergeType,
    recommendmetaList: RecommendMeta[],
) => {
    return recommendmetaList.map((recommendmeta: RecommendMeta) => {
        const newChampions = recommendmeta.champions.map((recommendMetaChampionObj) => {
            return {
                ...recommendMetaChampionObj,
                champion: parsedSynergeChampion(synergeGetter, recommendMetaChampionObj.champion),
            };
        });
        return { ...recommendmeta, champions: newChampions };
    });
};
