import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import ChampionBuilderComponent from '../../component/championbuilder/ChampionBuilderList';
import { championbuilderActions } from '../../module/championbuilder';

const ChampionBuilderContainer = () => {
    const {
        championbuilder: {
            builder: { chamList, synergestaticList },
        },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const click = (championId: number) => {
        dispatch(championbuilderActions.clickChampionBuilder(championId));
    };

    return <ChampionBuilderComponent chamList={chamList} synergestaticList={synergestaticList} onClick={click} />;
};

export default React.memo(ChampionBuilderContainer);
