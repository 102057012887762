import React from 'react';
import styled from 'styled-components';
import { Champion } from '../../lib/api/lolcheserver/InitdataApi';
import RecommendChampionImageonMouseEvent from '../../container/common/RecommendChampionImageonMouseEvent';

export type CellBoxClickPayload = { id: number; isSelected: boolean };

interface ChampionCellBoxLiteProps {
    champion: Champion;
    click: (payload: CellBoxClickPayload) => void;
}

const ChampionCellBoxLiteBlock = styled.div<{ isSelected: boolean }>`
    display: flex;
    color: white;
    /* background-color: ${(props) => (props.isSelected ? 'red' : '')}; */
    opacity:${(props) => (props.isSelected ? 0.4 : 1)};
`;

const ChampionCellBoxLiteComponent = ({ champion, click }: ChampionCellBoxLiteProps) => {
    const ChampionClick = (e: React.MouseEvent) => {
        click({
            id: champion.id,
            isSelected: champion.isSelected,
        });
    };
    return (
        <ChampionCellBoxLiteBlock onClick={ChampionClick} isSelected={champion.isSelected}>
            <RecommendChampionImageonMouseEvent champion={champion} width="3rem" heigth="3rem" />
        </ChampionCellBoxLiteBlock>
    );
};
export default React.memo(
    ChampionCellBoxLiteComponent,
    (prev, next) => prev.champion.isSelected === next.champion.isSelected,
);
