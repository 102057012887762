import React from 'react';
import styled from 'styled-components';
import BlueStar from '../../lib/style/MainIcon/BlueStar.svg';
import X from '../../lib/style/MainIcon/x.svg';
import palette from '../../lib/style/palette';
import { UserAPIData } from '../../lib/api/lolcheserver/user';

interface UserListProps {
    userList: UserAPIData[];
    deleteMethod: (user: UserAPIData) => void;
    isLikeUser: boolean;
    submit: (param: { nickname: string; lang: string }) => void;
    userlang: string;
}

const SearchUserListUl = styled.ul`
    margin-bottom: 1.5rem;
`;

const SearchUserLikeTitleBlock = styled.div`
    margin-bottom: 0.5rem;
`;
const SearchUserLikeTitle = styled.span`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #ffffff;
`;
const SearchUserBlueStar = styled.img`
    margin-right: 0.3rem;
`;

const UserInfoListItemBlock = styled.li`
    width: 16.6rem;
    height: 3.5rem;
    display: flex;
    background: ${palette.GRAY05};
    margin: 0.5rem 0 0.5rem 0;
    cursor: pointer;
`;

const UserInfoListItemBox = styled.div`
    width: 16.6rem;
    height: 3.5rem;
    background: ${palette.GRAY05};
    padding: 0.7rem;
    display: flex;
`;

const UserProfileImage = styled.img`
    width: 2.1rem;
    height: 2.1rem;
    margin-right: 0.5rem;
`;

const UserInfoBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const UserName = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #ffffff;
`;

const UserTier = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #9fa5b0;
`;

const XButtonBox = styled.div`
    display: flex;
    align-items: center;
    margin-right: 0.7rem;
    cursor: pointer;
`;

const XButton = styled.img`
    width: 1.2rem;
    height: 1.2rem;
`;

const LikeUserTitle = () => {
    return (
        <>
            <SearchUserBlueStar src={BlueStar} />
            <SearchUserLikeTitle>즐겨찾는 소환사</SearchUserLikeTitle>
        </>
    );
};

const LatestUserTitle = () => {
    return <SearchUserLikeTitle>최근 검색한 소환사</SearchUserLikeTitle>;
};

const SearchUserList: React.FC<UserListProps> = ({ userList, deleteMethod, isLikeUser, submit, userlang }) => {
    return userList.length ? (
        <SearchUserListUl>
            <SearchUserLikeTitleBlock>{isLikeUser ? <LikeUserTitle /> : <LatestUserTitle />}</SearchUserLikeTitleBlock>
            {userList.map((user) => {
                const clickXButton = (e: React.MouseEvent<HTMLDivElement>) => {
                    deleteMethod(user);
                };

                const clickList = (e: React.MouseEvent<HTMLLIElement>) => {
                    submit({ nickname: user.user_data.name, lang: userlang });
                };

                return (
                    <UserInfoListItemBlock key={user.user_data.id} onClick={clickList}>
                        <UserInfoListItemBox>
                            <UserProfileImage src={user.user_data.userProfile} />
                            <UserInfoBox>
                                <UserName>{user.user_data.name}</UserName>
                                <UserTier>{user.score_data.tier}</UserTier>
                            </UserInfoBox>
                        </UserInfoListItemBox>
                        <XButtonBox onClick={clickXButton}>
                            <XButton src={X} />
                        </XButtonBox>
                    </UserInfoListItemBlock>
                );
            })}
        </SearchUserListUl>
    ) : (
        <></>
    );
};

export default SearchUserList;
