import { _flatMap, _isNotEmpty } from '.';
import { SynergeType, Champion } from '../api/lolcheserver/InitdataApi';
import { parsedSynergeChampion } from './championUtil';
import * as R from 'ramda';

/**
 * 각 시너지 발동수 및 효과 [발동수,효과]로 변환
 *
 *
 * ex {effect_1:'공허효과',effect_count_1:3} -> [3,'공허효과']
 */
// export const getEffect = (obj: SynergeType) => {
//     return [
//         _props(['effect_count_1', 'effect_1'], obj),
//         _props(['effect_count_2', 'effect_2'], obj),
//         _props(['effect_count_3', 'effect_3'], obj),
//         _props(['effect_count_4', 'effect_4'], obj),
//     ].filter((a) => a[0]);
// };

export const getEffect = (obj: SynergeType) => {
    return [
        R.props(['effect_count_1', 'effect_1'], obj),
        R.props(['effect_count_2', 'effect_2'], obj),
        R.props(['effect_count_3', 'effect_3'], obj),
        R.props(['effect_count_4', 'effect_4'], obj),
    ].filter((a) => a[0]) as any;
};
/**
 * 데이터 쓰기 편한 형태로 변환
 * 시너지 챔피언 데이터 id -> Champion Type으로 변환
 * 효과 데이터 -> [발동수,효과설명]
 *
 */
export const processSynergeTypeData = (championMap: { [key: string]: Champion }, synergeList: SynergeType[]) =>
    R.map((a: SynergeType) => {
        return {
            ...a,
            parsedchampion_list: R.map((championNumber) => championMap[championNumber], a.champion_list),
            effects: getEffect(a),
        };
    }, synergeList);

export const parsedChampionSynerge = (
    synerge: SynergeType,
    championGetter: (id: number) => Champion,
    synergeGetter: (id: number) => SynergeType,
) => {
    const champions = R.map(
        R.pipe(championGetter, parsedSynergeChampion(synergeGetter)),
        synerge.champion_list,
    ) as Champion[];
    return { ...synerge, champions };
};

/* export const getsynergeStatic = (chamList: Champion[]) => {
    return chamList.reduce((acc, champion) => {
        const synerges = champion.synerges || [];
        const defaultCountObj = _defaultTo({ synerge: {}, count: 0 });
        return synerges.reduce((a, b) => {
            const key = b.id;
            const obj = defaultCountObj(a[key]);
            const count = obj.count + 1;
            a[key] = { synerge: b, count };
            return a;
        }, acc);
    }, {} as BuilderType['synergestatic']);
}; */
/**
 *
 * @param chamList
 * chamList => [key:string]:{synerge:SynergeType,count:number}
 *
 * 챔리스트 => {시너지아이디:{synerhge:시너지,count:시너지 수}}
 */
const getsynergeStatic = (chamList: Champion[]) => {
    const synerges = _flatMap((a: Champion) => a.synerges, chamList);
    const defaultCountObj = R.defaultTo({ synerge: {}, count: 0 });
    return synerges.reduce((a: any, b: SynergeType) => {
        const key = b.id;
        const value = defaultCountObj(a[key]);
        a[key] = { ...value, ...{ synerge: b, count: value.count + 1 } };
        return a;
    }, {});
};

// export const getSynergeStaticList = _pipe(
//     _filter(_isNotEmpty),
//     getsynergeStatic,
//     _entries,
//     _map((a: any) => {
//         const { synerge, count } = a[1] as { synerge: SynergeType; count: number };
//         const synergeEffect = getEffect(synerge);
//         const maxtier = synergeEffect.length;
//         const synergetier = Math.min(Math.trunc(count / synergeEffect[0][0]), maxtier);
//         return { ...a[1], synergetier: synergetier === maxtier ? 3 : synergetier };
//     }),
//     _filter((a: any) => a.synergetier > 0),
// );

export const getSynergeStaticList = R.pipe<Champion[], Champion[], { synerge: string; count: number }, any, any, any>(
    R.filter(_isNotEmpty),
    getsynergeStatic,
    R.toPairs,
    R.map((a: any) => {
        const { synerge, count } = a[1] as { synerge: SynergeType; count: number };
        const synergeEffect = getEffect(synerge);
        const maxtier = synergeEffect.length;
        const synergetier = Math.min(Math.trunc(count / synergeEffect[0][0]), maxtier);
        return { ...a[1], synergetier: synergetier === maxtier ? 3 : synergetier };
    }),
    R.filter((a: any) => a.synergetier > 0),
);
