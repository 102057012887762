import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import { userActions, CustomSelectType } from '../../module/user';
import UserRanksListComponent from '../../component/user/UserRanksListComponent';

const UserRanksListContainer = () => {
    const { userRankList, loading, tier, lang, isNationSelectOpen, isTierSelectOpen } = useSelector(
        (state: RootState) => state.user,
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            userActions.fetchuserranks({
                lang: {
                    displayname: 'KR',
                    value: 'kr',
                },
                tier: { displayname: 'Challenger', value: 'challenger' },
            }),
        );

        return () => {
            dispatch(userActions.setisnationselectopen(false));
            dispatch(userActions.setistierselectopen(false));
        };
    }, [dispatch]);

    const langChange = (langstr: CustomSelectType) => {
        //언어 변경 일 경우 tier는 전체 store에서 받아오고 나라만 받아온 상태로 변경
        dispatch(userActions.fetchuserranks({ tier, lang: langstr }));
    };

    const tierChange = (tierstr: CustomSelectType) => {
        //티어  변경 일 경우 언어는 전체 store에서 받아오고 나라만 받아온 상태로 변경
        dispatch(userActions.fetchuserranks({ tier: tierstr, lang }));
    };

    const setNationSelectOpen = (isopen: boolean) => {
        dispatch(userActions.setisnationselectopen(isopen));
    };
    const setTierSelectOpen = (isopen: boolean) => {
        dispatch(userActions.setistierselectopen(isopen));
    };

    return (
        <UserRanksListComponent
            userRanksList={userRankList}
            isLoading={loading}
            tier={tier}
            lang={lang}
            changeLangEvent={langChange}
            changeTierEvent={tierChange}
            setNationSelectOpen={setNationSelectOpen}
            setTierSelectOpen={setTierSelectOpen}
            isTierSelectOpen={isTierSelectOpen}
            isNationSelectOpen={isNationSelectOpen}
        />
    );
    // return <Loading />;
};

export default UserRanksListContainer;
