import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainSearchComponent from '../../component/user/MainHeaderSearchComponent';
import { useHistory } from 'react-router-dom';
import { chatActions } from '../../module/chat';
import { searchInputAction } from '../../module/searchInput';
import { RootState } from '../../module/rootReducer';

const MainSearchContainer = () => {
    const {
        chat: { isopen },
    } = useSelector((root: RootState) => root);
    // const {
    //     user: { nickname, user, error },
    // } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const history = useHistory();

    const chatOpen = () => {
        dispatch(chatActions.isopenChat());
    };

    const goNotice = () => {
        history.push('/notice');
    };

    const clickSearch = () => {
        dispatch(searchInputAction.focusinput());
    };

    return <MainSearchComponent chat={chatOpen} goNotice={goNotice} clickSearch={clickSearch} isChatOpen={isopen} />;
};

export default MainSearchContainer;
