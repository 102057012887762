import styled from 'styled-components';

const VideoDetailPageLayout = styled.div`
    position: relative;
    top: 100px;

    margin-bottom: 12rem;
`;

export default VideoDetailPageLayout;
