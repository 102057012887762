import React, { useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import ItemPage2 from './Itempage2';

function ItemPage({ match }: RouteComponentProps) {
    return (
        <>
            <Route path={`${match.path}/item`} component={ItemPage2} />
        </>
    );
}

export default ItemPage;
