import styled from 'styled-components';
import palette from '../../lib/style/palette';

const SynergePageLayout = styled.div`
    // display: grid;
    // grid-template-columns: 1fr minmax(auto, 980px) 1fr;
    // margin-top: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 10%;
    top: 2%;
    min-height: 50rem;

    // .middle {
    //     display: flex;
    //     flex-direction: column;
    // }

    // .middle > div,
    // .middle > ul {
    //     margin-bottom: 1rem;
    // }

    // background-color: ${palette.GRAY01};
`;

export default SynergePageLayout;
