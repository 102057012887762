import React from 'react';
import UserInfoContainer from '../../container/user/UserInfoContainer';
import UserInfoPageLayout from '../../component/layout/UserInfoPageLayout';
import { RouteComponentProps } from 'react-router-dom';
import Meta from '../../component/common/Meta';

function UserInfoPage({ match, location }: RouteComponentProps) {
    const { nickname, lang } = match.params as any;

    const metaData = {
        title: '전적 검색',
        description: '전적검색 순위 랭크 롤토체스 전략적 팀전투 TFT',
    };
    return (
        <UserInfoPageLayout>
            <Meta data={metaData} />
            <UserInfoContainer nickname={nickname} lang={lang} />
        </UserInfoPageLayout>
    );
}

export default UserInfoPage;
