// components/Meta
/* eslint-disable no-undefined */
import React from 'react';
import { Helmet } from 'react-helmet';

const locales = {
    en: 'en_US',
    ko: 'ko_KR',
} as any;

const Meta = ({ data }: any) => {
    const lang = locales[data.locale] || locales['en'];
    const title = data.title;
    const description = data.description;
    const image = data.image !== undefined && `${data.image}`;
    const canonical = `https://www.your-homepage.com/${data.canonical}`;
    const type = data.type === undefined ? 'website' : data.type;
    const width = data.image && (data.width || 1200);
    const height = data.image && (data.height || 630);
    return (
        <Helmet titleTemplate="%s">
            <html lang={lang} />
            <title>{`롤체패스 시즌 4.5 (${title})`}</title>
            <meta name="description" content={description} />
            <meta
                name="keyword"
                content="롤토체스, TFT, 아이템 조합표, 시너지, 전적 검색, 순위표, 랭킹, 시뮬레이터, 모바일,  전략적 팀 전투,  롤체패스 ,  가이드, 공략 , 명사수 , 챔피언 빌더, 대깨 , 리롤"
            />
            <meta property="og:url" content="web.lol-chess.net" />
            {canonical ? <link rel="canonical" href={canonical} /> : null}
            {image ? <link rel="image_src" href={image} /> : null}
            {image ? <meta itemProp="image" content={image} /> : null}

            <meta property="og:site_name" content="YOUR WEB SITE" />
            <meta property="og:title" content={title} />
            {description ? <meta property="og:description" content={description} /> : null}
            {canonical ? <meta property="og:url" content={canonical} /> : null}
            <meta property="og:locale" content={locales[lang]} />
            <meta property="og:type" content={type} />
            {image ? <meta property="og:image" content={image} /> : null}
            {width ? <meta property="og:image:width" content={width} /> : null}
            {height ? <meta property="og:image:height" content={height} /> : null}
            <meta property="fb:pages" content="YOUR WEB SITE" />

            {/* change type of twitter if there is no image? */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            {description ? <meta name="twitter:description" content={description} /> : null}
            {image ? <meta name="twitter:image" content={image} /> : null}
            <meta name="twitter:site" content="@YOURWEBSITE" />
            {canonical ? <link rel="alternate" href={data.canonical} hrefLang={lang} /> : null}
        </Helmet>
    );
};

export default Meta;
