import React from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import palette from '../../lib/style/palette';
import { ChampionSortType } from '../../module/championbuilder';

const sortButton = css`
    width: 50%;
    height: 100%;
    padding: 0.4rem 1rem 0.4rem 1rem;
    cursor: pointer;
`;

const commonFont = css`
    width: 100%;
    height: 100%;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4px;
    white-space: nowrap;
`;

const ChampionBuilderLinkBlock = styled.ul`
    display: flex;
    margin-right: 1ex;
   width:49rem;
    /* background: ${palette.GRAY10}; */
    .selected {
        background: ${palette.GRAY04};
        a {
            color:white;
        }
    }
    li {
        font-size:0.7rem;
         padding:0.6rem 1.2rem;
		a {
			color:${palette.WHITE05};
        }
    }
	margin-top:1.1rem;
	margin-bottom:1.1rem;
`;

const StyledNavLink = styled(NavLink)`
    display: block;
`;

const SortButtonBlock = styled.div`
    display: flex;
    margin-left: auto;
    border: 1px solid #282c34;
    box-sizing: border-box;
    border-radius: 4px;
    width: 7.9rem;
    height: 2rem;
`;

const ActiveSortButton = styled.div`
    ${(props) => sortButton}
    background-color: ${palette.GRAY04};
`;

const ActiveSortName = styled.p`
    ${(props) => commonFont}
    color: #ffffff;
`;

const SortButton = styled.div`
    ${(props) => sortButton}
    // background-color: ${palette.GRAY04};
`;

const SortName = styled.p`
    ${(props) => commonFont}
    color: ${palette.WHITE05};
`;

const TotalSortButton: React.FC<{
    name: string;
    activeCondition: boolean;
    clickSortConditionButton: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ name, activeCondition, clickSortConditionButton }) => {
    return activeCondition ? (
        <ActiveSortButton onClick={clickSortConditionButton}>
            <ActiveSortName>{name}</ActiveSortName>
        </ActiveSortButton>
    ) : (
        <SortButton onClick={clickSortConditionButton}>
            <SortName>{name}</SortName>
        </SortButton>
    );
};

const ChampionBuilderLinkButtonComponent = ({
    match,
    location,
    championSortCondition,
    clickSortConditionButton,
}: RouteComponentProps & {
    championSortCondition: ChampionSortType;
    clickSortConditionButton: (condition: ChampionSortType) => void;
}) => {
    const getactive = (matchpath: string) => (location.pathname === matchpath ? 'selected' : '');
    const clickSortButton = (condition: ChampionSortType) => (e: React.MouseEvent<HTMLDivElement>) => {
        clickSortConditionButton(condition);
    };

    return (
        <ChampionBuilderLinkBlock>
            <li className={`${getactive(match.path)}`}>
                <StyledNavLink to={`${match.path}`}>전체</StyledNavLink>
            </li>
            <li className={`${getactive(`${match.path}/class`)}`}>
                <StyledNavLink to={`${match.path}/class`}>직업</StyledNavLink>
            </li>
            <li className={`${getactive(`${match.path}/origin`)}`}>
                <StyledNavLink to={`${match.path}/origin`}>계열</StyledNavLink>
            </li>
            <SortButtonBlock>
                <TotalSortButton
                    name="이름순"
                    activeCondition={championSortCondition === 'name'}
                    clickSortConditionButton={clickSortButton('name')}
                />
                <TotalSortButton
                    name="가격순"
                    activeCondition={championSortCondition === 'cost'}
                    clickSortConditionButton={clickSortButton('cost')}
                />
            </SortButtonBlock>
        </ChampionBuilderLinkBlock>
    );
};

export default withRouter(ChampionBuilderLinkButtonComponent);
