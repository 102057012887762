import styled from 'styled-components';
import palette from '../../lib/style/palette';

const UserRankListPageLayout = styled.div`
    position: relative;
    // top: 5%;
    left: 10%;
    // background-color: ${palette.GRAY01};
    width: 32.4rem;
`;

export default UserRankListPageLayout;
