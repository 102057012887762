import React from 'react';
import styled from 'styled-components';
import YouTubeComponent from '../todaymeta/YouTubeComponent';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SampleNextArrow, SamplePrevArrow } from './SliderButton';
import { YoutubeVideo } from '../../../lib/api/lolcheserver/InitdataApi';

const VideoDesCription = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.4px;
    color: #ffffff;
    cursor: pointer;
`;

const VideoChannerNameBlock = styled.div`
    display: flex;
    margin-top: 0.6rem;
`;

const VideoChannerNameImg = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
`;

const VideoChannerName = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.4px;
    color: #cfd3d7;
`;

const VideoSliderList: React.FC<{ videoList: YoutubeVideo[]; clickVideo: (id: number) => void }> = ({
    videoList,
    clickVideo,
}) => {
    const size = {
        width: '384',
        height: '215',
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <Slider {...settings}>
            {videoList.map((recommendvideo, index) => {
                const handleClickVideo = (e: React.MouseEvent<HTMLParagraphElement>) => {
                    clickVideo(recommendvideo.id);
                };
                return (
                    <li key={index}>
                        <YouTubeComponent id={recommendvideo.video_id} size={size} />
                        <VideoDesCription onClick={handleClickVideo}>{recommendvideo.title}</VideoDesCription>

                        <VideoChannerNameBlock>
                            <VideoChannerNameImg src={recommendvideo.channel_img} />
                            <VideoChannerName>{recommendvideo.channel}</VideoChannerName>
                        </VideoChannerNameBlock>
                    </li>
                );
            })}
        </Slider>
    );
};

export default VideoSliderList;
