import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import PlusButtonComponent from '../../component/recommend/PlusButton';
import { recommendActions } from '../../module/recommendmeta';

const PlusButtonContainer = () => {
    const {
        recommendmeta: { totalRecommendList },
    } = useSelector((rootState: RootState) => rootState);
    const dispatch = useDispatch();

    const clickButton = () => {
        dispatch(recommendActions.setDisplayLength(totalRecommendList.length));
    };

    return <PlusButtonComponent click={clickButton} />;
};

export default PlusButtonContainer;
