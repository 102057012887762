import React from 'react';
import styled from 'styled-components';
import DescriptionBox, { DescriptionBoxProps } from '../common/DescriptionBox';
import Box from '../../lib/style/MainIcon/box.svg';
import palette from '../../lib/style/palette';

const ItemDescriptionBlock = styled.div`
    padding: 1.6rem 2.5rem 1.6rem 2.5rem;
    background: #202329;
    border: 1px solid rgba(255, 255, 255, 0.02);
    box-sizing: border-box;
    border-radius: 8px;
    width: 32.4rem;
    height: 10rem;
    margin-left: 1rem;
    // margin-top: 0.8rem;
`;

const ItemDescriptionPaddingBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
`;

const ItemDescriptionImgBlock = styled.div`
    margin-right: 0.8rem;

    img {
        border-radius: 0.6rem;
    }
`;

const ItemDescriptionImg = styled.img`
    min-width: 3.2rem;
    min-height: 3.2rem;
`;

const HasItemInfoDescriptionBox: React.FC<{ src: string } & DescriptionBoxProps> = ({ src, title, infomation }) => {
    return (
        <ItemDescriptionPaddingBlock>
            <ItemDescriptionImgBlock>{src ? <ItemDescriptionImg src={src} /> : <></>}</ItemDescriptionImgBlock>
            <DescriptionBox title={title} infomation={infomation} />
        </ItemDescriptionPaddingBlock>
    );
};

const EmptyItemBoxBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
`;

const EmptyItemBox = styled.div`
    display: flex;
    flex-direction: column;
    img {
        // width: 1.5rem;
        height: 1.5rem;
        justify-self: center;
        margin-bottom: 0.5rem;
    }
    p {
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1rem;
        text-align: center;
        letter-spacing: -0.4px;
        ${palette.WHITE04}
    }
`;

const NoItemInfoDescriptionBox: React.FC = () => {
    return (
        <EmptyItemBoxBlock>
            <EmptyItemBox>
                <img src={Box} alt="" />
                <p>아이템을 선택해주세요</p>
            </EmptyItemBox>
        </EmptyItemBoxBlock>
    );
};

const ItemDescriptionComponent: React.FC<{ src: string; isitemDescriptionOpen: boolean } & DescriptionBoxProps> = ({
    src,
    title,
    infomation,
    isitemDescriptionOpen,
}) => {
    return (
        <ItemDescriptionBlock>
            {isitemDescriptionOpen ? (
                <HasItemInfoDescriptionBox src={src} title={title} infomation={infomation} />
            ) : (
                <NoItemInfoDescriptionBox />
            )}
        </ItemDescriptionBlock>
    );
};

export default ItemDescriptionComponent;
