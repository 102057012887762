import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../module/rootReducer';
import { makeGetter } from '../../../lib/util';
import { Champion } from '../../../lib/api/lolcheserver/InitdataApi';
import ChampionNomalListComponent from '../../../component/championbuilder/ChampionNomalListComponent';
import { parsedSynergeChampionList } from '../../../lib/util/championUtil';
import { championbuilderActions } from '../../../module/championbuilder';
import * as R from 'ramda';

const SelectorTotal = () => {
    const {
        synerge: { indexedSynergeMap },
        champion: { totalChampionList },
        championbuilder: { championSortCondition },
    } = useSelector((state: RootState) => state);
    const getSynerge = makeGetter(indexedSynergeMap);
    // const getChampion = makeGetter(indexedChampionMap);
    // const synergeParser = parsedSynergeChampion(getSynerge);
    const dispatch = useDispatch();
    const sortingSynergeChmpionList = R.sort(
        (a: Champion, b: Champion) => (a[championSortCondition] < b[championSortCondition] ? -1 : 0),
        parsedSynergeChampionList(getSynerge, totalChampionList),
    );

    const click = (payload: { id: number; isSelected: boolean }) => {
        dispatch(championbuilderActions.clickChampionSelector(payload));
    };

    return <ChampionNomalListComponent championList={sortingSynergeChmpionList} click={click} />;
};

export default SelectorTotal;
