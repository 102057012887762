import React, { useEffect } from 'react';
import UserRanksListContainer from '../../container/user/UserRanksListContainer';
import UserRankListPageLayout from '../../component/layout/UserRankListPageLayout';
import Meta from '../../component/common/Meta';
import ReactGa from 'react-ga';

const UserranksListPage = () => {
    const metaData = {
        title: '유저 순위',
        description: '전적검색 순위 랭크 롤토체스 전략적 팀전투 TFT',
    };
    useEffect(() => {
        const pathName = window.location.pathname;
        ReactGa.initialize('G-49LQN3JGJ7');
        ReactGa.set({ page: pathName });
        ReactGa.pageview(pathName);
    });
    return (
        <UserRankListPageLayout>
            <Meta data={metaData} />
            <UserRanksListContainer />
        </UserRankListPageLayout>
    );
};

export default UserranksListPage;
