import React from 'react';
import {
    Champion,
    Item,
    RecommendMeta,
    EnforcedChampion,
    CurrentVersion,
} from '../../lib/api/lolcheserver/InitdataApi';
import RecommendMetaChampionPosition from './RecommendMetaChampionPosition';
import RecommendMetaDetailSynergeListnadHasItemChampionList from './RecommendMetaDetailSynergeList';
import styled from 'styled-components';
import Like from '../../lib/style/MainIcon/Like.svg';

interface RecommendDetalProps {
    recommendDetail: RecommendMeta & {
        isLike: boolean;
    };
    championMap: Record<string, { champion: Champion; items: Item[]; position: number }>;
    hasitemChampionList: EnforcedChampion[];
    itemMap: Record<string, Item>;
    currentVersion: CurrentVersion;
    clickLikeButton: () => void;
}

const RecommendDetailBlock = styled.div`
    // position: relative;
    // top: 1rem;
    // left: 11.3rem;
    // display: flex;
    // flex-direction: column;
    // min-height: 49rem;
`;

const RecommendPositionSynerge = styled.div`
    display: flex;
    margin-top: 1.6rem;
`;

const RecommendTitleBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
const RecommendLike = styled.div<{ isLike: boolean }>`
    width: 4rem;
    height: 4rem;
    background: ${(prop) => (prop.isLike ? '#3D62E4' : '#202329')};
    border-radius: 4.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem;
    ${(prop) => (prop.isLike ? '' : 'cursor:pointer')}
`;
const LikeImg = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;

const LikeNumber = styled.p`
    margin-top: 0.5rem;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 18px;
    color: #cfd3d7;
`;
const RecommendTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #ffffff;
`;
const RecommendPatchVersion = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 18px;
    color: #707888;
    margin-top: 0.5rem;
`;

const RecommendMetaDetailComponent: React.FC<RecommendDetalProps> = ({
    recommendDetail,
    championMap,
    hasitemChampionList,
    itemMap,
    currentVersion,
    clickLikeButton,
}) => {
    return (
        <RecommendDetailBlock>
            <RecommendTitleBlock>
                <RecommendLike
                    onClick={recommendDetail.isLike ? void 0 : clickLikeButton}
                    isLike={recommendDetail.isLike}
                >
                    <LikeImg src={Like} />
                    <LikeNumber>{recommendDetail.like_cnt}</LikeNumber>
                </RecommendLike>
                <RecommendTitle>{recommendDetail.title}</RecommendTitle>
                <RecommendPatchVersion>
                    {`Season ${currentVersion.season} | ${recommendDetail.patch_version}`}
                </RecommendPatchVersion>
            </RecommendTitleBlock>
            <RecommendPositionSynerge>
                <RecommendMetaChampionPosition championMap={championMap} />
                <RecommendMetaDetailSynergeListnadHasItemChampionList
                    recommendMeta={recommendDetail}
                    hasitemChampionList={hasitemChampionList}
                    itemMap={itemMap}
                />
            </RecommendPositionSynerge>
        </RecommendDetailBlock>
    );
};

export default RecommendMetaDetailComponent;
