import * as R from 'ramda';

export type Keytype = string | number;
export type CustomObject = { [key in Keytype]: Record<string, any> };
export const ___ = Symbol('___');
export function curry(fn: Function) {
    const flength = fn.length;
    //@ts-ignore
    return function (...args): ReturnType<fn> {
        return args.length >= flength ? fn(...args) : curry(fn.bind(null, ...args));
    };
}
export const _flatMap = curry((fn: any, array: Array<any>) => array.reduce((a, b) => a.concat(fn(b)), []));

export const _entries = curry((object: Map<string, Record<string, any>>) => Object.entries(object));
export const _mapEntries = curry((keyMap: Function, valueMap: Function, object: CustomObject) =>
    _entries(object).map((a: any) => [keyMap(a[0]), valueMap(a[1])]),
);
export const _isNotEmpty = R.pipe(R.isEmpty, R.not);

/**
 *
 * @param propArray
 * @param object
 * _pick(['name','age'],{name:'abc',age:12,id:'kkkrt'}) => {name:'abc',age:12}
 */

export const _any = curry((fn: any, array: Array<any>) => array.some(fn));

export const _islength = (query: any) => {
    if (Array.isArray(query)) {
        return query.length >= 1;
    }
    return query.trim().length >= 1;
};

export const _compact = (array: Array<any>) => array.filter(R.identity);

export const _iscompactLength = R.pipe(_compact, _islength);

export const _updateList = curry((condition: Function, updateObj: any, list: any[]) => {
    return list.map((a) => (condition(a) ? { ...a, ...updateObj } : a));
});
export const _isEqualArray = (a: Array<any>, b: Array<any>) => {
    return a.every((value, index) => value === b[index]);
};

export const _arrayIncludes = curry((array2d: Array<Array<any>>, array: Array<any>) =>
    _any((a: any) => _isEqualArray(a, array), array2d),
);

export const _compactObj = (obj: any) => {
    return obj || {};
};

export const defaultToZero = R.defaultTo(0);
export const defaultToEmptyStr = R.defaultTo('');
export const makeGetter = R.curry((indexedMap: any, id: number | string) => indexedMap[id]);

/**
propsDefaultToEmptyStr(['a','b','c'],obj) => "" || value
**/
export const getPropsDefaultToEmptyStr = R.pipe(R.path, defaultToEmptyStr);
export const getPropsDefaultToZero: (a: string[], b: any) => number = R.pipe(R.path, defaultToZero) as (
    a: any,
) => number;
// export const getPropsDefaultToEmptyObj = R.pipe(R.path, defaultToEmptyObj);
