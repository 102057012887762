import produce from 'immer';
interface TypedActions<T extends string> {
    type: T;
}

interface TypedPayloadActions<T extends string, P> extends TypedActions<T> {
    type: T;
    payload: P;
}
export function createAction<T extends string>(type: T): TypedActions<T>;
export function createAction<T extends string, P>(type: T, payload: P): TypedPayloadActions<T, P>;
//@ts-ignore
export function createAction<T extends string>(type: T, payload?) {
    return payload !== void 0
        ? {
              type,
              payload,
          }
        : {
              type,
          };
}

export function createReducer<S, A extends TypedActions<string>>(
    initialstate: S,
    handlesMap: {
        [key in A['type']]: (state: S, action: Extract<A, TypedActions<key>>) => void;
    },
) {
    return function (state: S = initialstate, action: A) {
        return produce(state, (draft) => {
            //@ts-ignore
            const handle = handlesMap[action['type']];
            if (handle) {
                handle(draft, action);
            }
        });
    };
}
