import React from 'react';
import RecommendVideoListComponent from '../../component/main/recommendVideo/RecommendVideoListComponent';
import { RootState } from '../../module/rootReducer';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const RecommendVideoContainer = () => {
    const {
        init: { curation_video },
    } = useSelector((rootState: RootState) => rootState);
    const history = useHistory();

    const clickVideo = (id: number) => {
        history.push(`/video/${id}`);
    };

    return <RecommendVideoListComponent curation_video={curation_video} clickVideo={clickVideo} />;
};

export default RecommendVideoContainer;
