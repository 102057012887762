import React from 'react';
import styled from 'styled-components';
import { Notice } from '../../module/notice';
import { noticeTypeColor } from '../../lib/style/palette';
import { getFormatDate } from '../../lib/util/time';

const NoticeListBlock = styled.ul`
    padding: 0.8rem;
    width: 15.8rem;
    min-height: 25.5rem;
    background: #202329;
    margin-right: 1rem;
`;

const NoticeListItem = styled.li`
    p {
        margin-bottom: 0.2rem;
    }
    cursor: pointer;
`;

const NoticeType = styled.p<{ type: string }>`
    color: ${({ type }) => (noticeTypeColor as any)[type]};
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 0.6rem;
    line-height: 0.9rem;
    text-transform: uppercase;
`;
const NoticeTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1.1rem;
    letter-spacing: -0.02rem;
    color: #ffffff;
`;
const NoticeDate = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 0.6rem;
    line-height: 0.9rem;
    color: #5b6370;
`;

const NoticeListComponent: React.FC<{ noticeList: Notice[]; clickNotice: (index: number) => void }> = ({
    noticeList,
    clickNotice,
}) => {
    return (
        <NoticeListBlock>
            {noticeList.map((notice, index) => {
                const click = (e: React.MouseEvent<HTMLLIElement>) => {
                    clickNotice(index);
                };

                return (
                    <NoticeListItem key={index} onClick={click}>
                        <NoticeType type={notice.type}>{notice.type}</NoticeType>
                        <NoticeTitle>{notice.title}</NoticeTitle>
                        <NoticeDate>{getFormatDate(notice.updated_at, 'YYYY-MM-DD')}</NoticeDate>
                    </NoticeListItem>
                );
            })}
        </NoticeListBlock>
    );
};

export default NoticeListComponent;
