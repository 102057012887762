import axios from 'axios';

const apiurl = 'https://lol-chess.net/api';

const apiInstance = axios.create({
    baseURL: apiurl,
});
//apiInstance.defaults.headers.common['key'] = 'RGAPI-00125789-3c07-47e2-bfcf-2c414f2560c2';
apiInstance.defaults.headers.common['APIKEY'] = 'fkejfijdl@!kdj=-dkjfemhy!';

export default apiInstance;
