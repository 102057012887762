import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import { noticeActions } from '../../module/notice';
import NoticeTotalListComponent from '../../component/notice/NoticeTotalComponent';

const NoticeContainer = () => {
    const {
        notice: { noticeList, currentNoticeIndex },
    } = useSelector((rootState: RootState) => rootState);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(noticeActions.fetchNotice());
    }, [dispatch]);

    const clickNotice = (index: number) => {
        dispatch(noticeActions.setNoticeIndex(index));
    };

    return (
        <NoticeTotalListComponent noticeList={noticeList} noticenum={currentNoticeIndex} clickNotice={clickNotice} />
    );
};

export default NoticeContainer;
