import React from 'react';
import { RouteComponentProps, Route, withRouter, Switch } from 'react-router-dom';
import SelectorTotal from './innerroute/SelectorTotal';
import SelectorGroup from './innerroute/SelectorGroup';

const ChampionSelectorContainer = ({ match }: RouteComponentProps) => {
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={SelectorTotal} />
            <Route exact path={`${match.url}/origin`} component={SelectorGroup} />
            <Route exact path={`${match.url}/class`} component={SelectorGroup} />
        </Switch>
    );
};

export default withRouter(ChampionSelectorContainer);
