import React from 'react';
import styled from 'styled-components';
import { SynergeType, SynergeTier } from '../../lib/api/lolcheserver/InitdataApi';
import { CellBoxClickPayload } from './ChampionCellBox';
import palette, { synergeTierColor } from '../../lib/style/palette';
import { getEffect } from '../../lib/util/synergeUtil';
import { SynergeImageComponent } from '../common/Image';
import ChampionGroupCardList from './ChampionGroupCardList';
import { EnforcedSynerge } from '../../module/championbuilder';
import * as R from 'ramda';

interface ChampionGroupListComponentProps {
    synergeList: SynergeType[];
    click: (id: CellBoxClickPayload) => void;
    synergestaticList: EnforcedSynerge[];
}

const ChampionGroupListBlock = styled.ul`
    display: grid;
    width: 49rem;
    grid-template-columns: repeat(3, 15.8rem);
    grid-gap: 1rem;
    row-gap: 1rem;
`;

const ChampionGroupliBlock = styled.li`
    display: flex;
    flex-direction: column;
    height: 10.3rem;
    p {
        margin-bottom: 1em;
        padding: 1em;
        width: 100%;
    }
`;

const SynergeInfoUl = styled.ul`
    display: flex;
    background-color: ${palette.GRAY05};
    padding: 12px 14px;
    height: 2.3rem;
`;

const SysnergeInfo = styled.li`
    color: white;
    display: flex;
    align-items: center;
`;

const SysnergeNumber = styled.li`
    display: flex;
    margin-left: auto;
`;

const Circle = styled.div<{ synergetier?: number }>`
    width: 0.9rem;
    height: 0.9rem;
    border: 1px solid #313843;
    box-sizing: border-box;
    border-radius: 68px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    background-color: ${(props) => synergeTierColor[props.synergetier as SynergeTier]};

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0.2rem;
`;

const SynergeName = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #ffffff;
`;

const SynergeInfoComponent: React.FC<
    {
        synerge: SynergeType;
    } & Pick<ChampionGroupListComponentProps, 'synergestaticList'>
> = ({ synerge, synergestaticList }) => {
    const getfindsynerge = R.pipe(
        R.find((staticsynerge: any) => staticsynerge.synerge.id === synerge.id),
        R.prop('count'),
    );
    const activeCount = getfindsynerge(synergestaticList) as number | undefined;

    return (
        <SynergeInfoUl>
            <SysnergeInfo>
                <SynergeImageComponent src={synerge.image} width={0.5} height={0.5} synergetier={3} />
                <SynergeName>{synerge.name}</SynergeName>
            </SysnergeInfo>
            <SysnergeNumber>
                {getEffect(synerge).map((a: any, index: number) => (
                    <Circle key={index} synergetier={activeCount === a[0] ? activeCount : void 0}>
                        {a[0]}
                    </Circle>
                ))}
            </SysnergeNumber>
        </SynergeInfoUl>
    );
};

const ChampionGroupListComponent: React.FC<ChampionGroupListComponentProps> = ({
    synergeList,
    click,
    synergestaticList,
}) => (
    <ChampionGroupListBlock>
        {synergeList.map((synerge) => (
            <ChampionGroupliBlock key={synerge.id}>
                <SynergeInfoComponent synerge={synerge} synergestaticList={synergestaticList} />
                <ChampionGroupCardList championList={synerge.champions!} click={click} />
            </ChampionGroupliBlock>
        ))}
    </ChampionGroupListBlock>
);

export default ChampionGroupListComponent;
