import React, { useEffect } from 'react';
import ChatComponent from '../../component/chat/ChatComponent';
import { addFireBaseChatListenEvent } from '../../lib/api/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../module/chat';
import { RootState } from '../../module/rootReducer';
import { getChatStorage } from '../../lib/api/localstorage';

const ChatContainer = () => {
    const { chatList } = useSelector((state: RootState) => state.chat);
    const dispatch = useDispatch();

    useEffect(() => {
        const localChatList = getChatStorage();

        const timestamp = localChatList.chatList.length === 0 ? 0 : localChatList.timestamp;

        // const timestamp = getPropsDefaultToZero(['userTime'], R.head(chatList));
        const unsub = addFireBaseChatListenEvent(timestamp);
        return () => {
            unsub();
        };
    }, []);

    const addchat = (message: string) => {
        dispatch(chatActions.postChat(message));
    };

    return <ChatComponent chatList={chatList} addchat={addchat} />;
};

export default ChatContainer;
