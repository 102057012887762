import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TooltipInfo, tooltipActions } from '../../module/tooltip';
import { ItemImageToolTipComponent, ItemImageProps } from '../../component/common/Image';
import styled from 'styled-components';
import { RootState } from '../../module/rootReducer';

const RecommendItemImageOnMouseEventBlock = styled.div``;

const RecommendItemImageOnMouseEvent: React.FC<ItemImageProps> = (props) => {
    const { indexedItemMap } = useSelector((rootState: RootState) => rootState.item);
    const dispatch = useDispatch();
    const handleMouseover = (e: React.MouseEvent) => {
        const tooltipObj = { ...props.item, childItem: props.item.child_item.map((a) => indexedItemMap[a]) };
        const { x } = e.currentTarget.getBoundingClientRect();
        const absoluteTop = window.pageYOffset + e.currentTarget.getBoundingClientRect().top;
        const tooltipObject: TooltipInfo = {
            type: 'item',
            tooltipObject: tooltipObj,
            position: {
                x: x + 40,
                y: absoluteTop + 30,
            },
            isshow: true,
        };
        dispatch(tooltipActions.mouseover(tooltipObject));
    };

    const handleMouseout = (e: React.MouseEvent) => {
        dispatch(tooltipActions.mouseout());
    };

    return (
        <RecommendItemImageOnMouseEventBlock onMouseOver={handleMouseover} onMouseOut={handleMouseout}>
            <ItemImageToolTipComponent {...props} />
        </RecommendItemImageOnMouseEventBlock>
    );
};

export default RecommendItemImageOnMouseEvent;
