import React from 'react';
import styled from 'styled-components';
import { EnforcedChampion } from '../../lib/api/lolcheserver/InitdataApi';
import { RecommendChampionImageonMouseEventHex } from '../../container/common/RecommendChampionImageonMouseEventHex';
import { ChampionPositionHexaImg } from '../common/HexaImg';
import RecommendItemImageOnMouseEvent from '../../container/common/RecommendItemImageOnMouseEvent';
import * as R from 'ramda';

interface RecommendMetaChampionPositionProps {
    championMap: Record<string, EnforcedChampion>;
}

const RecommendMetaChampionPositionBlock = styled.div`
    margin-right: 1rem;
`;

const RecommendItem = styled.ul`
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    width: 100%;
    bottom: 6px;
    left: -2.2rem;
    //  z-index: 10;
    gap: 0.1rem;
`;

const ChmapionPositionList = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    ul:nth-child(even) {
        margin-left: 2.2rem;
    }
    ul:nth-child(n + 1) {
        margin-top: -0.5rem;
    }
`;

const Championrow = styled.ul`
    display: flex;
`;

const HexaImgBlock = styled.div`
    position: relative;
`;

const ChampionListTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #e8e9e9;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
`;

// const ChampionImgHaveItem: React.FC<{ champion: EnforcedChampion }> = ({ champion }) => {
//     return (
//         <RecommendChampion>
//             <RecommendChampionImageonMouseEvent champion={champion.champion} />
//             <RecommendItem>
//                 {champion.items.map((item, index) => (
//                     <li key={index}>
//                         <ItemImageToolTipComponent item={item} size={'small'} />
//                     </li>
//                 ))}
//             </RecommendItem>
//         </RecommendChampion>
//     );
// };

const RecommendMetaChampionPosition: React.FC<RecommendMetaChampionPositionProps> = ({ championMap }) => {
    const emptyArray = [R.range(0, 7), R.range(0, 7), R.range(0, 7), R.range(0, 7)];
    return (
        <RecommendMetaChampionPositionBlock>
            <ChampionListTitle>챔피언 배치</ChampionListTitle>
            <ChmapionPositionList>
                {emptyArray.map((a, i) => {
                    return (
                        <Championrow key={i}>
                            {a.map((b, j) => {
                                const num = i * 7 + j;
                                return championMap[num] ? (
                                    <HexaImgBlock key={num}>
                                        <ChampionPositionHexaImg>
                                            <RecommendChampionImageonMouseEventHex
                                                champion={championMap[num].champion}
                                            />
                                        </ChampionPositionHexaImg>
                                        <RecommendItem>
                                            {championMap[num].items.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <RecommendItemImageOnMouseEvent item={item} size={'medium'} />
                                                    </li>
                                                );
                                            })}
                                        </RecommendItem>
                                    </HexaImgBlock>
                                ) : (
                                    <ChampionPositionHexaImg key={num} />
                                );
                            })}
                        </Championrow>
                    );
                })}
            </ChmapionPositionList>
        </RecommendMetaChampionPositionBlock>
    );
};

export default RecommendMetaChampionPosition;
