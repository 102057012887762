import { createAction, createReducer } from '../lib/reduxHelper';

export interface Login {
    userId?: string;
    userImg?: string;
    userName?: string;
}

export interface ChatPageState {
    error: boolean;
    loginUser: Login;
}

export enum ActionType {
    SETUSER = 'login/setuser',
    SETLOGINERROR = 'login/seterror',
}

export const loginActions = {
    setuser: (user: Login) => createAction(ActionType.SETUSER, user),
    setLoginError: (iserror: boolean) => createAction(ActionType.SETLOGINERROR, iserror),
};

type Action = ReturnType<typeof loginActions[keyof typeof loginActions]>;

const intialState: ChatPageState = {
    error: false,
    loginUser: {},
};

const reducer = createReducer<ChatPageState, Action>(intialState, {
    [ActionType.SETUSER]: (state, action) => {
        state.loginUser = action.payload;
        return state;
    },
    [ActionType.SETLOGINERROR]: (state, action) => {
        state.error = action.payload;
        return state;
    },
});

export default reducer;
