import React from 'react';
import styled from 'styled-components';
import { Champion } from '../../lib/api/lolcheserver/InitdataApi';
import { CellBoxClickPayload } from './ChampionCellBox';
import ChampionCellBoxLite from './ChampionCellBoxLite';
import palette from '../../lib/style/palette';

interface ChampionNomalListComponentProps {
    championList: Champion[];
    click: (id: CellBoxClickPayload) => void;
}

const ChampionListBlock = styled.div`
    padding: 1.6rem 2.3rem 1.6rem 2.3rem;
    background-color: ${palette.GRAY04};
    width: 49rem;
    border: 1.00816px solid rgba(255, 255, 255, 0.02);
    box-sizing: border-box;
    border-radius: 8px;
`;

const ChampionNomalListUlBox = styled.ul`
    display: grid;
    grid-template-columns: repeat(12, 3rem);
    gap: 0.8rem;
    row-gap: 0.9rem;
    width: 45rem;
`;

const ChampionNomalListComponent = ({ championList, click }: ChampionNomalListComponentProps) => (
    <ChampionListBlock>
        <ChampionNomalListUlBox>
            {/* <ChampionCellBox champion={champion} key={champion.id} click={click} /> */}
            {championList.map((champion) => (
                <li key={champion.id}>
                    <ChampionCellBoxLite champion={champion} click={click} />
                </li>
            ))}
        </ChampionNomalListUlBox>
    </ChampionListBlock>
);

export default ChampionNomalListComponent;
