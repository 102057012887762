import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MatrixItemListComponent from '../../../component/item/MatrixItemListComponent';
import { RootState } from '../../../module/rootReducer';
import { itemActions } from '../../../module/item';
import { Item } from '../../../lib/api/lolcheserver/InitdataApi';

function MatrixItemListContainer() {
    const { itemMatrix } = useSelector((rootState: RootState) => rootState.item);
    const dispatch = useDispatch();
    const onMouseOver = (param: { yx: Array<number>; item: Item }) => {
        dispatch(itemActions.matrixMouseOver(param));
    };
    const onMouseOut = () => {
        dispatch(itemActions.matrixMouseOut());
    };
    return <MatrixItemListComponent itemMatrix={itemMatrix} mouseover={onMouseOver} mouseout={onMouseOut} />;
}

export default MatrixItemListContainer;
