import { ofType, StateObservable, combineEpics } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { fetchInitData, TotalAPI, SynergeType, CurrentVersion } from '../lib/api/lolcheserver/InitdataApi';
import { createAction, createReducer } from '../lib/reduxHelper';
import { championActions } from './champion';
import { itemActions } from './item';
import { recommendActions } from './recommendmeta';
import { synergeActions } from './synerge';
import { RootState } from './rootReducer';
import { parsedSynergeChampionOfRecommendMeta } from '../lib/util/championUtil';
import { makeGetter } from '../lib/util';
import { getUser } from '../lib/api/localstorage';
import { loginActions } from './login';
import * as R from 'ramda';

export enum ActionType {
    GETINITDATA = 'init/getinitdata',
    SETINITDATA = 'init/setinitdata',
    SETVERSION = 'init/setversion',
    ERROR = 'init/error',
}

export const initActions = {
    getinitdata: () => createAction(ActionType.GETINITDATA),
    setinitdata: (totalApi: TotalAPI) => createAction(ActionType.SETINITDATA, totalApi),
    setversion: (version: CurrentVersion) => createAction(ActionType.SETVERSION, version),
    erroraction: () => createAction(ActionType.ERROR),
};

type Action = ReturnType<typeof initActions[keyof typeof initActions]>;

const intialState: TotalAPI = {
    champions: [],
    synerge: {
        origin: [],
        class: [],
    },
    item: [],
    recommend_meta: [],
    curation_video: {
        id: 0,
        like_cnt: 0,
        sub_title: '',
        title: '',
        youtube_videos: [],
    },
    current_version: {
        server_version: '11.2',
        season: 4.5,
        patchnote_url: 'https://kr.leagueoflegends.com/ko-kr/news/game-updates/teamfight-tactics-patch-11-2-notes/',
        android_force_update: 20104,
        ios_force_update: 20803,
        server_update_version: '11.2_10',
        advertisement: {
            name: 'facebook',
            margin: 0,
            option: {
                count: 5,
                group: 'enter_count',
                minutes: [],
            },
        },
    },
};

const fetchInitDataEpic = (action$: Observable<any>, state: StateObservable<RootState>) =>
    action$.pipe(
        ofType(ActionType.GETINITDATA),
        switchMap((action) =>
            fetchInitData().pipe(
                // tap((initdata) => {
                //     if (!localStorage.getItem('initdata')) {
                //         localStorage.setItem('initdata', JSON.stringify(initdata));
                //     }
                // }),
                // tap(() => {
                //     throw Error;
                // }),
                mergeMap(([totalApi, current_version]) => {
                    const synergeGetter = makeGetter(
                        R.indexBy((a: SynergeType) => a.id, [...totalApi.synerge.origin, ...totalApi.synerge.class]),
                    );
                    const parsedSynergedRecommendMetaList = parsedSynergeChampionOfRecommendMeta(
                        synergeGetter,
                        totalApi.recommend_meta,
                    );
                    const user = getUser(totalApi.champions);

                    return of(
                        itemActions.setInitItemList(totalApi.item),
                        synergeActions.setInitSynergeList(totalApi.synerge),
                        championActions.setInitChamPionList(totalApi.champions),
                        recommendActions.setInitRecommendList(parsedSynergedRecommendMetaList),
                        loginActions.setuser(user),
                        loginActions.setLoginError(false),
                        // recommendActions.setInitRecommendList(totalApi.recommend_meta),
                        initActions.setinitdata(totalApi),
                    );
                }),
                catchError((error) => {
                    // const user = getUser(totalApi.champions);
                    return of(initActions.erroraction(), loginActions.setLoginError(true));
                }),
            ),
        ),
    );

const reducer = createReducer<TotalAPI, Action>(intialState, {
    [ActionType.GETINITDATA]: (state, action) => {
        //state = state;
    },
    [ActionType.SETINITDATA]: (state, action) => {
        state.champions = action.payload.champions;
        state.synerge = action.payload.synerge;
        state.curation_video = action.payload.curation_video;
    },
    [ActionType.SETVERSION]: (state, action) => {
        state.current_version = action.payload;
    },
    [ActionType.ERROR]: (state, action) => {
        state = intialState;
        //state = state;
    },
});

export const combineinitEpic = combineEpics(fetchInitDataEpic);

export default reducer;
