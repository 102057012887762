import React from 'react';
import styled from 'styled-components';
import { SynergeSmallImageComponent } from './Image';
import palette from '../../lib/style/palette';
import * as R from 'ramda';

interface TooltipProps {
    TooltipObj: any;
    position: {
        x: number;
        y: number;
    };
}

const ChampionTooltipBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.8rem 1.2rem;
    width: 18.25rem;
    background: ${palette.GRAY10};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    // z-index: 999;
    .champion-block-item {
        padding: 0.5rem;
    }

    .champion-name {
        font-size: 0.9rem;
        color: ${palette.WHITE01};
    }

    .champion-synerge {
        display: flex;
    }
    .synerge-info {
        display: flex;
        background: ${palette.WHITE09};
        border-radius: 100px;
        padding: 0.2rem 0.5rem;
        margin-right: 0.3rem;
        span {
            font-size: 0.6rem;
            color: ${palette.WHITE01};
            line-height: 0.9rem;
        }
        & > * {
            margin-right: 0.2rem;
        }

        .synerge-info-image {
            display: block;
        }
    }
    .champion-stat {
        display: flex;
        margin: 0.4rem 0;
        font-size: 0.7rem;
        & > * {
            margin-right: 1rem;
        }

        li {
            span:nth-child(2) {
                color: ${palette.WHITE06};
            }
        }

        li:nth-child(1) {
        }
        li:nth-child(2) {
            color: ${palette.WHITE01};
        }

        li:nth-child(3) {
            color: ${palette.WHITE01};
        }
    }
    .champion-skill {
        display: flex;
        & > * {
            margin-right: 1rem;
        }
        img {
            width: 2rem;
            height: 2rem;
        }
        p {
            margin-bottom: 0.5rem;
        }
        font-size: 0.7rem;

        .champion-skill-info {
            p:nth-child(1) {
                color: ${palette.WHITE01};
            }
            p:nth-child(2) {
                color: ${palette.WHITE06};
            }
        }
    }
`;

export const ChamPionNameSynergeInfoComponent: React.FC<{ championName: string; synerges: any }> = ({
    championName,
    synerges,
}) => {
    return (
        <div className="champion-name-synerge champion-block-item">
            <p className="champion-name">{championName}</p>
            <ul className="champion-synerge">
                {synerges?.map((synerge: any, index: any) => (
                    <li key={index} className="synerge-info">
                        <SynergeSmallImageComponent
                            src={R.prop('image', synerge)}
                            width={0.6}
                            height={0.6}
                            className="synerge-info-image"
                        />
                        <span>{R.prop('name', synerge)}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const CommonChampionTooltip: React.FC<TooltipProps> = ({ TooltipObj, position }) => {
    return (
        <div style={{ position: 'absolute', top: position.y, left: position.x }}>
            <ChampionTooltipBlock>
                <ChamPionNameSynergeInfoComponent championName={TooltipObj.name} synerges={TooltipObj.synerges} />
                <ul className="champion-stat champion-block-item">
                    <li>STAT</li>
                    <li>
                        <span>마나</span> <span>0/{TooltipObj.skill_mana}</span>
                    </li>
                    <li>
                        <span>사거리</span> <span>{TooltipObj.attack_distance}</span>
                    </li>
                </ul>
                <div className="champion-skill champion-block-item">
                    <img src={TooltipObj.skill_image} alt="" />
                    <div className="champion-skill-info">
                        <p>{TooltipObj.skill_name}</p>
                        <p>{TooltipObj.skill_description}</p>
                    </div>
                </div>
            </ChampionTooltipBlock>
        </div>
    );
};

export const RecommendChampionTooltip: React.FC<TooltipProps> = ({ TooltipObj, position }) => {
    return <div style={{ position: 'absolute', top: position.y, left: position.x }}></div>;
};

const ItemTooltipBlock = styled.div`
    display: flex;
    width: 18.2rem;
    height: 8.6rem;
    padding: 1.2rem;
    background: #2c3038;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
`;

const ItemInnerbox = styled.div`
    width: 15.8rem;
    height: 6.2rem;
    display: flex;
`;

const ItemImageBox = styled.div`
    // width: 2.1rem;
    // height: 2.1rem;
    margin-right: 0.5rem;
`;
const ItemImage = styled.img`
    min-width: 2.1rem;
    min-height: 2.1rem;
    // width: 100%;
    // height: 100%;
`;
const ItemDesciptionBox = styled.div``;

const ItemTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1.1rem;
    letter-spacing: -0.02rem;
    color: #ffffff;
    opacity: 0.9;
    margin-bottom: 0.1rem;
`;

const ItemDescription = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1.1rem;
    letter-spacing: -0.02rem;
    color: #9fa5b0;
    margin-bottom: 0.4rem;
`;

const ItemCombinationBox = styled.div`
    display: flex;
    padding: 0.5rem;
`;

const ItemCombinationItembox = styled.div``;

const ItemCombinationItem = styled.img`
    width: 1.4rem;
    height: 1.4rem;
`;

export const CommonItemTooltip: React.FC<TooltipProps> = ({ TooltipObj, position }) => {
    const getImg = R.prop('image');
    const [childItem, childItem2] = TooltipObj.childItem;
    const img1 = getImg(childItem) as string;
    const img2 = getImg(childItem2) as string;

    return (
        <div style={{ position: 'absolute', top: position.y, left: position.x }}>
            <ItemTooltipBlock>
                <ItemInnerbox>
                    <ItemImageBox>
                        <ItemImage src={TooltipObj.image} />
                    </ItemImageBox>
                    <ItemDesciptionBox>
                        <ItemTitle>{TooltipObj.name}</ItemTitle>
                        <ItemDescription>{TooltipObj.description}</ItemDescription>
                        <ItemCombinationBox>
                            <ItemCombinationItembox>
                                {<ItemCombinationItem src={img1} />}+{<ItemCombinationItem src={img2 || img1} />}
                            </ItemCombinationItembox>
                        </ItemCombinationBox>
                    </ItemDesciptionBox>
                </ItemInnerbox>
            </ItemTooltipBlock>
        </div>
    );
};
