import { createAction, createReducer } from '../lib/reduxHelper';
import { Observable, of } from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { RootState } from './rootReducer';
import { mergeMap } from 'rxjs/operators';
import { searchInputAction } from './searchInput';

export interface OverlayState {
    isOverlay: boolean;
}

export enum ActionType {
    OPENOVERLAY = 'overlay/openoverlay',
    CLOSEOVERLAYSTART = 'overlay/closeoverlaystart',
    CLOSEOVERLAY = 'overlay/closeoverlay',
}

export const overlayActions = {
    openOverlay: () => createAction(ActionType.OPENOVERLAY),
    closeOverlaystart: () => createAction(ActionType.CLOSEOVERLAYSTART),
    closeOverlay: () => createAction(ActionType.CLOSEOVERLAY),
};

type Action = ReturnType<typeof overlayActions[keyof typeof overlayActions]>;

const intialState: OverlayState = {
    isOverlay: false,
};
const clickOverlayEpic = (
    action$: Observable<ReturnType<typeof overlayActions['closeOverlaystart']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.CLOSEOVERLAYSTART),
        mergeMap((data) => {
            return of(overlayActions.closeOverlay(), searchInputAction.close(), searchInputAction.closesearchuser());
        }),
    );

const reducer = createReducer<OverlayState, Action>(intialState, {
    [ActionType.OPENOVERLAY]: (state) => {
        state.isOverlay = true;
    },
    [ActionType.CLOSEOVERLAYSTART]: (state) => {
        // state = state;
    },
    [ActionType.CLOSEOVERLAY]: (state) => {
        state.isOverlay = false;
    },
});

export const overlayEpics = combineEpics(clickOverlayEpic);
export default reducer;
