import React from 'react';
import styled from 'styled-components';
import ic_search from '../../lib/style/MainIcon/ic_search.png';
import ic_notice from '../../lib/style/MainIcon/ic_notice.svg';
import message from '../../lib/style/MainIcon/message-square.svg';
import messageActive from '../../lib/style/MainIcon/message-square-active.svg';

interface MainHeaderSearchComponentProps {
    chat: () => void;
    goNotice: () => void;
    clickSearch: () => void;
    isChatOpen: boolean;
}

const MainHeaderSearchComponentBlock = styled.ul`
    display: flex;
`;

const HeaderImg = styled.img`
    width: 0.8rem;
    height: 0.8rem;
    padding-right: 0.5rem;
`;

const ChatNoticeIconBlock = styled.li`
    margin-left: 1rem;
    img {
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        cursor: pointer;
    }
`;

const MainSearchComponent: React.FC<MainHeaderSearchComponentProps> = ({ chat, goNotice, clickSearch, isChatOpen }) => {
    const clickNotice = (e: React.MouseEvent<HTMLImageElement>) => {
        goNotice();
    };

    const clickSearchIcon = (e: React.MouseEvent<HTMLImageElement>) => {
        clickSearch();
    };

    return (
        <MainHeaderSearchComponentBlock>
            <ChatNoticeIconBlock>
                <HeaderImg src={ic_search} onClick={clickSearchIcon} />
                <HeaderImg src={isChatOpen ? messageActive : message} onClick={chat} />
                <HeaderImg src={ic_notice} onClick={clickNotice} />
            </ChatNoticeIconBlock>
        </MainHeaderSearchComponentBlock>
    );
};

export default MainSearchComponent;
