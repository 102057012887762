import React, { useCallback } from 'react';
import styled from 'styled-components';
import palette from '../../lib/style/palette';
import ic_search from '../../lib/style/MainIcon/ic_search.png';
import { UserAPIData } from '../../lib/api/lolcheserver/user';
import SearchUserList from './SearchUserList';

export interface SearchInputAndUserListComponentProps {
    nickname: string;
    change: (a: string) => void;
    submit: (param: { nickname: string; lang: string }) => void;
    isUserListOpen: boolean;
    favoritesUserList: UserAPIData[];
    deleteLikeUser: (user: UserAPIData) => void;
    latestUserList: UserAPIData[];
    deleteLatestUser: (user: UserAPIData) => void;
    setUserLang: (lang: string) => void;
    userlang: string;
}

const MainSearchBlock = styled.div`
    border: 1px solid ${palette.GRAY04};
    background: ${palette.GRAY04};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 10;
    height: 3rem;
`;

const NationSelect = styled.select`
    border: none;
    background: ${palette.GRAY08};
    color: white;
`;

const SearchInput = styled.input`
    color: white;
    border: none;
    background: ${palette.GRAY08};
    &:focus {
        outline: none;
    }
`;

const HeaderImg = styled.img`
    width: 0.8rem;
    height: 0.8rem;
    margin-left: auto;
`;

const UserInputBlock = styled.div`
    display: flex;
    width: 17.4rem;
    height: 2rem;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: ${palette.GRAY08};
    border-radius: 90px;
`;

const SearchUserListBlock = styled.div``;
const SearchUserTitleBlock = styled.div`
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
`;
const SearchUserTitle = styled.h2`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */
    letter-spacing: -0.4px;
    color: white;
`;

const SearchInputAndUserListComponent: React.FC<SearchInputAndUserListComponentProps> = ({
    nickname,
    change,
    submit,
    isUserListOpen,
    favoritesUserList,
    deleteLikeUser,
    latestUserList,
    deleteLatestUser,
    setUserLang,
    userlang,
}) => {
    const ref = useCallback((node) => {
        if (node) {
            node.focus();
        }
    }, []);

    const onNationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUserLang(e.currentTarget.value);
    };

    const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
        change(e.currentTarget.value);
    };

    const onsubmit = (e: React.MouseEvent<HTMLSpanElement>) => {
        submit({ nickname: nickname, lang: userlang });
    };

    const onkeyup = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            submit({ nickname: nickname, lang: userlang });
        }
    };

    return (
        <MainSearchBlock>
            <UserInputBlock>
                <NationSelect onChange={onNationChange} value={userlang}>
                    <option value="kr">KR</option>
                    <option value="na1">NA</option>
                    <option value="jp">JP</option>
                    <option value="ru1">RU</option>
                    <option value="br1">BR</option>
                    <option value="oc1">OC</option>
                    <option value="oc1">OC</option>
                    <option value="euw1">EUW</option>
                    <option value="tr1">TR</option>
                    <option value="la1">LA1</option>
                    <option value="la2">LA2</option>
                </NationSelect>
                <SearchInput
                    type="search"
                    placeholder="소환사 아이디"
                    value={nickname}
                    onChange={onchange}
                    onKeyUp={onkeyup}
                    ref={ref}
                />
                <HeaderImg src={ic_search} onClick={onsubmit} />
            </UserInputBlock>
            {isUserListOpen ? (
                <SearchUserListBlock>
                    <SearchUserTitleBlock>
                        <SearchUserTitle>전적 검색</SearchUserTitle>
                    </SearchUserTitleBlock>
                    <SearchUserList
                        userList={favoritesUserList}
                        deleteMethod={deleteLikeUser}
                        isLikeUser={true}
                        submit={submit}
                        userlang={userlang}
                    />
                    <SearchUserList
                        userList={latestUserList}
                        deleteMethod={deleteLatestUser}
                        isLikeUser={false}
                        submit={submit}
                        userlang={userlang}
                    />
                </SearchUserListBlock>
            ) : (
                <></>
            )}
        </MainSearchBlock>
    );
};

export default SearchInputAndUserListComponent;
