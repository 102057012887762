import { createAction, createReducer } from '../lib/reduxHelper';
import { Observable } from 'rxjs';
import { RootState } from './rootReducer';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { fetchNoticeList } from '../lib/api/lolcheserver/notice';

export interface Notice {
    id: number;
    title: string;
    content: string;
    image: string;
    type: string;
    view_count: number;
    created_at: Date;
    updated_at: Date;
}

export interface NoticePageState {
    noticeList: Notice[];
    currentNoticeIndex: number;
    isLoading: boolean;
}

export enum ActionType {
    FETCHNOTICE = 'notice/fetchnotice',
    SETNOTICE = 'notice/setnotice',
    SETCURRENTINDEX = 'notice/setcurrentindex',
}

export const noticeActions = {
    fetchNotice: () => createAction(ActionType.FETCHNOTICE),
    setNotice: (noticeList: Notice[]) => createAction(ActionType.SETNOTICE, noticeList),
    setNoticeIndex: (index: number) => createAction(ActionType.SETCURRENTINDEX, index),
};

type Action = ReturnType<typeof noticeActions[keyof typeof noticeActions]>;

const intialState: NoticePageState = {
    noticeList: [],
    currentNoticeIndex: 0,
    isLoading: false,
};

const fetchNoticeListEpic = (
    action$: Observable<ReturnType<typeof noticeActions['fetchNotice']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FETCHNOTICE),
        switchMap((action) => {
            return fetchNoticeList();
        }),
        map((data) => {
            return noticeActions.setNotice(data);
        }),
    );

const reducer = createReducer<NoticePageState, Action>(intialState, {
    [ActionType.FETCHNOTICE]: (state, action) => {
        state.isLoading = true;
    },
    [ActionType.SETNOTICE]: (state, action) => {
        state.noticeList = action.payload;
        state.isLoading = false;
    },
    [ActionType.SETCURRENTINDEX]: (state, action) => {
        state.currentNoticeIndex = action.payload;
    },
});

export const noticeEpics = combineEpics(fetchNoticeListEpic);

export default reducer;
