import { combineReducers } from 'redux';
import item, { itemCombineEpics } from './item';
import champion, { championEpics } from './champion';
import synerge from './synerge';
import recommendmeta, { recommendEpics } from './recommendmeta';
import championbuilder, { championbuilderEpics } from './championbuilder';
import user, { userEpics } from './user';
import init from './init';
import game from './game';
import { combineEpics } from 'redux-observable';
import { combineinitEpic } from './init';
import tooltip, { tooltipEpics } from './tooltip';
import chat, { chatEpics } from './chat';
import login from './login';
import loading from './loading';
import overlay, { overlayEpics } from './overlay';
import searchInput, { searchInputEpic } from './searchInput';
import notice, { noticeEpics } from './notice';
import video, { videoEpics } from './video';

export const rootEpic = combineEpics(
    combineinitEpic,
    itemCombineEpics,
    championEpics,
    recommendEpics,
    championbuilderEpics,
    userEpics,
    tooltipEpics,
    chatEpics,
    noticeEpics,
    searchInputEpic,
    overlayEpics,
    videoEpics,
);

const createRootReducer = () =>
    combineReducers({
        item,
        champion,
        synerge,
        recommendmeta,
        init,
        championbuilder,
        user,
        tooltip,
        chat,
        login,
        game,
        notice,
        overlay,
        searchInput,
        video,
        loading,
    });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
