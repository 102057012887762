import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/style/palette';
import { RecommendMeta } from '../../../lib/api/lolcheserver/InitdataApi';
import RecommendChampionImageonMouseEvent from '../../../container/common/RecommendChampionImageonMouseEvent';
import Like from '../../../lib/style/MainIcon/Like.svg';
import RecommendItemImageOnMouseEvent from '../../../container/common/RecommendItemImageOnMouseEvent';

interface FindRecommendMetaComponentProps {
    recommendMetaList: RecommendMeta[];
    goDetail: any;
}

const FindRecommendMetaBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    * {
        color: white;
    }
    /* flex-wrap: wrap; */
    /* @media (min-width: 768px) {
        width: 50%;
        .flex-item {
            width: 20%;
        }
    } */
    cursor: pointer;
`;

const FindRecommendCardBlock = styled.div`
    display: flex;
    padding: 1em;
    background-color: ${palette.GRAY03};
    margin-bottom: 1em;
    .recommendmeta-name {
        margin: 1em;
        font-size: 0.8rem;
        background-color: '#FFFFFF';
    }
`;

const FindRecommendCardListBlock = styled.div`
    margin-top: 2rem;
`;

const RecommendTitleBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LikeBlock = styled.div`
    margin-right: 1.6rem;
`;

const LikeImg = styled.img``;

const LikeNumber = styled.p`
    font-size: 0.7rem;
    line-height: 0.9rem;
    text-align: center;
    color: ${palette.WHITE08};
`;

const RecommendTitle = styled.p`
    font-size: 1rem;
    line-height: 1.45rem;
    letter-spacing: -0.02rem;
    color: ${palette.WHITE01};
`;

const RecommendHot = styled.p`
    font-weight: 900;
    font-size: 0.7rem;
    line-height: 0.9rem;
    color: #ff676a;
    margin-left: 0.7rem;
`;

const RecommendChampionList = styled.ul`
    margin-top: 0.2em;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
`;

const RecommendChampion = styled.li`
    position: relative;
    margin-right: 0.5rem;
`;

const RecommendItem = styled.ul`
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    width: 100%;
    bottom: -8px;
`;

const FindRecommendCard: React.FC<{ recommendMeta: RecommendMeta; goDetail: any }> = ({ recommendMeta, goDetail }) => {
    const handleGodetail = (e: any) => {
        goDetail(recommendMeta.id);
    };

    return (
        <FindRecommendCardBlock onClick={handleGodetail}>
            <RecommendTitleBlock>
                <LikeBlock>
                    <LikeImg src={Like} />
                    <LikeNumber>{recommendMeta.like_cnt}</LikeNumber>
                </LikeBlock>
                <RecommendTitle>{recommendMeta.title}</RecommendTitle>
                <RecommendHot>HOT</RecommendHot>
            </RecommendTitleBlock>
            <RecommendChampionList>
                {recommendMeta.champions.map(({ champion, items, position }, index) => (
                    <RecommendChampion key={index}>
                        <RecommendChampionImageonMouseEvent champion={champion} isNoChampionName={true} />
                        <RecommendItem>
                            {items.map((item, index) => (
                                <li key={index}>
                                    <RecommendItemImageOnMouseEvent item={item} size={'small'} />
                                </li>
                            ))}
                        </RecommendItem>
                    </RecommendChampion>
                ))}
            </RecommendChampionList>
        </FindRecommendCardBlock>
    );
};

const FindRecommendMetaComponent: React.FC<FindRecommendMetaComponentProps> = ({
    recommendMetaList,
    children,
    goDetail,
}) => {
    return (
        <FindRecommendMetaBlock>
            {children}
            <FindRecommendCardListBlock>
                {recommendMetaList.map((recommendMeta, index) => (
                    <FindRecommendCard recommendMeta={recommendMeta} key={index} goDetail={goDetail} />
                ))}
            </FindRecommendCardListBlock>
        </FindRecommendMetaBlock>
    );
};

export default FindRecommendMetaComponent;
