import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchChampionComponent from '../../component/champion/SearchChampionComponent';
import RecommendMetaComponent from '../../component/recommend/RecommendMetaComponent';
import { championActions } from '../../module/champion';
import { recommendActions } from '../../module/recommendmeta';
import { RootState } from '../../module/rootReducer';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';

interface RecommendMetaContainerProps {
    displayLength?: number;
}

const RecommendMetaContainer: React.FC<RecommendMetaContainerProps> = ({ displayLength = 50 }) => {
    const {
        recommendmeta: { searchRecommendList },
        champion: { searchChampionList },
    } = useSelector((rootState: RootState) => rootState);
    const dispatch = useDispatch();
    const history = useHistory();

    const click = useCallback(
        (id: number) => {
            dispatch(recommendActions.clickChampionImage(id));
        },
        [dispatch],
    );
    const search = useCallback(
        (query: string) => {
            dispatch(championActions.searchChamPionListdebounce(query));
        },
        [dispatch],
    );

    useEffect(() => {
        return () => {
            dispatch(championActions.setsearchChamPionList([]));
        };
    }, []);

    const goDetail = (id: number) => {
        history.push(`/recommendMeta/${id}`);
    };

    return (
        <RecommendMetaComponent recommendMetaList={R.take(displayLength, searchRecommendList)} goDetail={goDetail}>
            <SearchChampionComponent searchedChampionList={searchChampionList} onserch={search} onclick={click} />
        </RecommendMetaComponent>
    );
};

export default RecommendMetaContainer;
