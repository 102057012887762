import React from 'react';
import FailImg from '../../lib/style/MainIcon/Fail.svg';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { searchInputAction } from '../../module/searchInput';

const ErrorPageBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 38rem;
`;

const ErrorImg = styled.img``;

const NotFoundMessage = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #ffffff;
    text-align: center;
`;

const CheckNameMessage = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #707888;
`;

const RetrySearchButtonBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
`;

const RetrySearchButton = styled.button`
    width: 200px;
    height: 50px;
    background: #3d62e4;
    border-radius: 100px;
`;

const ErrorCode: React.FC<{ code: number }> = ({ code }) => {
    const dispatch = useDispatch();
    const errorMessage = {
        404: ['앗! 소환사를 찾지 못했어요', '소환사 이름을 다시 한번 확인해주세요.'],
        600: ['앗! 게임 전적이 없어요', '게임 한판 하고 오세요', ''],
    } as any;

    const clickButton = (e: any) => {
        dispatch(searchInputAction.focusinput());
    };

    return (
        <>
            <NotFoundMessage>{errorMessage[code][0]}</NotFoundMessage>
            <CheckNameMessage>{errorMessage[code][1]}</CheckNameMessage>
            <RetrySearchButtonBlock>
                <RetrySearchButton onClick={clickButton}>다시 검색하기</RetrySearchButton>
            </RetrySearchButtonBlock>
        </>
    );
};

const UserInfoErrorContainer: React.FC<{ code: number }> = ({ code }) => {
    return (
        <ErrorPageBlock>
            <ErrorImg src={FailImg} />
            <ErrorCode code={code} />
        </ErrorPageBlock>
    );
};

export default UserInfoErrorContainer;
