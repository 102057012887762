import { createAction, createReducer } from '../lib/reduxHelper';
import { Observable } from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { RootState } from './rootReducer';
import { map, mapTo, filter } from 'rxjs/operators';
import { Champion } from '../lib/api/lolcheserver/InitdataApi';
import { NullAble } from '../lib/util/typehelper';

export type TooltipKinds = 'recommendchampion' | 'commonchampion' | 'none' | 'item';

export type TooltipInfo = {
    type: TooltipKinds;
    tooltipObject: any;
    position: {
        x: number;
        y: number;
    };
    isshow: boolean;
};

export interface TooltipState {
    tooltipInfo: TooltipInfo;
}

export type NullableTooltipState = NullAble<TooltipState>;

export enum ActionType {
    INITIALIZETOOLTIP = 'tooltip/initializetooltip',
    SETTOOLTIP = 'tooltip/settooltip',
    CLOSETOOLTIP = 'tooltip/closetooltip',
    TOOLTIPMOUSEROVER = 'tooltip/mouseover',
    TOOLTIPMOUSEROUT = 'tooltip/mouseout',
}

export const tooltipActions = {
    initializetooltip: () => createAction(ActionType.INITIALIZETOOLTIP),
    settooltip: (toolTip: TooltipInfo) => createAction(ActionType.SETTOOLTIP, toolTip),
    closetooltip: () => createAction(ActionType.CLOSETOOLTIP),
    mouseover: (toolTip: TooltipInfo) => createAction(ActionType.TOOLTIPMOUSEROVER, toolTip),
    mouseout: () => createAction(ActionType.TOOLTIPMOUSEROUT),
};

type Action = ReturnType<typeof tooltipActions[keyof typeof tooltipActions]>;

const intialState: TooltipState = {
    tooltipInfo: {
        type: 'none',
        tooltipObject: {} as Champion,
        position: {
            x: 0,
            y: 0,
        },
        isshow: false,
    },
};

export const mouseOverEpic = (
    action$: Observable<ReturnType<typeof tooltipActions['mouseover']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.TOOLTIPMOUSEROVER),
        filter((action) => {
            const beforeState = state.value.tooltip.tooltipInfo;
            return action.payload.tooltipObject.id !== beforeState.tooltipObject.id;
        }),
        map((action) => tooltipActions.settooltip(action.payload)),
    );

const mouseOutEpic = (
    action$: Observable<ReturnType<typeof tooltipActions['mouseout']>>,
    state: StateObservable<RootState>,
) => action$.pipe(ofType(ActionType.TOOLTIPMOUSEROUT), mapTo(tooltipActions.initializetooltip()));

const reducer = createReducer<TooltipState, Action>(intialState, {
    [ActionType.INITIALIZETOOLTIP]: (state, action) => {
        state.tooltipInfo = {
            type: 'none',
            tooltipObject: {} as any,
            position: {} as any,
            isshow: false,
        };
    },
    [ActionType.CLOSETOOLTIP]: (state, action) => {
        state.tooltipInfo.isshow = false;
    },
    [ActionType.SETTOOLTIP]: (state, action) => {
        state.tooltipInfo = action.payload;
    },
    [ActionType.TOOLTIPMOUSEROVER]: (state, action) => {
        // state = state;
    },
    [ActionType.TOOLTIPMOUSEROUT]: (state, action) => {
        // state = state;
    },
});

export const tooltipEpics = combineEpics(mouseOverEpic, mouseOutEpic);
export default reducer;
