import { createGlobalStyle } from 'styled-components';
import palette from '../lib/style/palette';

const globalStyle = createGlobalStyle`
html {
        font-family: 'Apple SD Gothic Neo', Roboto, 'Noto Sans KR', NanumGothic, 'Malgun Gothic', sans-serif;
        color: #555;
        line-height: 1.2;
        word-wrap: break-word;
}
body {
        // background: #eee;
        background: #000000;
        -webkit-font-smoothing: antialiased;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
}
html {
	font-family: 'Apple SD Gothic Neo', Roboto, 'Noto Sans KR', NanumGothic, 'Malgun Gothic', sans-serif;
	color: #555;
	line-height: 1.2;
	word-wrap: break-word;
	font-size:20px;
}
body {
	background: ${palette.GRAY00};
	-webkit-font-smoothing: antialiased;
	font-size:1rem;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
div, span, article, section, header, footer, aside, p, ul, li, fieldset, legend, label, a, nav, form {
	box-sizing: border-box;
	/* content-box */
}
ol, ul, li {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
	max-width: 100%;
	height: auto;
	border: 0;
}
a {
	display: inline-block;
	text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
        color: white;
    }
    outline: none;
}
button {
	border: 0;
	background: transparent;
	cursor: pointer;
}
input { 

-webkit-appearance : none;

-moz-appearance:none;

appearance:none;

}







/* input type number 에서 화살표 제거 */

input[type=number]::-webkit-inner-spin-button,

input[type=number]::-webkit-out-spin-button {

-webkit-appearance: none;

-moz-appearance:none;

appearance:none;

}

`;

export default globalStyle;
