import React from 'react';
import { useDispatch } from 'react-redux';
import { TooltipInfo, tooltipActions } from '../../module/tooltip';
import { ChampionImageProps } from '../../component/common/Image';
import styled from 'styled-components';
import { costTierColor } from '../../lib/style/palette';

export const ChampionImageBlock = styled.div`
    display: inline-block;
    position: relative;
`;

const ChampionInnerImgBlock = styled.div<{ width?: string; height?: string }>`
    width: ${(props) => (props.width ? props.width : '2.9rem')};
    height: ${(props) => (props.height ? props.height : '3.2rem')};
    flex-shrink: 0;
`;

const ChampionName = styled.div`
    // font-size: 0.6rem;
    text-align: center;
    position: absolute;
    // bottom: 1%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 18px;

    color: #ffffff;
`;

export const ChamPionImage = styled.img<{ cost: number }>`
    // width: 100%;
    // height: 100%;
    border: ${(props) => `3px solid ${costTierColor[props.cost]}`};
    border-radius: 130.435px;
`;

export const RecommendChampionImageonMouseEvent: React.FC<ChampionImageProps & { isNoChampionName?: boolean }> = ({
    champion,
    width,
    heigth,
    onClick,
    isNoChampionName,
}) => {
    const dispatch = useDispatch();
    const handleMouseover = (e: React.MouseEvent) => {
        const { x } = e.currentTarget.getBoundingClientRect();
        const absoluteTop = window.pageYOffset + e.currentTarget.getBoundingClientRect().top;

        const tooltipObject: TooltipInfo = {
            type: 'recommendchampion',
            tooltipObject: champion,
            position: {
                x: x + 50,
                y: absoluteTop + 50,
            },
            isshow: true,
        };
        dispatch(tooltipActions.mouseover(tooltipObject));
    };

    const handleMouseout = (e: React.MouseEvent) => {
        dispatch(tooltipActions.mouseout());
    };

    return (
        <ChampionImageBlock
            onClick={onClick}
            onMouseOver={handleMouseover}
            /* onClick={handleMouseover} */
            onMouseOut={handleMouseout}
        >
            <ChampionInnerImgBlock width={width} height={heigth}>
                <ChamPionImage src={champion.image} cost={champion.cost} />
            </ChampionInnerImgBlock>
            {isNoChampionName ? <></> : <ChampionName>{champion.name}</ChampionName>}
        </ChampionImageBlock>
    );
};

export default RecommendChampionImageonMouseEvent;
