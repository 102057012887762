import React, { useEffect } from 'react';
import MainPageLayout from '../../component/layout/MainPageLayout';
import RecommendVideoContainer from '../../container/main/RecommendVideoContainer';
import FindRecommendContainer from '../../container/main/FindRecommendMetaContainer';
import PlusButtonContainer from '../../container/main/PlusButtonContainer';
import MainPageSvg from '../../lib/style/mainSvg2.svg';
import styled from 'styled-components';
import Meta from '../../component/common/Meta';
import ReactGa from 'react-ga';

const MainImgBlock = styled.div`
    width: 120%;
    position: relative;
    left: -7%;
`;

const MainImg = styled.img`
    width: 100%;
`;

function MainPage() {
    const metaData = {
        title: '롤체패스',
        description:
            '추천 영상, 추천 메타, 추천메타 검색, 챔피언 검색, 챔피언 추천메타 검색, 추천 유튜브 , 시너지 , 빌더 , 조합 , TFT 전략적 팀전투',
    };
    useEffect(() => {
        const pathName = window.location.pathname;
        ReactGa.initialize('G-49LQN3JGJ7');
        ReactGa.set({ page: pathName });
        ReactGa.pageview(pathName);
    });
    return (
        <MainPageLayout>
            {/* <div /> */}
            <>
                <Meta data={metaData} />
                <MainImgBlock>
                    <MainImg src={MainPageSvg} alt="롤토체스 메인 이미지 전략적 팀 전투 메인 이미지 TFT" />
                </MainImgBlock>
                {/* <TotayMetaContainer /> */}
                <RecommendVideoContainer />
                <FindRecommendContainer />
                <PlusButtonContainer />
            </>
            {/* <div /> */}
        </MainPageLayout>
    );
}
export default MainPage;
