import React from 'react';
import styled from 'styled-components';
import { UserRank } from '../../lib/api/lolcheserver/user';
import { getWinrate } from '../../lib/util/userUtil';
import palette from '../../lib/style/palette';
// import Lightning from '../../lib/style/MainIcon/Lightning.svg';
import Loading from '../common/Loading';
import NationSelect from './NationSelect';
import TierSelect from './TierSelect';
import { CustomSelectType } from '../../module/user';

interface UserRanksListConentProps {
    userRanksList: UserRank[];
    tier: CustomSelectType;
    lang: CustomSelectType;
    isLoading: boolean;
    changeLangEvent: (lang: CustomSelectType) => void;
    changeTierEvent: (tier: CustomSelectType) => void;
    setNationSelectOpen: (isopen: boolean) => void;
    setTierSelectOpen: (isopen: boolean) => void;
    isNationSelectOpen: boolean;
    isTierSelectOpen: boolean;
}

const UserRanksListComponentBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    width: 49rem;
    min-height: 50rem;
`;

const UserRankTitleBlock = styled.ul`
    display: flex;
    li:last-child {
        margin-left: 0.4rem;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        // letter-spacing: -0.4px;
        color: #ffffff;
    }
`;

const UserRankSearchCondition = styled.ul`
    display: flex;
    margin-top: 1.2rem;
    margin-bottom: 0.8rem;
    * {
        color: #ffffff;
        font-size: 0.7rem;
        line-height: 2.1rem;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
    }
`;

const UserRankNationConditionLi = styled.li`
    min-width: 3.65rem;
    margin-right: 0.75rem;
`;

const UserRankSearchConditionLi = styled.li`
    min-width: 7rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // padding: 10px 18px;
    // background-color: ${palette.GRAY04};
    // margin-right: 0.8rem;
`;

const UserRanksListUl = styled.table`
    position: relative;
    tr {
        border-radius: 8px 8px 0px 0px;
    }
    th,
    td {
        padding: 16px 0px;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.4px;
        color: #ffffff;
    }
`;

const UserRankHeaderBlock = styled.thead`
    tr {
        background: #282c34;
    }
`;

const UserRankTableBody = styled.tbody``;

const LoadingBlock = styled.div`
    position: absolute;
    left: 50%;
    top: 25rem;
`;

const UserRanksList: React.FC<Pick<UserRanksListConentProps, 'userRanksList' | 'isLoading' | 'tier'>> = ({
    userRanksList,
    isLoading,
    tier,
}) => {
    return (
        <UserRanksListUl>
            <UserRankHeaderBlock>
                <tr>
                    <th>순위</th>
                    <th>소환사</th>
                    <th>티어</th>
                    <th>LP</th>
                    <th>승리</th>
                    <th>패배</th>
                    <th>승률</th>
                </tr>
            </UserRankHeaderBlock>
            {isLoading ? (
                <LoadingBlock>
                    <Loading />
                </LoadingBlock>
            ) : (
                <UserRankTableBody>
                    {userRanksList.map((userRank, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{userRank.summonerName}</td>
                            <td>{tier.displayname}</td>
                            <td>{userRank.leaguePoints}</td>
                            <td>{userRank.wins}</td>
                            <td>{userRank.losses}</td>
                            <td>{getWinrate(userRank.wins, userRank.losses)}</td>
                        </tr>
                    ))}
                </UserRankTableBody>
            )}
        </UserRanksListUl>
    );
};

const UserRanksListComponent: React.FC<UserRanksListConentProps> = ({
    userRanksList,
    isLoading,
    tier,
    lang,
    changeLangEvent,
    changeTierEvent,
    setNationSelectOpen,
    setTierSelectOpen,
    isNationSelectOpen,
    isTierSelectOpen,
}) => {
    return (
        <UserRanksListComponentBlock>
            <UserRankTitleBlock>
                <li>전적</li>
            </UserRankTitleBlock>
            <UserRankSearchCondition>
                <UserRankNationConditionLi>
                    <NationSelect
                        lang={lang}
                        changeLangEvent={changeLangEvent}
                        isNationSelectOpen={isNationSelectOpen}
                        setNationSelectOpen={setNationSelectOpen}
                        setTierSelectOpen={setTierSelectOpen}
                    />
                </UserRankNationConditionLi>
                <UserRankSearchConditionLi>
                    <TierSelect
                        tier={tier}
                        changeTierEvent={changeTierEvent}
                        isTierSelectOpen={isTierSelectOpen}
                        setNationSelectOpen={setNationSelectOpen}
                        setTierSelectOpen={setTierSelectOpen}
                    />
                </UserRankSearchConditionLi>
            </UserRankSearchCondition>
            <UserRanksList userRanksList={userRanksList} isLoading={isLoading} tier={tier} />
        </UserRanksListComponentBlock>
    );
};

export default UserRanksListComponent;
