import React from 'react';
import { useSelector } from 'react-redux';
import CommonSynergeComponent from '../../../component/synerge/CommonSynergeComponent';
import { processSynergeTypeData } from '../../../lib/util/synergeUtil';
import { RootState } from '../../../module/rootReducer';

const OriginSynergeContainer = () => {
    const {
        champion: { indexedChampionMap },
        synerge: { totalSynergeList },
    } = useSelector((rootState: RootState) => rootState);
    const processedSynergeList = processSynergeTypeData(indexedChampionMap, totalSynergeList.origin);
    return <CommonSynergeComponent synergeList={processedSynergeList} />;
};

export default OriginSynergeContainer;
