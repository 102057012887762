import React from 'react';

export function WithDefaultProps<T extends React.AllHTMLAttributes<HTMLElement>, K>(
    Component: React.ComponentClass<K> | React.FC<K>,
) {
    const ReturnComponent: React.FC<T & K> = ({ ...props }) => {
        return <Component {...props} />;
    };
    return ReturnComponent;
}
