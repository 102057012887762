import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../module/rootReducer';
import { parsedChampionSynerge } from '../../../lib/util/synergeUtil';
import { makeGetter } from '../../../lib/util';
import ChampionGroupListComponent from '../../../component/championbuilder/ChampionGroupListComponent';
import { championbuilderActions } from '../../../module/championbuilder';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CellBoxClickPayload } from '../../../component/championbuilder/ChampionCellBox';
import { Champion } from '../../../lib/api/lolcheserver/InitdataApi';
import * as R from 'ramda';

const SelectorGroup = ({ match: { path } }: RouteComponentProps) => {
    const synergetype = R.last(path.split(/\//)) as 'origin' | 'class';
    const {
        champion: { indexedChampionMap },
        synerge: { totalSynergeList, indexedSynergeMap },
        championbuilder: {
            championSortCondition,
            builder: { synergestaticList },
        },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const getChampion = makeGetter(indexedChampionMap);
    const getSynerge = makeGetter(indexedSynergeMap);
    const parsedoriginSynergeList = totalSynergeList[synergetype]
        .map((synerge) => parsedChampionSynerge(synerge, getChampion, getSynerge))
        .map((total) => {
            const sortCham = R.sort(
                (a: Champion, b: Champion) => (a[championSortCondition] < b[championSortCondition] ? -1 : 0),
                total.champions,
            );
            return {
                ...total,
                champions: sortCham,
            };
        });
    const click = (payload: CellBoxClickPayload) => {
        dispatch(championbuilderActions.clickChampionSelector(payload));
    };
    return (
        <ChampionGroupListComponent
            synergeList={parsedoriginSynergeList}
            click={click}
            synergestaticList={synergestaticList}
        />
    );
};

export default withRouter(SelectorGroup);
