import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './component/common/Header';
import championbuilder from './pages/championbuilder';
import Item from './pages/item';
import recommendmeta from './pages/recommendmeta';
import synerge from './pages/synerge';
import user from './pages/user';
import main from './pages/main';
import TooltipContainer from './container/tooltip/TooltipContainer';
import ChatContainer from './container/chat/ChatContainer';
import SeapoongPage from './pages/game/SeaPoongPage';
import NoticePage from './pages/notice/NoticePage';
import Footer from './component/common/Footer';
import OverLayContainer from './container/overlay/OverLayContainer';
import { useSelector } from 'react-redux';
import { RootState } from './module/rootReducer';
import SearchAndSearchedUserListContainer from './container/user/SearchAndSearchedUserListContainer';
import styled from 'styled-components';
// import AdImg from './lib/style/adImg.png';
import VideoPage from './pages/video/VideoPage';
// import MainPageSvg from './lib/style/mainSvg2.svg';

const Main = styled.div`
    display: grid;
    grid-template-columns: 1fr 1200px 1fr;
`;

const AdImgBlock = styled.div``;

function App() {
    const {
        searchInput: { isOpen },
        chat: { isopen },
        login: { error },
    } = useSelector((root: RootState) => root);

    // useEffect(() => {
    //     window.addEventListener('click', (e: any) => {
    //         userActions.setistierselectopen(false);
    //         userActions.setisnationselectopen(false);
    //     });
    // });

    return (
        <>
            <Router>
                {isOpen ? <SearchAndSearchedUserListContainer /> : <Header />}
                {/* {window.location.pathname === '/' ? <MainImg src={MainPageSvg} alt="" /> : <></>} */}
                <Main>
                    <div />
                    <div className="router">
                        <Route path="/item" render={Item} />
                        <Route path="/synerge" render={synerge} />
                        <Route path="/recommendmeta" render={recommendmeta} />
                        <Route path="/championbuilder" render={championbuilder} />
                        <Route path="/user" render={user} />
                        {/* <Route path="/game" render={SeapoongPage} /> */}
                        <Route exact path="/" render={main} />
                        <Route path="/notice" render={NoticePage} />
                        <Route path="/video/:id" render={VideoPage} />
                    </div>
                    <AdImgBlock>{/* <img src={AdImg} alt="" /> */}</AdImgBlock>
                </Main>
            </Router>
            {!error && isopen ? <ChatContainer /> : <></>}
            <TooltipContainer />
            <OverLayContainer />
            <Footer />
        </>
    );
}

export default App;
