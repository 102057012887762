import { createAction, createReducer } from '../lib/reduxHelper';
import { UserRank, fetchUserRanks, fetchUser, UserAPIData } from '../lib/api/lolcheserver/user';
import { Observable, from, of } from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { RootState } from './rootReducer';
import { switchMap, map, mergeMap, catchError, filter, tap } from 'rxjs/operators';
import { NullAble } from '../lib/util/typehelper';
import { _islength } from '../lib/util';
import { addLatestUserList } from '../lib/api/localstorage';
import { overlayActions } from './overlay';
import * as R from 'ramda';

export type NullAbleUser = NullAble<UserAPIData>;

export type CustomSelectType = {
    displayname: string;
    value: string;
    image?: any;
};

export interface UserPageState {
    userRankList: UserRank[];
    user: NullAbleUser;
    nickname: string;
    userlang: string;
    tier: CustomSelectType;
    lang: CustomSelectType;
    error: NullAble<number>;
    loading: boolean;
    isNationSelectOpen: boolean;
    isTierSelectOpen: boolean;
}

export type fetchUserRanksParam = {
    tier: CustomSelectType;
    lang: CustomSelectType;
};

export enum ActionType {
    INITUSER = 'user/inituser',
    FETCHUSER = 'user/fetchuser',
    SETUSER = 'user/setuser',
    SETUSERLANG = 'user/setuserlang',
    FETCHUSERRANKS = 'user/fetchuserranks',
    SETUSERRANKS = 'user/setuserranks',
    INPUTCHANGENICKNAME = 'user/inputchangenickname',
    SETNICKNAME = 'user/setnickname',
    FETCHUSERREJECTED = 'user/fetchuserrejected',
    ISNATIONSELECTOPEN = 'user/isnationselectopen',
    ISTIERSELECTOPEN = 'user/istierselectopen',
    // SETTIER = 'user/settier',
    // SETLANG = 'user/setlang',
}

export const userActions = {
    inituser: () => createAction(ActionType.INITUSER),
    fetchuser: (param: { nickname: string; lang: string }) => createAction(ActionType.FETCHUSER, param),
    setuser: (user: NullAbleUser) => createAction(ActionType.SETUSER, user),
    fetchuserranks: (params: fetchUserRanksParam) => createAction(ActionType.FETCHUSERRANKS, params),
    setuserranks: (userRanksList: UserRank[]) => createAction(ActionType.SETUSERRANKS, userRanksList),
    inputchangenickname: (nickname: string) => createAction(ActionType.INPUTCHANGENICKNAME, nickname),
    setnickname: (nickname: string) => createAction(ActionType.SETNICKNAME, nickname),
    setuserlang: (lang: string) => createAction(ActionType.SETUSERLANG, lang),
    fetchUserRejected: (status: number | null) => createAction(ActionType.FETCHUSERREJECTED, status),
    // settier: (tier: string) => createAction(ActionType.SETTIER, tier),
    // setlang: (lang: string) => createAction(ActionType.SETLANG, lang),
    setisnationselectopen: (isopen: boolean) => createAction(ActionType.ISNATIONSELECTOPEN, isopen),
    setistierselectopen: (isopen: boolean) => createAction(ActionType.ISTIERSELECTOPEN, isopen),
};

type Action = ReturnType<typeof userActions[keyof typeof userActions]>;

const intialState: UserPageState = {
    userRankList: [],
    user: null,
    nickname: '',
    userlang: 'kr',
    tier: { displayname: 'Challenger', value: 'challenger' },
    lang: { value: 'ko', displayname: 'KR' },
    error: null,
    loading: false,
    isNationSelectOpen: false,
    isTierSelectOpen: false,
};

const fetchUserRanksEpic = (
    action$: Observable<ReturnType<typeof userActions['fetchuserranks']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FETCHUSERRANKS),
        switchMap((action) => {
            const { tier, lang } = state.value.user;
            return from(fetchUserRanks({ tier: tier.value, lang: lang.value })).pipe(
                map(({ data }) => userActions.setuserranks(data)),
            );
        }),
    );

const changeNickNameEpic = (
    action$: Observable<ReturnType<typeof userActions['inputchangenickname']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.INPUTCHANGENICKNAME),
        map((action) => userActions.setnickname(action.payload)),
    );

const fetchUserEpic = (
    action$: Observable<ReturnType<typeof userActions['fetchuser']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FETCHUSER),
        filter((action) => _islength(action.payload.nickname)),
        switchMap((action) => {
            return fetchUser(action.payload).pipe(
                tap(({ data }) => {
                    if (R.isEmpty(data.score_data)) {
                        throw { status: 600 };
                    }
                }),
                mergeMap(({ data }) =>
                    of(
                        userActions.setuser(data),
                        userActions.setnickname(''),
                        userActions.fetchUserRejected(null),
                        overlayActions.closeOverlaystart(),
                    ),
                ),
                catchError((error) => {
                    return of(
                        userActions.setnickname(''),
                        userActions.setuser(null),
                        userActions.fetchUserRejected(error.status),
                        overlayActions.closeOverlaystart(),
                        // searchInputAction.closesearchuser(),
                    );
                }),
            );
        }),
    );

const reducer = createReducer<UserPageState, Action>(intialState, {
    [ActionType.INITUSER]: (state, action) => {
        // state;
        state.user = null;
        state.error = null;
        state.nickname = '';
    },

    [ActionType.FETCHUSERRANKS]: (state, action) => {
        state.loading = true;
        state.tier = action.payload.tier;
        state.lang = action.payload.lang;
    },
    [ActionType.SETUSERRANKS]: (state, action) => {
        state.userRankList = action.payload;
        state.loading = false;
    },
    [ActionType.FETCHUSER]: (state, action) => {
        state.loading = true;
    },
    [ActionType.SETUSER]: (state, action) => {
        state.user = action.payload;
        if (action.payload) {
            addLatestUserList(action.payload!);
        }
        state.loading = false;
    },
    [ActionType.SETNICKNAME]: (state, action) => {
        state.nickname = action.payload;
    },
    [ActionType.SETUSERLANG]: (state, action) => {
        state.userlang = action.payload;
    },
    [ActionType.INPUTCHANGENICKNAME]: (state, action) => {
        // state = state;
    },
    [ActionType.FETCHUSERREJECTED]: (state, action) => {
        state.error = action.payload;
    },
    [ActionType.ISNATIONSELECTOPEN]: (state, action) => {
        state.isNationSelectOpen = action.payload;
    },
    [ActionType.ISTIERSELECTOPEN]: (state, action) => {
        state.isTierSelectOpen = action.payload;
    },
    // [ActionType.SETTIER]: (state, action) => {
    //     state.tier = action.payload;
    // },
    // [ActionType.SETLANG]: (state, action) => {
    //     state.lang = action.payload;
    // },
});

export const userEpics = combineEpics(fetchUserRanksEpic, changeNickNameEpic, fetchUserEpic);

export default reducer;
