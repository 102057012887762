import React from 'react';
import styled from 'styled-components';
import { RecommendMeta } from '../../lib/api/lolcheserver/InitdataApi';
import palette from '../../lib/style/palette';
// import { SynergeImageComponent } from '../common/Image';
import RecommendChampionImageonMouseEvent from '../../container/common/RecommendChampionImageonMouseEvent';
import RecommendItemImageOnMouseEvent from '../../container/common/RecommendItemImageOnMouseEvent';
import { SynergeImgHexa } from '../common/HexaImg';

interface RecommendMetaComponentProps {
    recommendMetaList: RecommendMeta[];
    goDetail: (id: number) => void;
}

const RecommendMetaBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 32.4rem;
    * {
        color: white;
    }
    /* flex-wrap: wrap; */
    /* @media (min-width: 768px) {
        width: 50%;
        .flex-item {
            width: 20%;
        }
    } */
`;

const RecommendTitleBlock = styled.div`
    margin-bottom: 1.2rem;
`;

const RecommendTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
`;

const RecommendCardBlock = styled.div`
    // padding: 1em;
    background-color: ${palette.GRAY03};
    margin-bottom: 1em;
    cursor: pointer;
`;

const ChampionInfomationList = styled.ul`
    margin-top: 0.2em;
    display: flex;
    padding: 1em;
    justify-content: space-between;
    & > li {
        position: relative;
    }
`;

const ChampionInfomationItemList = styled.ul`
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    width: 100%;
    bottom: -8px;
    left: 8%;
`;

const ChampionInfomationItemListLi = styled.li`
    margin-right: 0.2rem;
`;

const RecommendName = styled.p`
    margin: 1em;
    font-size: 0.8rem;
    background-color: '#FFFFFF';
`;

const TitleandSynergeIconBlock = styled.div`
    // display: flex;
    background: ${palette.GRAY06};
    padding: 0.2rem 1.2rem;
`;

const SynergeIconListUl = styled.ul`
    display: flex;
    margin-left: auto;
    align-self: center;
    padding-top: 0.5rem;
`;

const SynergeBlock = styled.li`
    margin-right: 0.2rem;
`;

const TitleandSynergeIconPaddingBlock = styled.div`
    display: flex;
`;

const TitleandSynergeIconComponent: React.FC<Pick<RecommendMeta, 'synerges'> & { title: string }> = ({
    synerges,
    title,
}) => {
    return (
        <TitleandSynergeIconBlock>
            <TitleandSynergeIconPaddingBlock>
                <RecommendName>{title}</RecommendName>
                <SynergeIconListUl>
                    {synerges.map((synerge, index) => (
                        <SynergeBlock key={index}>
                            <SynergeImgHexa width={20} tier={synerge.synerge_tier} image={synerge.image} />
                        </SynergeBlock>
                    ))}
                </SynergeIconListUl>
            </TitleandSynergeIconPaddingBlock>
        </TitleandSynergeIconBlock>
    );
};

const RecommendCard: React.FC<{ recommendMeta: RecommendMeta; goDetail: (id: number) => void }> = ({
    recommendMeta,
    goDetail,
}) => {
    const click = (e: React.MouseEvent<HTMLDivElement>) => {
        goDetail(recommendMeta.id);
    };

    return (
        <RecommendCardBlock className="flex-item synerge-page1" onClick={click}>
            <TitleandSynergeIconComponent synerges={recommendMeta.synerges} title={recommendMeta.title} />
            <ChampionInfomationList>
                {recommendMeta.champions.map(({ champion, items, position }, index) => (
                    <li key={index}>
                        <RecommendChampionImageonMouseEvent champion={champion} isNoChampionName={true} />
                        <ChampionInfomationItemList>
                            {items.map((item, index) => (
                                <ChampionInfomationItemListLi key={index}>
                                    <RecommendItemImageOnMouseEvent item={item} size={'medium'} />
                                </ChampionInfomationItemListLi>
                            ))}
                        </ChampionInfomationItemList>
                    </li>
                ))}
            </ChampionInfomationList>
            {/* </div> */}
        </RecommendCardBlock>
    );
};

const MemoedRecommendCard = React.memo(RecommendCard);

const RecommendMetaComponent: React.FC<RecommendMetaComponentProps> = ({ recommendMetaList, children, goDetail }) => {
    return (
        <RecommendMetaBlock>
            <RecommendTitleBlock>
                <RecommendTitle>추천 조합</RecommendTitle>
            </RecommendTitleBlock>
            {children}
            {recommendMetaList.map((recommendMeta, index) => (
                <MemoedRecommendCard recommendMeta={recommendMeta} key={index} goDetail={goDetail} />
            ))}
        </RecommendMetaBlock>
    );
};

export default RecommendMetaComponent;
