import React from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import palette from '../../lib/style/palette';
// import Litening from '../../lib/style/MainIcon/Lightning.svg';

const SynergeTabLinkBlock = styled.ul`
    display: flex;
    margin-right: 1ex;
	margin-bottom:1rem;
    /* background: ${palette.GRAY10}; */
    .selected {
        background: ${palette.GRAY04};
        a {
            color:white;
        }
    }
    li {
        font-size:0.7rem;
        padding:0.6rem 1.2rem;
        a {
            color:${palette.GRAY10};
        }
    }
`;
const StyledNavLink = styled(NavLink)`
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: -0.02rem;
    color: ${palette.WHITE01};
`;

const SynergeLinkButtonComponent = ({ match, location }: RouteComponentProps) => {
    const getactive = (matchpath: string) => (location.pathname === matchpath ? 'selected' : '');
    return (
        <SynergeTabLinkBlock>
            <li className={`${getactive(`${match.path}`)}`}>
                <StyledNavLink to={`${match.path}`}>직업 시너지</StyledNavLink>
            </li>
            <li className={`${getactive(`${match.path}/origin`)}`}>
                <StyledNavLink to={`${match.path}/origin`}>계열 시너지</StyledNavLink>
            </li>
        </SynergeTabLinkBlock>
    );
};

export default withRouter(SynergeLinkButtonComponent);
