import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import MainSearchContainer from '../../container/user/MainSearchContainer';
import palette from '../../lib/style/palette';

const HeaderBlock = styled.header`
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    background-color: ${palette.GRAY03};
    height: 3rem;

    .header-item {
        margin-right: 1rem;
        color: white;
        font-size: 0.8rem;
    }

    .left-header {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
`;

const RightHeaderBlock = styled.div`
    // margin-bottom: 0.5rem;
    padding-top: 0.5rem;
    position: absolute;
    right: 0;
`;

const LeftHeaderListUl = styled.ul`
    display: flex;
    margin-left: 2rem;

    .selected {
        color: #61dafb;
    }
`;

const StyledLink = styled(NavLink)`
    font-family: GT America Trial;
    font-style: normal;
    font-weight: 900;
    font-size: 13.3131px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
`;

const Header: React.FC = () => {
    return (
        <HeaderBlock>
            <LeftHeaderListUl className="left-header">
                <li className="header-item">
                    <StyledLink to="/" activeClassName="selected" exact>
                        LOLCHEPASS
                    </StyledLink>
                </li>
                <li className="header-item">
                    <StyledLink to="/recommendmeta" activeClassName="selected">
                        추천메타
                    </StyledLink>
                </li>
                <li className="header-item">
                    <StyledLink to="/championbuilder" activeClassName="selected">
                        빌더
                    </StyledLink>
                </li>
                <li className="header-item">
                    <StyledLink to="/item/item" activeClassName="selected">
                        아이템
                    </StyledLink>
                </li>
                <li className="header-item">
                    <StyledLink to="/synerge" activeClassName="selected">
                        시너지
                    </StyledLink>
                </li>
                <li className="header-item">
                    <StyledLink to="/user" activeClassName="selected" exact>
                        순위
                    </StyledLink>
                </li>
            </LeftHeaderListUl>
            <RightHeaderBlock>
                <MainSearchContainer />
            </RightHeaderBlock>
        </HeaderBlock>
    );
};

export default Header;
