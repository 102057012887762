import styled from 'styled-components';
import palette from '../../lib/style/palette';

const UserInfoPageLayout = styled.div`
    position: relative;
    top: 5%;
    left: 20%;
    // background-color: ${palette.GRAY01};
    width: 32.4rem;
    min-height: 45rem;
`;

export default UserInfoPageLayout;
