import { createAction, createReducer } from '../lib/reduxHelper';
import { Observable } from 'rxjs';
import { RootState } from './rootReducer';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { fetchChatListObservable, postChatObservable } from '../lib/api/firebase';
import { timeForToday } from '../lib/util/time';

const makeChat = (chat: Chat, localuserid?: string) => {
    const userdiffTodayTime = timeForToday(chat.millTime!);
    const chatposition = localuserid === chat.userId ? 'right' : 'left';
    return { ...chat, userdiffTodayTime, chatposition, ismine: localuserid === chat.userId, hasProfile: true } as Chat;
};

export interface Chat {
    userId: string;
    userImg: string;
    userMessage: string;
    userName: string;
    userTime?: any;
    userdiffTodayTime?: string;
    millTime?: number;
    ismine?: boolean;
    chatposition?: 'left' | 'right';
    hasProfile?: boolean;
}

export interface ChatPageState {
    isopen: boolean;
    chatList: Chat[];
}

export enum ActionType {
    FETCHCHATLIST = 'chat/fetchChatList',
    SETCHATLIST = 'chat/setChatList',
    POSTCHATLIST = 'chat/postChatList',
    ISOPENCHAT = 'chat/isopenchat',
    PUSHCHAT = 'chat/pushchat',
    NOOP = 'chat/NOOP',
}

export const chatActions = {
    fetchChatList: (page: number) => createAction(ActionType.FETCHCHATLIST, page),
    setChatList: (list: Chat[]) => createAction(ActionType.SETCHATLIST, list),
    postChat: (message: string) => createAction(ActionType.POSTCHATLIST, message),
    isopenChat: () => createAction(ActionType.ISOPENCHAT),
    pushchat: (chatObj: { chat: Chat[]; currentUser: string }) => createAction(ActionType.PUSHCHAT, chatObj),
    noop: () => createAction(ActionType.NOOP),
};

type Action = ReturnType<typeof chatActions[keyof typeof chatActions]>;

const intialState: ChatPageState = {
    isopen: false,
    chatList: [],
};

const fetchChatList = (
    action$: Observable<ReturnType<typeof chatActions['fetchChatList']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.FETCHCHATLIST),
        switchMap((action) => {
            return fetchChatListObservable(action.payload);
        }),
        map((data) => {
            const localuserid = state.value.login.loginUser.userId;
            const updatedata = data.map((chat) => {
                // const userdiffTodayTime = timeForToday(chat.userTime.toMillis());
                // const chatposition = localuserid === chat.userId ? 'right' : 'left';
                // return { ...chat, userdiffTodayTime, chatposition } as Chat;
                return makeChat(chat, localuserid);
            });
            return chatActions.setChatList(updatedata);
        }),
    );

const postChat = (action$: Observable<ReturnType<typeof chatActions['postChat']>>, state: StateObservable<RootState>) =>
    action$.pipe(
        ofType(ActionType.POSTCHATLIST),
        switchMap((action) => {
            const user = state.value.login.loginUser;
            const chatData = {
                userId: user.userId,
                userImg: user.userImg,
                userMessage: action.payload,
                userName: user.userName,
            } as Chat;
            return postChatObservable(chatData);
        }),
        map((a) => {
            return chatActions.noop();
        }),
        // tap((a) => console.log(a, 'postchat')),
    );

const reducer = createReducer<ChatPageState, Action>(intialState, {
    [ActionType.FETCHCHATLIST]: (state, action) => {
        return state;
    },
    [ActionType.SETCHATLIST]: (state, action) => {
        // const userdiffTodayTime = timeForToday(data.userTime);
        state.chatList = action.payload;
        return state;
    },
    [ActionType.POSTCHATLIST]: (state, action) => {
        return state;
    },
    [ActionType.ISOPENCHAT]: (state, action) => {
        state.isopen = !state.isopen;
        return state;
    },
    [ActionType.PUSHCHAT]: (state, action) => {
        const chatList = action.payload.chat;
        const makeChatlist = chatList.map((chat, index) => {
            const returnChat = makeChat(chat, action.payload.currentUser);
            if (index > 0) {
                const beforeChat = chatList[index - 1];
                const hasProfile = !(chat.userId === beforeChat.userId && chat.userTime === beforeChat.userTime);
                return { ...returnChat, hasProfile };
            }
            return returnChat;
        });
        state.chatList = makeChatlist;
        // state.chatList.push(...makeChatlist);
        return state;
    },
    [ActionType.NOOP]: (state, action) => {
        return state;
    },
});
export const chatEpics = combineEpics(fetchChatList, postChat);
export default reducer;
