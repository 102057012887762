import React from 'react';
import styled from 'styled-components';
import { WithDefaultProps } from './Hoc';
export interface DescriptionBoxProps {
    title: string;
    infomation: string;
}

const DescriptionBoxBlock = styled.div``;

const DescriptionTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #ffffff;
    margint-bottom: 0.2rem;
`;

const DescriptionInfo = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1.2rem;
    letter-spacing: -0.4px;
    color: #ffffff;
    opacity: 0.7;
`;

const DescriptionBox: React.FC<DescriptionBoxProps> = ({ title, infomation, ...props }) => {
    return (
        <DescriptionBoxBlock {...props}>
            <DescriptionTitle>{title}</DescriptionTitle>
            <DescriptionInfo className="infomation">{infomation}</DescriptionInfo>
        </DescriptionBoxBlock>
    );
};

export default WithDefaultProps(DescriptionBox);
