import React from 'react';
import styled from 'styled-components';
import Spinner from '../../lib/style/tail-spin.svg';

const LoadingBlock = styled.div``;

const Loading = () => {
    return (
        <LoadingBlock>
            <img src={Spinner} alt="" />
        </LoadingBlock>
    );
};

export default Loading;
