import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import ChampionBuildPage from './ChampionBuildPage';

function ChampionRoutePage({ match }: RouteComponentProps) {
    return (
        <>
            <Route path={`${match.path}`} component={ChampionBuildPage} />
        </>
    );
}

export default ChampionRoutePage;
