import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import { CommonChampionTooltip, CommonItemTooltip } from '../../component/common/Tooltip';
import { Champion } from '../../lib/api/lolcheserver/InitdataApi';
import { TooltipState, TooltipKinds } from '../../module/tooltip';

const getTooltipComponent = (tooltip: TooltipState): Record<TooltipKinds, JSX.Element> => {
    return {
        recommendchampion: (
            <CommonChampionTooltip
                TooltipObj={tooltip.tooltipInfo.tooltipObject as Champion}
                position={tooltip.tooltipInfo.position}
            />
        ),
        commonchampion: <></>,
        none: <></>,
        item: (
            <CommonItemTooltip
                TooltipObj={tooltip.tooltipInfo.tooltipObject as Champion}
                position={tooltip.tooltipInfo.position}
            />
        ),
    };
};

const TooltipContainer = () => {
    const { tooltip } = useSelector((state: RootState) => state);
    return tooltip && tooltip.tooltipInfo.isshow ? (
        <>{getTooltipComponent(tooltip)[tooltip.tooltipInfo.type]}</>
    ) : (
        <></>
    );
};

export default TooltipContainer;
