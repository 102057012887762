import styled from 'styled-components';
import palette from '../../lib/style/palette';

const UserInfoErrorPage = styled.div`
    position: relative;
    // top: 50%;
    // left: 10%;
    // background-color: ${palette.GRAY01};
    // padding-top: 0.5rem;
`;

export default UserInfoErrorPage;
