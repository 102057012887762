import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NullAbleUser } from '../../module/user';
import { UserProfileImage } from '../common/Image';
import { getWinrate } from '../../lib/util/userUtil';
import BlueStar from '../../lib/style/MainIcon/BlueStar.svg';
import Star from '../../lib/style/MainIcon/Star.svg';
import palette, { tierTypeColor } from '../../lib/style/palette';
import { getLikeUserList, addLikeUserList, cancleLikeUser } from '../../lib/api/localstorage';
import Tier from '../../lib/style/tier';
import { defaultToZero } from '../../lib/util';
import * as R from 'ramda';

interface UserInfoComponentProps {
    user: NullAbleUser;
}

const UserInfoBlock = styled.div``;

const UserInfoTitleBlock = styled.div``;

const UserInfoTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-bottom: 1.2rem;
`;

const UserInfoComponentBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 670px;
    /* background: ${palette.GRAY04}; */
`;

const UsernameInfoBlock = styled.div`
    // padding: 1.6rem 15.85rem 1.6rem 0.1rem;
    background: ${palette.GRAY04};
    padding-top: 1.6rem;
    padding-left: 2rem;
`;

const UserNameInfoUl = styled.ul`
    display: flex;
    padding-bottom: 1rem;
    border-radius: 8px;
    height: 8.4rem;
    margin-bottom: 0.8rem;
`;

const UserNameBlock = styled.div`
    margin-bottom: 0.5rem;
`;

const UserLevelBlock = styled.div`
    width: 60px;
    padding: 2px 8px 2px 8px;
    background: #343943;
    border-radius: 100px;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
`;

const UserLevel = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    opacity: 0.8;
    text-align: center;
`;

const UserInfo = styled.span`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #ffffff;
`;

const LikeUserStar = styled.img`
    cursor: pointer;
    margin-left: 0.5rem;
`;

const UserScoreInfoBlock = styled.div`
    padding: 2.4rem 3.6rem 2.4rem 3.6rem;
    background: ${palette.GRAY04};
    margin-top: 0.8rem;
`;

const UserScoreInfoUl = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 10rem);
    column-gap: 7.2rem;
    row-gap: 0.8rem;
`;

const UserScoreInfoli = styled.li`
    display: flex;
`;

const UserScoreTitle = styled.span`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    letter-spacing: -0.4px;
    color: #ffffff;
    opacity: 0.5;
`;

const UserScoreValue = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
    color: #ffffff;
    margin-left: auto;
`;

const UserProfileImageBlock = styled.li`
    margin: 1.2rem;
`;

const UserTierBlock = styled.div`
    display: flex;
`;

const UserTierImgBlock = styled.div`
    display: flex;
`;

const UserTierImg = styled.img`
    margin-right: 0.4rem;
`;
const UserTierTier = styled.span<{ tier: string }>`
    margin-right: 0.4rem;
    ${(props) => `color:${tierTypeColor[props.tier]}`};

    // color: white;
`;
const UserTierTierLev = styled.span<{ tier: string }>`
    margin-right: 0.4rem;
    color: white;
    ${(props) => `color:${tierTypeColor[props.tier]}`};
`;
const UserTierPoint = styled.span`
    color: white;
    opacity: 0.5;
`;

const UserLpText = styled.span`
    color: white;
    opacity: 0.5;
`;

const UserNameInfoComponent: React.FC<UserInfoComponentProps> = ({ user }) => {
    const [isLikeUser, setIsLikeUser] = useState(false);

    useEffect(() => {
        const likeUserList = getLikeUserList();
        if (user && likeUserList.map((a) => a.user_data.name).includes(user?.user_data.name)) {
            setIsLikeUser(true);
        } else {
            setIsLikeUser(false);
        }
    }, [user]);

    const clickStar = (e: React.MouseEvent<HTMLImageElement>) => {
        addLikeUserList(user!);
        setIsLikeUser(true);
    };

    const clickBlueStar = (e: React.MouseEvent<HTMLImageElement>) => {
        cancleLikeUser(user!);
        setIsLikeUser(false);
    };

    return user ? (
        <UsernameInfoBlock>
            <UserNameInfoUl className="user-name-info">
                <UserProfileImageBlock>
                    <UserProfileImage src={user.user_data.userProfile} />
                </UserProfileImageBlock>
                <li>
                    <UserLevelBlock>
                        {/* <UserLevel>Lv</UserLevel> */}
                        <UserLevel>{`LV ${user.user_data.summonerLevel}`}</UserLevel>
                    </UserLevelBlock>
                    <UserNameBlock>
                        <UserInfo>{user.user_data.name}</UserInfo>
                        {isLikeUser ? (
                            <LikeUserStar src={BlueStar} alt="" onClick={clickBlueStar} />
                        ) : (
                            <LikeUserStar src={Star} alt="" onClick={clickStar} />
                        )}
                    </UserNameBlock>
                    <UserTierBlock>
                        <UserTierImgBlock>
                            <UserTierImg src={Tier[user.score_data.tier]} alt="" />
                            <UserTierTier tier={user.score_data.tier}>{user.score_data.tier || 0}</UserTierTier>
                            <UserTierTierLev tier={user.score_data.tier}>{user.score_data.rank || 0}</UserTierTierLev>
                            <UserTierPoint>{`${user.score_data.leaguePoints || 0}`}</UserTierPoint>
                            <UserLpText>Lp</UserLpText>
                        </UserTierImgBlock>
                        {/* <UserInfo>{`${user.score_data.leaguePoints || 0} Lp`}</UserInfo> */}
                    </UserTierBlock>
                </li>
            </UserNameInfoUl>
        </UsernameInfoBlock>
    ) : (
        <></>
    );
};

const UserScoreInfoComponent: React.FC<Pick<UserInfoComponentProps, 'user'>> = ({ user }) => {
    const getWins = R.pipe(R.prop('wins'), defaultToZero);
    const getlosses = R.pipe(R.prop('losses'), defaultToZero);

    return user ? (
        <UserScoreInfoBlock>
            <UserScoreInfoUl>
                <UserScoreInfoli>
                    <UserScoreTitle>게임수</UserScoreTitle>
                    <UserScoreValue>{getWins(user.score_data) + getlosses(user.score_data)}</UserScoreValue>
                </UserScoreInfoli>
                <UserScoreInfoli>
                    <UserScoreTitle>승리</UserScoreTitle>
                    <UserScoreValue>{getWins(user.score_data)}</UserScoreValue>
                </UserScoreInfoli>
                <UserScoreInfoli>
                    <UserScoreTitle>승률</UserScoreTitle>
                    <UserScoreValue>{getWinrate(getWins(user.score_data), getlosses(user.score_data))}</UserScoreValue>
                </UserScoreInfoli>
                <UserScoreInfoli>
                    <UserScoreTitle>패배</UserScoreTitle>
                    <UserScoreValue>{getlosses(user.score_data)}</UserScoreValue>
                </UserScoreInfoli>
            </UserScoreInfoUl>
        </UserScoreInfoBlock>
    ) : (
        <></>
    );
};

const EtcInfoBlock = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12.6rem;
    background: ${palette.GRAY04};
`;

const EtcInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitleText = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0.5rem;
`;

const SubTitleText = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    opacity: 0.5;
    margin-bottom: 0.5rem;
`;

const ButtonBlock = styled.div`
    display: flex;
    justify-content: center;
`;

const MailButton = styled.button`
    width: 200px;
    height: 50px;
    background: #3d62e4;
    border-radius: 100px;
`;

const UserInfoComponent: React.FC<UserInfoComponentProps> = ({ user }) => {
    return (
        user && (
            <UserInfoBlock>
                <UserInfoTitleBlock>
                    <UserInfoTitle>검색 결과</UserInfoTitle>
                </UserInfoTitleBlock>
                <UserInfoComponentBlock>
                    {/* <UserInfoComponentHeader /> */}
                    <UserNameInfoComponent user={user} />
                    <UserScoreInfoComponent user={user} />
                    <EtcInfoBlock>
                        <EtcInfo>
                            <TitleText>더 많은 데이터가 궁금하신가요?</TitleText>
                            <SubTitleText>개발이 완료되면 메일로 알려드릴게요.</SubTitleText>
                            <ButtonBlock>
                                <MailButton>알림 메일 받기</MailButton>
                            </ButtonBlock>
                        </EtcInfo>
                    </EtcInfoBlock>
                </UserInfoComponentBlock>
            </UserInfoBlock>
        )
    );
};

export default UserInfoComponent;
