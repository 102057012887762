import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/style/palette';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CurationVideo } from '../../../lib/api/lolcheserver/InitdataApi';
import VideoSliderList from './VideoSliderList';

interface RecommendVideoProps {
    curation_video: CurationVideo;
    clickVideo: (id: number) => void;
}

const RecommendVideoBlock = styled.div`
    margin-top: 2rem;
`;

const MainTitle = styled.h2`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    /* or 92% */
    letter-spacing: -0.4px;
    color: #328ffd;
`;

const SubTitle = styled.h2`
    margin-top: 1.2rem;
    font-size: 1.6rem;
    line-height: 2.3rem;
    letter-spacing: -0.02rem;
    color: ${palette.WHITE01};
    margin-bottom: 2rem;
`;

const RecommendVideoListComponent: React.FC<RecommendVideoProps> = ({ curation_video, clickVideo }) => {
    return (
        <RecommendVideoBlock>
            <MainTitle>{curation_video.title}</MainTitle>
            <SubTitle>{curation_video.sub_title}</SubTitle>
            <VideoSliderList videoList={curation_video.youtube_videos} clickVideo={clickVideo} />
        </RecommendVideoBlock>
    );
};

export default RecommendVideoListComponent;
