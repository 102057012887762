import React from 'react';
import styled from 'styled-components';
import YouTubeComponent from '../main/todaymeta/YouTubeComponent';
import { YoutubeVideo } from '../../lib/api/lolcheserver/InitdataApi';
import { timeForToday } from '../../lib/util/time';

const VideoDetailComponentBlock = styled.div``;

const VideoTitle = styled.p`
    margin-top: 0.6rem;
    margin-bottom: 0.2rem;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #ffffff;
`;

const VideoChannerInfo = styled.ul`
    display: flex;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #707888;
`;

const VideoChannerInfoLi = styled.li`
    margin-right: 0.5rem;
`;

const VideoDetailComponent: React.FC<{ videoDetail: YoutubeVideo }> = ({ videoDetail }) => {
    const videosize = {
        width: '1200',
        height: '625',
    };

    return (
        <VideoDetailComponentBlock>
            <YouTubeComponent id={videoDetail.video_id} size={videosize} />
            <VideoTitle>{videoDetail.title}</VideoTitle>
            <VideoChannerInfo>
                <VideoChannerInfoLi>{videoDetail.channel}</VideoChannerInfoLi>
                <VideoChannerInfoLi>조회수 {videoDetail.view_cnt}회</VideoChannerInfoLi>
                <VideoChannerInfoLi>{timeForToday(videoDetail.published_at)}</VideoChannerInfoLi>
            </VideoChannerInfo>
        </VideoDetailComponentBlock>
    );
};

export default VideoDetailComponent;
