import React from 'react';
import arrow from '../../../lib/style/MainIcon/ic_chevron_right.png';
import styled from 'styled-components';

const SliderButtonBlock = styled.div`
    display: 'block';
    background: '#313843';
    width: '48px';
    height: '48px';
    border-radius: '100px';
    &:before {
        display: none;
    }
    // position: relative;
    // right: -30px;

    z-index: 10;
`;

const SliderButtonImgBlock = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: #313843;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -23px;
`;

const Arrow = styled.img<{ isright: boolean }>`
    width: 1.2rem;
    height: 1.2rem;
    transform: ${(props) => (props.isright ? 'rotate(-180deg)' : '')};
`;

export function SampleNextArrow(props: any) {
    const { className, onClick } = props;
    return (
        <SliderButtonBlock className={className} onClick={onClick}>
            <SliderButtonImgBlock>
                <Arrow src={arrow} alt="" isright={false} />
            </SliderButtonImgBlock>
        </SliderButtonBlock>
    );
}

export function SamplePrevArrow(props: any) {
    const { className, onClick } = props;
    return (
        <SliderButtonBlock className={className} onClick={onClick}>
            <SliderButtonImgBlock>
                <Arrow src={arrow} alt="" isright={true} />
            </SliderButtonImgBlock>
        </SliderButtonBlock>
    );
}
