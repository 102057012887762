import React from 'react';
import ChampionBuilderLinkButtonComponent from '../../component/championbuilder/ChampionBuilderLinkButtonComponent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import { ChampionSortType, championbuilderActions } from '../../module/championbuilder';

const ChampionSelectorTapButtonContainer = () => {
    const {
        championbuilder: { championSortCondition },
    } = useSelector((root: RootState) => root);
    const dispatch = useDispatch();

    const clickSortConditionButton = (condition: ChampionSortType) => {
        dispatch(championbuilderActions.setChampionSortCondition(condition));
    };

    return (
        <ChampionBuilderLinkButtonComponent
            championSortCondition={championSortCondition}
            clickSortConditionButton={clickSortConditionButton}
        />
    );
};

export default ChampionSelectorTapButtonContainer;
