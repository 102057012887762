import styled from 'styled-components';
import palette from '../../lib/style/palette';

const ChampionBuildPageLayout = styled.div`
    display: flex;
    min-height: 45rem;
    flex-direction: column;
    justify-content: center;
    // background-color: ${palette.GRAY01};
    position: relative;
    left: 10%;
`;

export default ChampionBuildPageLayout;
