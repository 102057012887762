import React from 'react';
import { RouteComponentProps, Route, withRouter, Switch } from 'react-router-dom';
import ClassSynergeContainer from './class/ClassSynergeContainer';
import OriginSynergeContainer from './origin/OriginSynergeContainer';

const ChampionSelectorContainer = ({ match }: RouteComponentProps) => {
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={ClassSynergeContainer} />
            <Route exact path={`${match.url}/origin`} component={OriginSynergeContainer} />
        </Switch>
    );
};

export default withRouter(ChampionSelectorContainer);
