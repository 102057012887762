import React from 'react';
import styled from 'styled-components';
import { Champion } from '../../lib/api/lolcheserver/InitdataApi';
import { CellBoxClickPayload } from './ChampionCellBox';
import ChampionCellBoxLite from './ChampionCellBoxLite';
import palette from '../../lib/style/palette';

interface ChampionNomalListComponentProps {
    championList: Champion[];
    click: (id: CellBoxClickPayload) => void;
}

const ChampionListBlock = styled.div`
    width: 15.8rem;
    height: 8rem;
    border: 1.00816px solid rgba(255, 255, 255, 0.02);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: ${palette.GRAY03};
`;

const ChampionGroupCard = styled.ul`
    display: grid;
    grid-template-columns: repeat(4, 3rem);
    gap: 0.8rem;
    row-gap: 0.6rem;
    padding: 0.7rem;
`;

const ChampionGroupCardList = ({ championList, click }: ChampionNomalListComponentProps) => (
    <ChampionListBlock>
        <ChampionGroupCard>
            {/* <ChampionCellBox champion={champion} key={champion.id} click={click} /> */}
            {championList.map((champion) => (
                <li key={champion.id}>
                    <ChampionCellBoxLite champion={champion} click={click} />
                </li>
            ))}
        </ChampionGroupCard>
    </ChampionListBlock>
);

export default ChampionGroupCardList;
