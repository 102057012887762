import { Login } from '../../../module/login';
import { Champion } from '../lolcheserver/InitdataApi';
import { UserAPIData } from '../lolcheserver/user';
import * as R from 'ramda';

function guid() {
    function s4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getUser = (championList: Champion[]): Login => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user) as Login;
    }

    const championindex = getRandomIntInclusive(0, championList.length - 1);
    const champion = championList[championindex];
    const newUser = {
        userId: guid(),
        // userImg: championList[championindex].image,
        userImg: R.prop('image', champion),
        userName: R.prop('name', champion) + String(getRandomIntInclusive(0, 99999)),
        // userName: championList[championindex].name + String(getRandomIntInclusive(0, 99999)),
    };
    localStorage.setItem('user', JSON.stringify(newUser));

    return newUser;
};

export const getLikeUserList = () => {
    const userList = localStorage.getItem('likeUserList');
    if (userList) {
        return JSON.parse(userList) as UserAPIData[];
    }

    return [] as UserAPIData[];
};

export const addLikeUserList = (userdata: UserAPIData) => {
    const userList = getLikeUserList();
    userList.unshift(userdata);
    localStorage.setItem('likeUserList', JSON.stringify(userList));
};

export const cancleLikeUser = (userdata: UserAPIData) => {
    const userList = getLikeUserList().filter((user) => user.user_data.id !== userdata.user_data.id);
    localStorage.setItem('likeUserList', JSON.stringify(userList));
};

export const getlatestSearchUserList = () => {
    const userList = localStorage.getItem('latestUserList');
    if (userList) {
        return JSON.parse(userList) as UserAPIData[];
    }
    return [] as UserAPIData[];
};

export const addLatestUserList = (userdata: UserAPIData) => {
    const userList = getlatestSearchUserList();
    userList.unshift(userdata);
    localStorage.setItem('latestUserList', JSON.stringify(R.uniqBy((a: UserAPIData) => a.user_data.id, userList)));
};

export const cancleLatestUser = (userdata: UserAPIData) => {
    const userList = getlatestSearchUserList().filter((user) => user.user_data.id !== userdata.user_data.id);
    localStorage.setItem('latestUserList', JSON.stringify(userList));
};

export const getChatStorage = () => {
    const hostName = window.location.host;
    const chatStorage = localStorage.getItem(hostName) as string;
    return R.defaultTo(
        {
            chatList: [],
            timestamp: 0,
        },
        JSON.parse(chatStorage),
    );
};

export const getLikeMetaList = () => {
    const likeMetaList = localStorage.getItem('likeMetaList');
    if (likeMetaList) {
        return JSON.parse(likeMetaList);
    }
    return [];
};
