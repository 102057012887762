import { createAction, createReducer } from '../lib/reduxHelper';

export interface GamePageState {
    enemy: number[][];
    me: number[][];
    currentmePosition: {
        y: number;
        x: number;
    };
    tonadoPostion: {
        y: number;
        x: number;
    };
}

export enum ActionType {
    SETDROP = 'game/setdrop',
}

export const gameActions = {
    // setuser: (user: Login) => createAction(ActionType.SETUSER, user),
    setdrop: (obj: { x: number; y: number }) => createAction(ActionType.SETDROP, obj),
};

type Action = ReturnType<typeof gameActions[keyof typeof gameActions]>;

const intialState: GamePageState = {
    enemy: [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
    ],
    me: [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [1, 0, 0, 0, 0, 0, 0],
    ],
    currentmePosition: {
        y: 3,
        x: 0,
    },
    tonadoPostion: {
        y: 0,
        x: 6,
    },
};

const reducer = createReducer<GamePageState, Action>(intialState, {
    [ActionType.SETDROP]: (state, action) => {
        const obj = action.payload;
        const currentme = state.currentmePosition;
        const tonadoPostion = { y: 3 - obj.y, x: 6 - obj.x };
        state.currentmePosition = obj;
        state.me[currentme['y']][currentme['x']] = 0;
        state.me[obj['y']][obj['x']] = 1;
        state.tonadoPostion = tonadoPostion;
        return state;
    },
});

export default reducer;
