import React, { useEffect } from 'react';
import RecommendMetaPageLayout from '../../component/layout/RecommendMetaPageLayout';
import RecommendMetaContainer from '../../container/recommend/RecommendMetaContainer';
import Meta from '../../component/common/Meta';
import ReactGa from 'react-ga';

function RecommendMetaPage() {
    const metaData = {
        title: '추천메타',
        description: '추천 영상, 추천 메타, 추천메타 검색, 챔피언 검색, 챔피언 추천메타 검색, 추천 유튜브',
    };
    useEffect(() => {
        const pathName = window.location.pathname;
        ReactGa.initialize('G-49LQN3JGJ7');
        ReactGa.set({ page: pathName });
        ReactGa.pageview(pathName);
    });

    return (
        <RecommendMetaPageLayout className="recommend-page-layout">
            <Meta data={metaData} />
            <RecommendMetaContainer />
        </RecommendMetaPageLayout>
    );
}

export default RecommendMetaPage;
