import React, { useEffect } from 'react';
import NoticePageLayout from '../../component/layout/NoticePageLayout';
import NoticeContainer from '../../container/notice/NoticeContainer';
import ReactGa from 'react-ga';

function NoticePage() {
    useEffect(() => {
        const pathName = window.location.pathname;
        ReactGa.initialize('G-49LQN3JGJ7');
        ReactGa.set({ page: pathName });
        ReactGa.pageview(pathName);
    });

    return (
        <NoticePageLayout>
            <NoticeContainer />
        </NoticePageLayout>
    );
}

export default NoticePage;
