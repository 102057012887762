import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/style/palette';

const PlusButtonBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
`;

const CirCle = styled.div`
    border: 1px solid ${palette.WHITE01};
    box-sizing: border-box;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 100px;
    position: relative;
`;

const CrossLine = styled.div`
    border: 1px solid ${palette.WHITE01};
    width: 33%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 33%;
`;

const LongitudinalLine = styled.div`
    border: 1px solid ${palette.WHITE01};
    width: 2px;
    height: 33%;
    position: absolute;
    top: 33%;
    left: 50%;
`;

const PlusButtonComponent: React.FC<{ click: any }> = ({ click }) => {
    // const history = useHistory();
    // const clickButton = () => {
    //     history.push('/recommendmeta');
    // };
    return (
        <PlusButtonBlock onClick={click}>
            <CirCle>
                <CrossLine></CrossLine>
                <LongitudinalLine></LongitudinalLine>
            </CirCle>
        </PlusButtonBlock>
    );
};

export default PlusButtonComponent;
