import * as Hangul from 'hangul-js';
import { ofType, StateObservable, combineEpics } from 'redux-observable';
import { iif, Observable, of } from 'rxjs';
import { switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Champion } from '../lib/api/lolcheserver/InitdataApi';
import { createAction, createReducer } from '../lib/reduxHelper';
import { _islength } from '../lib/util';
import { RootState } from './rootReducer';
import { recommendActions } from './recommendmeta';
import { tooltipActions } from './tooltip';
import * as R from 'ramda';

export interface ChamPionPageState {
    totalChampionList: Champion[];
    indexedChampionMap: {
        [key: string]: Champion;
    };
    searchChampionList: Champion[];
}

export enum ActionType {
    INITCHAMPIONLIST = 'champion/initchampionlist',
    SETCHAMPIONLIST = 'champion/setchampionlist',
    SEARCHCHAMPIONLISTDEBOUNCE = 'champion/searchchampionlistdebounce',
    SETSEARCHCHAMPIONLIST = 'champion/setsearchchampionlist',
}

export const championActions = {
    setInitChamPionList: (championList: Champion[]) => createAction(ActionType.INITCHAMPIONLIST, championList),
    setChamPionList: (championList: Champion[]) => createAction(ActionType.SETCHAMPIONLIST, championList),
    setsearchChamPionList: (championList: Champion[]) => createAction(ActionType.SETSEARCHCHAMPIONLIST, championList),
    searchChamPionListdebounce: (query: string) => createAction(ActionType.SEARCHCHAMPIONLISTDEBOUNCE, query),
};

type Action = ReturnType<typeof championActions[keyof typeof championActions]>;

const intialState: ChamPionPageState = {
    totalChampionList: [],
    indexedChampionMap: {},
    searchChampionList: [],
};

const alpaReg = /^[a-zA-Z]+$/;

const EngToHan = {
    q: 'ㅂ',
    w: 'ㅈ',
    e: 'ㄷ',
    r: 'ㄱ',
    t: 'ㅅ',
    y: 'ㅛ',
    u: 'ㅕ',
    i: 'ㅑ',
    o: 'ㅐ',
    p: 'ㅔ',
    a: 'ㅁ',
    s: 'ㄴ',
    d: 'ㅇ',
    f: 'ㄹ',
    g: 'ㅎ',
    h: 'ㅗ',
    j: 'ㅓ',
    k: 'ㅏ',
    l: 'ㅣ',
    z: 'ㅋ',
    x: 'ㅌ',
    c: 'ㅊ',
    v: 'ㅍ',
    b: 'ㅠ',
    n: 'ㅜ',
    m: 'ㅡ',
} as any;

const querytoHan = (query: string) => {
    return alpaReg.test(query) ? Hangul.assemble(query.split('').map((a) => EngToHan[a])) : query;
};

const filterdChampionList = (championList: Champion[], query: string) => {
    return championList.filter((champion) => Hangul.search(champion.name, querytoHan(query)) === 0);
};

const searchChampionEpic = (
    action$: Observable<ReturnType<typeof championActions['searchChamPionListdebounce']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.SEARCHCHAMPIONLISTDEBOUNCE),
        debounceTime(100),
        distinctUntilChanged((a, b) => a.payload === b.payload),
        switchMap((action) =>
            iif(
                () => _islength(action.payload),
                of(
                    championActions.setsearchChamPionList(
                        filterdChampionList(state.value.champion.totalChampionList, action.payload),
                    ),
                    recommendActions.setSearchRecommendList(
                        state.value.recommendmeta.totalRecommendList.filter((recommend) => {
                            const championids = filterdChampionList(
                                state.value.champion.totalChampionList,
                                action.payload,
                            ).map((a) => a.id);
                            return recommend.champions.some((ch) => championids.includes(ch.champion.id));
                        }),
                    ),
                    tooltipActions.closetooltip(),
                ),
                of(
                    championActions.setsearchChamPionList([]),
                    recommendActions.setSearchRecommendList(state.value.recommendmeta.totalRecommendList),
                ),
            ),
        ),
    );

const reducer = createReducer<ChamPionPageState, Action>(intialState, {
    [ActionType.INITCHAMPIONLIST]: (state, action) => {
        const chamPionList = action.payload.map((a) => Object.assign(a, { isSelected: false }));
        state.totalChampionList = chamPionList;
        state.indexedChampionMap = R.indexBy((champion: Champion) => champion.id, chamPionList);
    },
    [ActionType.SETCHAMPIONLIST]: (state, action) => {
        state.totalChampionList = action.payload;
        state.indexedChampionMap = R.indexBy((champion: Champion) => champion.id, action.payload);
    },
    [ActionType.SETSEARCHCHAMPIONLIST]: (state, action) => {
        const chamPionList = action.payload;
        state.searchChampionList = chamPionList;
    },
    [ActionType.SEARCHCHAMPIONLISTDEBOUNCE]: (state, action) => {
        // state = state;
    },
});

export const championEpics = combineEpics(searchChampionEpic);

export default reducer;
