enum palette {
    GRAY00 = '#181a1f',
    GRAY01 = '#1A1C21',
    GRAY02 = '#1c1e23',
    GRAY03 = '#1e2127',
    GRAY04 = '#202329',
    GRAY05 = '#23262d',
    GRAY06 = '#24272e',
    GRAY08 = '#282c34',
    GRAY10 = '#2c3038',
    COST1 = '#9aa7B9',
    COST2 = '#00cc97',
    COST3 = '#328ffd',
    COST4 = '#af2dff',
    COST5 = '#e7ae1c',
    GOLD100 = '#eac400',
    SILVER100 = '#a9bbd0',
    BRONZE100 = '#936a3a',
    GOLD30 = '#eac400',
    SILVER30 = '#a9bbd0',
    BRONZE30 = '#9e6a3a',
    WHITE01 = '#FFFFFF',
    WHITE02 = '#E8E9E9',
    WHITE03 = '#CFD3D7',
    WHITE04 = '#B6BDC4',
    WHITE05 = '#9FA5B0',
    WHITE06 = '#878F9C',
    WHITE07 = '#707888',
    WHITE08 = '#5B6370',
    WHITE09 = '#474E5A',
    WHITE10 = '#313843',
}

export const synergeTierColor = {
    0: palette.GRAY08,
    1: palette.BRONZE100,
    2: palette.SILVER100,
    3: 'rgba(255, 214, 0, 0.49)',
    4: '',
    10: '#3D6CE4',
} as Record<number, string>;

export const costTierColor = {
    1: palette.COST1,
    2: palette.COST2,
    3: palette.COST3,
    4: palette.COST4,
    5: palette.COST5,
} as Record<number, string>;

export const noticeTypeColor = {
    PATCH: '#5DA9D5',
    UPDATE: '#A291D5',
    EVENT: '#D3C15C',
    NOTICE: '#40CBC3',
};

export const tierTypeColor = {
    CHALLENGER: '#F4CA7B',
    GRANDMASTER: '#665C6E',
    MASTER: '#A4594B',
    DIAMOND: '#415BDD',
    PLATINUM: '#4BC4C6',
    GOLD: '#FB992F',
    SILVER: '#8EA4AA',
    BRONZE: '#A9644D',
    IRON: '#8F798C',
} as any;

export default palette;
