import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import RecommendMetaPage from './RecommendMetaPage';
import RecommendDetailPage from './RecommendDetailPage';

function Page({ match }: RouteComponentProps) {
    return (
        <>
            <Route path={`${match.path}`} exact component={RecommendMetaPage} />
            <Route path={`${match.path}/:id`} exact component={RecommendDetailPage} />
        </>
    );
}

export default Page;
