import apiInstance from './maininstance';
import { YoutubeVideo } from './InitdataApi';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';

export interface VideoDetail {
    curation_video: YoutubeVideo;
    extra_videos: YoutubeVideo[];
    related_videos: YoutubeVideo[];
}

export function fetchVideoDetail(id: number) {
    return from(apiInstance.get(`/video/${id}`)).pipe(pluck('data'), pluck('data'));
}

export function postLike(id: number) {
    return from(apiInstance.patch(`/meta/like/${id}`));
}
