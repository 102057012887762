import { Champion, SynergeType, SynergeTier } from '../lib/api/lolcheserver/InitdataApi';
import { createAction, createReducer } from '../lib/reduxHelper';
import { _updateList, _isNotEmpty, makeGetter } from '../lib/util';
import { getSynergeStaticList } from '../lib/util/synergeUtil';
import { Observable, of } from 'rxjs';
import { StateObservable, ofType, combineEpics } from 'redux-observable';
import { RootState } from './rootReducer';
import { mergeMap, filter } from 'rxjs/operators';
import { championActions } from './champion';
import { parsedSynergeChampion } from '../lib/util/championUtil';
import * as R from 'ramda';

export type ChampionSortType = 'name' | 'cost';

export type EnforcedSynerge = {
    synerge: SynergeType;
    count: number;
    synergetier: SynergeTier;
};

export type BuilderType = {
    chamList: Champion[];
    synergestaticList: Array<EnforcedSynerge>;
};
export interface ChampionBuilderPageProps {
    builder: BuilderType;
    championSortCondition: ChampionSortType;
    //    selectableChampionList: Array<Champion>;
}

export enum ActionType {
    //    INITSELECTABLECHAMPIONLIST = 'championbuilder/initselectablechampionlist',
    CLICKCHAMPIONSELECTOR = 'championbuilder/clickchampionselector',
    CLICKCHAMPIONBUILDER = 'championbuilder/clickchampionbuilder',
    ADDCHAMPIONBUILDER = 'championbuilder/addchampionbuilder',
    REMOVECHAMPIONBUILDER = 'championbuilder/removechampionbuilder',
    SETCHAMPIONSORTCONDITION = 'championbuilder/setChampionSortCondition',
}

export const championbuilderActions = {
    clickChampionSelector: (payload: { id: number; isSelected: boolean }) =>
        createAction(ActionType.CLICKCHAMPIONSELECTOR, payload),
    addChampionBuilder: (champion: Champion) => createAction(ActionType.ADDCHAMPIONBUILDER, champion),
    clickChampionBuilder: (championId: number) => createAction(ActionType.CLICKCHAMPIONBUILDER, championId),
    removeChampionBuilder: (championId: number) => createAction(ActionType.REMOVECHAMPIONBUILDER, championId),
    setChampionSortCondition: (condition: ChampionSortType) =>
        createAction(ActionType.SETCHAMPIONSORTCONDITION, condition),
};

type Action = ReturnType<typeof championbuilderActions[keyof typeof championbuilderActions]>;

const intialState: ChampionBuilderPageProps = {
    builder: {
        chamList: R.range(0, 10).map((a) => ({})) as Champion[],
        synergestaticList: [],
    },
    championSortCondition: 'name',
};
const clickChampionSelectorEpic = (
    action$: Observable<ReturnType<typeof championbuilderActions['clickChampionSelector']>>,
    state: StateObservable<RootState>,
) => {
    return action$.pipe(
        ofType(ActionType.CLICKCHAMPIONSELECTOR),
        filter((action) => {
            const chamList = state.value.championbuilder.builder.chamList;
            //클릭시 추가된 챔피언이 10 보다 작거나 이미 추가된 챔피언일 경우에만 실행
            return (
                chamList.filter((a) => _isNotEmpty(a)).length < 10 ||
                chamList.find((a) => a.id === action.payload.id) !== void 0
            );
        }),
        mergeMap((action) => {
            const findchampionkey = R.propEq('id', action.payload.id);
            const championList = _updateList(
                findchampionkey,
                { isSelected: !action.payload.isSelected },
                state.value.champion.totalChampionList,
            );
            const findcham = championList.find(findchampionkey);
            const synergeparseCham = parsedSynergeChampion(makeGetter(state.value.synerge.indexedSynergeMap));

            //토글시 액션 처리를 위한 이미 선택된애를 다시 선택하면 빼야함
            const finalAction = action.payload.isSelected
                ? championbuilderActions.removeChampionBuilder(action.payload.id)
                : championbuilderActions.addChampionBuilder(synergeparseCham(findcham));
            return of(championActions.setChamPionList(championList), finalAction);
        }),
    );
};

const clickChampionBuilderEpic = (
    action$: Observable<ReturnType<typeof championbuilderActions['clickChampionBuilder']>>,
    state: StateObservable<RootState>,
) => {
    return action$.pipe(
        ofType(ActionType.CLICKCHAMPIONBUILDER),
        mergeMap((action) => {
            const findchampionkey = R.propEq('id', action.payload);
            const championList = _updateList(
                findchampionkey,
                { isSelected: false },
                state.value.champion.totalChampionList,
            );
            return of(
                championActions.setChamPionList(championList),
                championbuilderActions.removeChampionBuilder(action.payload),
            );
        }),
    );
};

const reducer = createReducer<ChampionBuilderPageProps, Action>(intialState, {
    [ActionType.CLICKCHAMPIONSELECTOR]: (state, action) => {
        // state = state;
    },
    [ActionType.ADDCHAMPIONBUILDER]: (state, action) => {
        state.builder.chamList.some((a, i) => {
            if (R.isEmpty(a)) {
                state.builder.chamList[i] = action.payload;
            }
            return R.isEmpty(a);
        });
        const newList = state.builder.chamList;
        state.builder.synergestaticList = getSynergeStaticList(newList);
    },
    [ActionType.CLICKCHAMPIONBUILDER]: (state, action) => {
        // state = state;
    },
    [ActionType.REMOVECHAMPIONBUILDER]: (state, action) => {
        const chamid = action.payload;
        const newList = state.builder.chamList.filter((a) => a.id !== chamid).concat({} as Champion);
        state.builder = {
            chamList: newList,
            synergestaticList: getSynergeStaticList(newList),
        };
    },
    [ActionType.SETCHAMPIONSORTCONDITION]: (state, action) => {
        state.championSortCondition = action.payload;
    },
});

export const championbuilderEpics = combineEpics(clickChampionSelectorEpic, clickChampionBuilderEpic);
export default reducer;
