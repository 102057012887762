import React from 'react';
import styled from 'styled-components';
import { RecommendMeta, EnforcedChampion, Item } from '../../lib/api/lolcheserver/InitdataApi';
import { synergeTierColor } from '../../lib/style/palette';
import { getPropsDefaultToEmptyStr } from '../../lib/util';

const RecommendMetaDetailSynergeListBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    background: #202329;
    position: relative;
    top: -5rem;
`;

const SynergeBlock = styled.div``;
const SynergeTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1.1rem;
    letter-spacing: -0.02rem;
    color: #e8e9e9;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
`;
const SynergeList = styled.ul`
    display: flex;
    width: 12.25rem;
    flex-wrap: wrap;
`;

const SynergeItemBlock = styled.li<{ tier: number }>`
    padding: 4px 10px 4px 10px;
    border-radius: 100px;
    background: ${({ tier }) => synergeTierColor[tier]};
    box-sizing: border-box;
    margin-right: 0.3rem;
    margin-bottom: 0.5rem;
`;

const SynergeItem = styled.div`
    display: flex;
    justify-content: center;
`;

const SynergeImg = styled.img`
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 0.2rem;
`;

const SynergeName = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.4px;
    color: #ffffff;
`;

const HasChampionTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1.1rem;
    letter-spacing: -0.02rem;
    color: #e8e9e9;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
`;

const HasItemChampionList = styled.ul`
    padding: 0.5rem 0.35rem 0.5rem 0.35rem;
    display: flex;
    flex-direction: column;
`;

const HasItemChampionItem = styled.li`
    display: flex;
`;

const HasItemChampionItemChampionBlock = styled.div`
    margin: 1.5rem 2.4rem 1.5rem 0.35rem;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HasItemChampionItemChampionImg = styled.img`
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 100px;
    margin-bottom: 0.4rem;
`;

const HasItemChampionItemChampionName = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.4px;
    color: #ffffff;
    text-align: center;
`;

const HasItemChampionItemItemBlock = styled.ul`
    margin-bottom: 0.6rem;
`;

const HasItemChampionItemItemListLi = styled.li`
    display: flex;
    align-items: center;
`;

const HasItemChampionItemItemImg = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
    border-radius: 50px;
`;

const ArrowBlock = styled.div`
    padding: 0.4rem;
`;

const RecommendMetaDetailSynergeListnadHasItemChampionList: React.FC<{
    recommendMeta: RecommendMeta;
    hasitemChampionList: EnforcedChampion[];
    itemMap: Record<string, Item>;
}> = ({ recommendMeta, hasitemChampionList, itemMap }) => {
    return (
        <RecommendMetaDetailSynergeListBlock>
            <SynergeBlock>
                <SynergeTitle>적용 시너지</SynergeTitle>
                <SynergeList>
                    {recommendMeta.synerges.map((a, index) => {
                        return (
                            <SynergeItemBlock tier={a.synerge_tier} key={index}>
                                <SynergeItem>
                                    <SynergeImg src={a.image} />
                                    <SynergeName>{a.name}</SynergeName>
                                </SynergeItem>
                            </SynergeItemBlock>
                        );
                    })}
                </SynergeList>
            </SynergeBlock>
            <HasChampionTitle>템 들고 있는 놈들</HasChampionTitle>
            <HasItemChampionList>
                {hasitemChampionList.map((a, index) => {
                    return (
                        <HasItemChampionItem key={index}>
                            <HasItemChampionItemChampionBlock>
                                <HasItemChampionItemChampionImg src={a.champion.image} />
                                <HasItemChampionItemChampionName>{a.champion.name}</HasItemChampionItemChampionName>
                            </HasItemChampionItemChampionBlock>
                            <HasItemChampionItemItemBlock>
                                {a.items.map((item, index) => {
                                    const [childItem, childItem2] = item.child_item;

                                    const itemImage1 = getPropsDefaultToEmptyStr(
                                        [childItem, 'image'],
                                        //@ts-ignore
                                        itemMap,
                                    ) as string;
                                    const itemImage2 = getPropsDefaultToEmptyStr(
                                        [childItem2, 'image'],
                                        //@ts-ignore
                                        itemMap,
                                    ) as string;

                                    return (
                                        <HasItemChampionItemItemListLi key={index}>
                                            <HasItemChampionItemItemImg src={itemImage1} alt="" />
                                            <HasItemChampionItemItemImg src={itemImage2 || itemImage1} alt="" />
                                            <ArrowBlock>{'>'}</ArrowBlock>
                                            <HasItemChampionItemItemImg src={item.image} alt="" />
                                        </HasItemChampionItemItemListLi>
                                    );
                                })}
                            </HasItemChampionItemItemBlock>
                        </HasItemChampionItem>
                    );
                })}
            </HasItemChampionList>
        </RecommendMetaDetailSynergeListBlock>
    );
};

export default RecommendMetaDetailSynergeListnadHasItemChampionList;
