import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chat, chatActions } from '../../../module/chat';
import dayjs from 'dayjs';
import { store } from '../../..';
import * as R from 'ramda';
import { getChatStorage } from '../localstorage';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyB9oWKLGWx_KeNfytuNgk4BwiGRNwz3dWY',
    authDomain: 'lolchepass.firebaseapp.com',
    databaseURL: 'https://lolchepass.firebaseio.com',
    projectId: 'lolchepass',
    storageBucket: 'lolchepass.appspot.com',
    messagingSenderId: '970999656966',
    appId: '1:970999656966:web:07d9de8263fa41ae18c987',
};

firebase.initializeApp(firebaseConfig);

// Initialize Firebase

const db = firebase.firestore().collection('chat-data/ko/chats');
// const db = firebase.firestore().collection('chat-data/ko/web-chat-test');
const docref = db.orderBy('userTime', 'asc');

export const fetchChatListObservable = (page: number) =>
    from(docref.limitToLast(1).where('userTime', '<', dayjs().toDate()).get()).pipe(
        map((a) => {
            return a.docs.map((b) => {
                const data = b.data();
                // const userdiffTodayTime = timeForToday(data.userTime);
                // return { ...data, userdiffTodayTime } as Chat;
                return data as Chat;
            });
        }),
    );

export const postChatObservable = (chat: Chat) => {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date()).toMillis();
    // const pushdata = { ...chat, userTime: firebase.firestore.FieldValue.serverTimestamp() };
    const pushdata = { ...chat, userTime: timestamp };
    return db.add(pushdata);
};

// const CHATLIMIT = 10;
const CHATLIMIT = 100;

export const addFireBaseChatListenEvent = (timestamp: number) => {
    return (
        docref
            .limitToLast(CHATLIMIT)
            // .limit(CHATLIMIT)
            // .where('userTime', '>', firebase.firestore.Timestamp.fromMillis(timestamp))
            .where('userTime', '>', timestamp)
            .onSnapshot((querySnapshot) => {
                const hostName = window.location.host;
                const today = firebase.firestore.Timestamp.fromDate(new Date());
                const localChatList = getChatStorage();

                const data = querySnapshot.docs.map(function (doc) {
                    const obj = doc.data();
                    return { ...obj, millTime: obj.userTime };
                }) as Chat[];

                localChatList.chatList = R.uniqBy(
                    (a: any) => a.millTime,
                    R.takeLast(CHATLIMIT, localChatList.chatList.concat(data)),
                );
                localChatList.timestamp = today.toMillis();
                localStorage.setItem(hostName, JSON.stringify(localChatList));
                const user = store.getState().login.loginUser.userId!;
                store.dispatch(chatActions.pushchat({ chat: localChatList.chatList, currentUser: user }));
            })
    );
};
