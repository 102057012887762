import React from 'react';
import styled from 'styled-components';
import { Item } from '../../lib/api/lolcheserver/InitdataApi';
import { ItemImageToolTipComponent } from '../common/Image';
import { ItemPageState } from '../../module/item';

interface MatrixItemListComponentProps extends Pick<ItemPageState, 'itemMatrix'> {
    mouseover: (param: { yx: Array<number>; item: Item }) => void;
    mouseout: () => void;
}

const MatrixItemBlock = styled.div`
    padding: 1.6rem 2.5rem 1.6rem 2.5rem;
    background: #202329;
    border: 1px solid rgba(255, 255, 255, 0.02);
    box-sizing: border-box;
    border-radius: 8px;
    width: 32.4rem;
`;

const MatrixItemUl = styled.ul`
    display: flex;
    li {
        margin-right: 0.4rem;
    }
    img {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 0.6rem;
    }
    margin-bottom: 0.4rem;
`;

const ItemliComponent: React.FC<{
    item: Item;
    row: number;
    column: number;
    mouseover: (param: { yx: Array<number>; item: Item }) => void;
}> = ({ item, row, column, mouseover }) => {
    const onMouseOver = (e: React.MouseEvent) => {
        mouseover({ yx: [row, column], item: item });
    };
    return (
        <li onMouseOver={onMouseOver}>
            <ItemImageToolTipComponent item={item} size={'default'} />
        </li>
    );
};

const MemoItemliComponent = React.memo(ItemliComponent, (prev, next) => prev.item.is_active === next.item.is_active);
//const MemoItemliComponent = ItemliComponent;

const MatrixRowList: React.FC<
    { itemList: Item[] } & { row: number; mouseover: (param: { yx: Array<number>; item: Item }) => void }
> = ({ itemList, row, mouseover }) => (
    <MatrixItemUl>
        {itemList.map((item, column) => (
            <MemoItemliComponent item={item} row={row} column={column} mouseover={mouseover} key={column} />
        ))}
    </MatrixItemUl>
);

const MatrixItemListComponent: React.FC<MatrixItemListComponentProps> = ({ itemMatrix, mouseover, mouseout }) => (
    <MatrixItemBlock onMouseOut={mouseout}>
        {itemMatrix.map((itemlist, row) => (
            <MatrixRowList itemList={itemlist} key={row} row={row} mouseover={mouseover} />
        ))}
    </MatrixItemBlock>
);

export default MatrixItemListComponent;
