import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import { useHistory } from 'react-router-dom';
import SearchInputAndUserListComponent from '../../component/user/SearchInputAndUserListComponent';
import { userActions } from '../../module/user';
import { UserAPIData } from '../../lib/api/lolcheserver/user';
import { searchInputAction } from '../../module/searchInput';

const SearchAndSearchedUserListContainer = () => {
    const {
        user: { nickname, user, error, userlang },
        searchInput: { isUserListOpen, favoritesUserList, latestUserList },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const history = useHistory();

    const change = (nickname: string) => {
        dispatch(userActions.inputchangenickname(nickname));
    };

    const changeUserLang = (userlang: string) => {
        dispatch(userActions.setuserlang(userlang));
    };

    const submit = (param: { nickname: string; lang: string }) => {
        dispatch(userActions.fetchuser(param));
    };

    const deleteLikeUser = (user: UserAPIData) => {
        dispatch(searchInputAction.deletefavoritesUserList(user));
    };

    const deleteLatestUser = (user: UserAPIData) => {
        dispatch(searchInputAction.deletelatestUserList(user));
    };

    useEffect(() => {
        if (error) {
            history.push(`/user/error/${error}`);
            return;
        }
        if (user && user.user_data) {
            history.push(`/user/userinfo/${user.user_data.name}/${userlang}`);
            return;
        }

        return () => {
            dispatch(userActions.setuserlang('kr'));
        };
    }, [user, error, history]);

    return (
        <SearchInputAndUserListComponent
            nickname={nickname}
            change={change}
            submit={submit}
            isUserListOpen={isUserListOpen}
            favoritesUserList={favoritesUserList}
            deleteLikeUser={deleteLikeUser}
            latestUserList={latestUserList}
            deleteLatestUser={deleteLatestUser}
            setUserLang={changeUserLang}
            userlang={userlang}
        />
    );
};

export default SearchAndSearchedUserListContainer;
