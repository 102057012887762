import React, { useEffect } from 'react';
import BuildPageLayout from '../../component/layout/BuildPageLayout';
import ChampionBuilderContainer from '../../container/championbuilder/ChampionBuilderContainer';
import ChampionSelectorContainer from '../../container/championbuilder/ChampionSelectorContainer';
import ChampionSelectorTapButtonContainer from '../../container/championbuilder/ChampionSelectorTapButtonContainer';
import styled from 'styled-components';
import Meta from '../../component/common/Meta';
import ReactGa from 'react-ga';

const BuilderTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-bottom: 1.6rem;
    // margin-top: 1.6rem;
`;

function ChampionBuilderPage() {
    const metaData = {
        title: '챔피언 빌더',
        description: '챔피언, 빌더, 시너지 조합, 조합 빌더',
    };

    useEffect(() => {
        const pathName = window.location.pathname;
        ReactGa.initialize('G-49LQN3JGJ7');
        ReactGa.set({ page: pathName });
        ReactGa.pageview(pathName);
    });
    return (
        <BuildPageLayout>
            <Meta data={metaData} />
            <BuilderTitle>빌더</BuilderTitle>
            <div>
                <ChampionBuilderContainer />
                <ChampionSelectorTapButtonContainer />
                <ChampionSelectorContainer />
            </div>
        </BuildPageLayout>
    );
}

export default ChampionBuilderPage;
