import { Item } from '../api/lolcheserver/InitdataApi';
import * as R from 'ramda';

export const BASICITEMLENGTH = 9;

export const filteringBasicItem = (a: Item) =>
    a.grade === 1 && !(a.name === '황금 징수의 총' || a.name === '영혼의 형상' || a.name === '흑요석 양날 도끼');
export const sortingKey = (a: Array<any>, b: Array<any>) => a[0] - b[0];

export const getmatrixActiveList = ([row, column]: Array<number>) => {
    if (row === 0 && column > 0) {
        return R.range(0, BASICITEMLENGTH + 1).map((a) => [a, column]);
    }
    if (column === 0 && row > 0) {
        return R.range(0, BASICITEMLENGTH + 1).map((a) => [row, a]);
    }

    return [
        [row, column],
        [0, column],
        [row, 0],
    ];
};
