import React from 'react';
import { useDispatch } from 'react-redux';
import { TooltipInfo, tooltipActions } from '../../module/tooltip';
import { ChampionImageProps } from '../../component/common/Image';
import styled from 'styled-components';

const ChampImgBlock = styled.div`
    width: 100%;
`;

const ChamImg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
`;

export const RecommendChampionImageonMouseEventHex: React.FC<ChampionImageProps> = ({
    champion,
    width,
    heigth,
    onClick,
}) => {
    const dispatch = useDispatch();
    const handleMouseover = (e: React.MouseEvent) => {
        const { x } = e.currentTarget.getBoundingClientRect();
        const absoluteTop = window.pageYOffset + e.currentTarget.getBoundingClientRect().top;

        const tooltipObject: TooltipInfo = {
            type: 'recommendchampion',
            tooltipObject: champion,
            position: {
                x: x + 50,
                y: absoluteTop + 70,
            },
            isshow: true,
        };
        dispatch(tooltipActions.mouseover(tooltipObject));
    };

    const handleMouseout = (e: React.MouseEvent) => {
        dispatch(tooltipActions.mouseout());
    };

    return (
        <ChampImgBlock>
            <ChamImg
                src={champion.image}
                onMouseOver={handleMouseover}
                /* onClick={handleMouseover} */
                onMouseOut={handleMouseout}
            />
            <div className="champion-name">{champion.name}</div>
        </ChampImgBlock>
    );
};

export default RecommendChampionImageonMouseEventHex;
