import React from 'react';
import VideoDetailPageLayout from '../../component/layout/VideoDetailPageLayout';
import VideoDetailContainer from '../../container/video/VideoContainer';
import { RouteComponentProps } from 'react-router-dom';
import Meta from '../../component/common/Meta';

function VideoPage({ match, location }: RouteComponentProps) {
    const { id } = match.params as any;
    const metaData = {
        title: '추천 영상',
        description: '추천영상 유튜브 롤토체스유튜브 롤토체스 전략적 팀전투 TFT',
    };

    return (
        <VideoDetailPageLayout>
            <Meta data={metaData} />
            <VideoDetailContainer id={id} />
        </VideoDetailPageLayout>
    );
}

export default VideoPage;
