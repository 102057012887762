import React from 'react';
import styled from 'styled-components';
import { Notice } from '../../module/notice';
import NoticeMain from '../../lib/style/noticeMain.svg';
import { noticeTypeColor } from '../../lib/style/palette';
import { getFormatDate } from '../../lib/util/time';

const NoticeLatestBlock = styled.div`
    max-width: 50.6rem;
    background: #202329;
`;

const NoticeLatestMainImg = styled.img`
    width: 100%;
`;

const NoticeContentBlock = styled.div`
    padding: 1.6rem;
`;

const NoticeLatestTitleBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.2rem;
`;

const NoticeType = styled.p<{ type: string }>`
    color: ${({ type }) => (noticeTypeColor as any)[type]};
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 0.6rem;
    line-height: 0.9rem;
    text-transform: uppercase;
    padding: 0.1rem 0.3rem;
`;

const NoticeTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.4rem;
    letter-spacing: -0.02rem;
    color: #ffffff;
`;

const NoticeDate = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 0.6rem;
    line-height: 0.9rem;
    color: #5b6370;
`;

const NoticeLatestMainContentBlock = styled.div``;

const NoticeLatestMainContent = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 1.1rem;
    letter-spacing: -0.02rem;
    color: #b6bdc4;
`;

const NoticeLatest: React.FC<{ notice: Notice }> = ({ notice }) => {
    return (
        <NoticeLatestBlock>
            <NoticeLatestMainImg src={NoticeMain} />
            <NoticeContentBlock>
                <NoticeLatestTitleBlock>
                    <NoticeType type={notice.type}>{notice.type}</NoticeType>
                    <NoticeTitle>{notice.title}</NoticeTitle>
                    <NoticeDate>{getFormatDate(notice.updated_at, 'YYYY-MM-DD')}</NoticeDate>
                </NoticeLatestTitleBlock>
                <NoticeLatestMainContentBlock>
                    <NoticeLatestMainContent>{notice.content}</NoticeLatestMainContent>
                </NoticeLatestMainContentBlock>
            </NoticeContentBlock>
        </NoticeLatestBlock>
    );
};

export default NoticeLatest;
