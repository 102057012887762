import React from 'react';
import styled, { css } from 'styled-components';
import { synergeTierColor } from '../../lib/style/palette';
import { SynergeTier, Item, Champion } from '../../lib/api/lolcheserver/InitdataApi';
import * as R from 'ramda';

//const ChampionTooltip = React.lazy(() => import('./Tooltip'));
export interface ItemImageProps {
    item: Item;
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
    size: 'small' | 'default' | 'medium';
}

const ItemImageCssSize = {
    default: css`
        width: 2.2rem;
        height: 2.2rem;
    `,
    small: css`
        width: 0.9rem;
        height: 0.9rem;
    `,
    medium: css`
        width: 1rem;
        height: 1rem;
    `,
};

export const ItemImage = styled.img<{ is_active?: boolean; size: 'small' | 'default' | 'medium' }>`
    /* margin-right: 1em;
    margin-bottom: 1em;
    width: 30px;
 */
    ${(props) => ItemImageCssSize[props.size]}
    flex-shrink: 0;
    opacity: ${(props) => {
        if (R.isNil(props.is_active)) {
            return 1;
        }
        return props.is_active ? 1 : 0.4;
    }};
`;

const EmptyImage = styled.div`
    width: 2.2rem;
    height: 2.2rem;
`;

export const ItemImageToolTipComponent: React.FC<ItemImageProps> = ({ item, onClick, size }) => {
    return item.image ? (
        <ItemImage
            src={item.image}
            is_active={item.is_active}
            data-for={'' + item.id}
            data-tip=""
            onClick={onClick}
            size={size}
        />
    ) : (
        <EmptyImage />
    );
};
export interface ChampionImageProps {
    champion: Champion;
    onClick?: (e: React.MouseEvent) => void;
    width?: string;
    heigth?: string;
}

export const ChampionImageBlock = styled.div<{ width?: string; height?: string }>`
    display: inline-block;
    position: relative;

    .champion-img {
        width: ${(props) => (props.width ? props.width : '2.9rem')};
        height: ${(props) => (props.height ? props.height : '3.2rem')};
        flex-shrink: 0;
    }
`;

/* export const MemoedChamPionImageToolTipComponent = React.memo(
    ChamPionImageToolTipComponent,
    (prev, next) => prev.champion.id === next.champion.id,
); */

interface SynergeImageProps extends Partial<HTMLImageElement> {
    //    synerge: SynergeType;
    synergetier: SynergeTier;
}

const SynergeImageBlock = styled.div<Pick<SynergeImageProps, 'synergetier'> & { width?: number; height?: number }>`
    width: ${(props) => props.width}rem;
    height: ${(props) => props.height}rem;
    position: relative;
    background-color: ${({ synergetier }) => synergeTierColor[synergetier]};
    margin-right: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    ::before {
        content: '';
        position: absolute;
        top: -${(props) => props.height! / 2}rem;
        left: 0;
        width: 0;
        height: 0;
        border-left: ${(props) => props.width! / 2}rem solid transparent;
        border-right: ${(props) => props.height! / 2}rem solid transparent;
        border-bottom: ${(props) => props.width! / 2}rem solid ${({ synergetier }) => synergeTierColor[synergetier]};
    }
    ::after {
        content: '';
        position: absolute;
        bottom: -${(props) => props.width! / 2}rem;
        left: 0;
        width: 0;
        height: 0;
        border-left: ${(props) => props.width! / 2}rem solid transparent;
        border-right: ${(props) => props.width! / 2}rem solid transparent;
        border-top: ${(props) => props.height! / 2}rem solid ${({ synergetier }) => synergeTierColor[synergetier]};
    }
`;

export const SynergeImageSmallBlock = styled.div<{ width?: number; height?: number }>`
    /* width: 0.6rem;
    height: 0.6rem; */
    width: ${(props) => props.width}rem;
    height: ${(props) => props.height}rem;
`;

export const SynergeImage = styled.img`
    flex-shrink: 0;
`;

export const UserProfileImage = styled.img`
    width: 104px;
    // height: 104px;
    border: 1px solid #41cdc7;
    border-radius: 50%;
`;

export const SynergeImageComponent: React.FC<SynergeImageProps> = ({ src, synergetier, width = 0.8, height = 0.8 }) => (
    <SynergeImageBlock synergetier={synergetier} width={width} height={height}>
        <SynergeImage src={src} />
    </SynergeImageBlock>
);
export const SynergeSmallImageComponent: React.FC<Partial<HTMLImageElement>> = ({ src, width, height, className }) => (
    <SynergeImageSmallBlock width={width} height={height}>
        <SynergeImage src={src} className={className} />
    </SynergeImageSmallBlock>
);
