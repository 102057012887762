import { from, forkJoin } from 'rxjs';
import { pluck } from 'rxjs/operators';
import apiInstance from './maininstance';

export type SynergeTier = 0 | 1 | 2 | 3 | 4 | 10;

export interface TotalAPI {
    champions: Champion[];
    synerge: Synerge;
    item: Item[];
    recommend_meta: RecommendMeta[];
    curation_video: CurationVideo;
    current_version: CurrentVersion;
}

export interface Champion {
    id: number;
    image: string;
    synerge: ChampionSynerge;
    synerges?: SynergeType[];
    skill_image: string;
    lang: Lang;
    key: string;
    name: string;
    tier: number;
    cost: number;
    attack_distance: number;
    search_keywords: any[];
    skill_name: string;
    skill_description: string;
    skill_mana: number;
    skill_attribute_1: string;
    skill_attribute_value_1: string;
    skill_attribute_2: null | string;
    skill_attribute_value_2: null | string;
    skill_attribute_3: null | string;
    skill_attribute_value_3: null | string;
    is_active: boolean;
    isSelected: boolean;
}
export enum Lang {
    Ko = 'ko',
}

export interface ChampionSynerge {
    origin: number[];
    class: number[];
}

export interface Item {
    id: number;
    name: string;
    image: string;
    grade: number;
    description: null | string;
    attribute_1: null | string;
    attribute_2: null | string;
    child_item: number[];
    item_combination: { [key: string]: number };
    is_active?: boolean;
}

export interface Synerge {
    origin: SynergeType[];
    class: SynergeType[];
}

export interface SynergeType {
    id: number;
    name: string;
    image: string;
    champion_list: number[];
    champions?: Champion[];
    effect_1: string;
    effect_count_1: number;
    effect_2: null | string;
    effect_count_2: number | null;
    effect_3: null | string;
    effect_count_3: number | null;
    effect_4: null | string;
    effect_count_4: number | null;
    description: string;
}

export type CombinationItem = [Item, Item, Item];

export type EnforcedChampion = { champion: Champion; items: Item[]; position: number };

export interface RecommendMeta {
    id: number;
    title: string;
    champions: EnforcedChampion[];
    synerges: Array<Pick<SynergeType, 'name' | 'image'> & { synerge_tier: SynergeTier }>;
    patch_version: string;
    source: string;
    priority: number;
    is_active: boolean;
    like_cnt: number;
}

export interface YoutubeVideo {
    channel: string;
    channel_img: string;
    id: number;
    main_img: string;
    published_at: string;
    title: string;
    url: string;
    video_id: string;
    view_cnt: number;
}

export interface CurationVideo {
    id: number;
    like_cnt: number;
    sub_title: string;
    title: string;
    youtube_videos: YoutubeVideo[];
}

/* export function fetchInitData() {
    return from(initDataInstance.get<TotalAPI>('init-data/init-data.json')).pipe(
        pluck('data'),
        //     tap((a) => console.log(a, 'asdasd')),
    );
} */

const getInitData$ = from(apiInstance.get('/init-data')).pipe(pluck('data'));
const getVersion$ = from(apiInstance.get('/check-version')).pipe(pluck('data'));

export interface CurrentVersion {
    server_version: string;
    season: number;
    patchnote_url: string;
    android_force_update: number;
    ios_force_update: number;
    server_update_version: string;
    advertisement: Advertisement;
}

export interface Advertisement {
    name: string;
    margin: number;
    option: AdOption;
}

export interface AdOption {
    count: number;
    group: string;
    minutes: any[];
}

export function fetchInitData() {
    // const initdata = localStorage.getItem('initdata');
    // if (initdata) {
    //     return of(JSON.parse(initdata));
    // }
    // return from(apiInstance.get('/init-data')).pipe(
    //     pluck('data'),
    return forkJoin([getInitData$, getVersion$]);
    /* pluck('url'),
        mergeMap((url: string) => {
            return from(
                axios({
                    method: 'get',
                    url,
                }),
            ).pipe(pluck('data'));
        }), */
}
