import React from 'react';
import { Route } from 'react-router-dom';
import MainPage from './MainPage';

function MainPageIndex() {
    return (
        <>
            <Route path={`/`} component={MainPage} />
        </>
    );
}

export default MainPageIndex;
