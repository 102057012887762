import React from 'react';
import styled from 'styled-components';
import { BuilderType } from '../../module/championbuilder';
import { SynergeSmallImageComponent } from '../common/Image';
import { Champion, SynergeTier } from '../../lib/api/lolcheserver/InitdataApi';
import palette, { synergeTierColor } from '../../lib/style/palette';
import RecommendChampionImageonMouseEvent from '../../container/common/RecommendChampionImageonMouseEvent';
import * as R from 'ramda';

const ChampionBuilderBlock = styled.div`
    background-color: ${palette.GRAY04};
    width: 49rem;
    background: #202329;
    border: 1.00816px solid rgba(255, 255, 255, 0.02);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 9.8rem;
    align-items: center;
`;

const ChampionBuildListUl = styled.ul`
    display: flex;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;

    li {
        padding-right: 0.8rem;
    }
`;

const SynergeStaticUl = styled.ul`
    display: flex;
    width: 45.2rem;
`;

const SynergeStaticItem = styled.li<{ synergetier: number }>`
    display: flex;
    background-color: ${(props) => synergeTierColor[props.synergetier as SynergeTier]};
    height: 1.5rem;
    font-size: 0.7rem;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-right: 0.4rem;
    color: white;
    padding: 0.45rem 0.5rem;
    div {
        margin-right: 0.2rem;
    }
`;

const EmptyItem = styled.div`
    width: 3.6rem;
    height: 3.6rem;
    flex-shrink: 0;
    border-radius: 100px;
    background: rgba(48, 53, 64, 0.15);
    border: 1px solid rgba(48, 53, 64, 0.5);
    box-sizing: border-box;
`;

const ChampionBuildItem: React.FC<{ champion: Champion; onClick: (id: number) => void }> = ({ champion, onClick }) => {
    if (R.isEmpty(champion)) {
        return (
            <li>
                <EmptyItem />
            </li>
        );
    }
    const click = (e: React.MouseEvent) => {
        onClick(champion.id);
    };

    return (
        <li onClick={click}>
            <RecommendChampionImageonMouseEvent
                champion={champion}
                width="3.6rem"
                heigth="3.6rem"
                isNoChampionName={true}
            />
        </li>
    );
};

const ChampionBuilderComponent: React.FC<BuilderType & { onClick: (id: number) => void }> = ({
    chamList,
    synergestaticList,
    onClick,
}) => {
    return (
        <ChampionBuilderBlock>
            <ChampionBuildListUl>
                {chamList.map((champion, index) => (
                    <ChampionBuildItem champion={champion} onClick={onClick} key={index} />
                ))}
            </ChampionBuildListUl>
            <SynergeStaticUl>
                {synergestaticList.map(({ synerge, count, synergetier }, index) => (
                    <SynergeStaticItem key={index} synergetier={synergetier}>
                        <SynergeSmallImageComponent src={synerge.image} width={0.6} height={0.6} />
                        <div>{`${synerge.name}(${count})`}</div>
                    </SynergeStaticItem>
                ))}
            </SynergeStaticUl>
        </ChampionBuilderBlock>
    );
};
export default ChampionBuilderComponent;
