import styled from 'styled-components';

const NoticePageLayout = styled.div`
    position: relative;
    // left: 20%;
    top: 5%;
    min-height: 45rem;
`;

export default NoticePageLayout;
