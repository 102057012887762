import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../module/rootReducer';
import { videoActions } from '../../module/video';
import TotalVideoDetailComponent from '../../component/video/TotalVideoDetailComponent';
import { useHistory } from 'react-router-dom';

const VideoDetailContainer: React.FC<{ id: number }> = ({ id }) => {
    const { videoDetail } = useSelector((state: RootState) => state.video);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(videoActions.fetchvideodetail(id));
    }, [id, dispatch]);

    const clickListVideo = (id: number) => {
        history.push(`/video/${id}`);
    };

    return <TotalVideoDetailComponent videoDetail={videoDetail} clickListVideo={clickListVideo} />;
};

export default VideoDetailContainer;
