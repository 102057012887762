import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import UserranksListPage from './UserranksListPage';
import UserInfoPage from './UserInfoPage';
import UserInfoErrorPage from './UserInfoErrorPage';

function User({ match }: RouteComponentProps) {
    return (
        <>
            <Route exact path={`${match.path}`} component={UserranksListPage} />
            <Route exact path={`${match.path}/userinfo/:nickname/:lang`} component={UserInfoPage} />
            <Route exact path={`${match.path}/error/:code`} component={UserInfoErrorPage} />
        </>
    );
}

export default User;
