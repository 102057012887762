import React from 'react';
import styled from 'styled-components';
import { synergeTierColor } from '../../lib/style/palette';

const propotion = 1.104167;

export const Hexa = styled.div<{ width: number; color?: string }>`
    display: flex;
    width: ${(props) => props.width}px;
    height: ${(props) => props.width * propotion}px;
    background-color: ${(props) => props.color};
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    border: 1.95588px solid rgba(255, 255, 255, 0.02);
    box-sizing: border-box;
    margin-bottom: 10px;
    // margin-right: 11px;
`;

export const ChampionPositionHexaImg: React.FC = ({ children }) => {
    return (
        <Hexa width={80} color="#1c1e23">
            {children}
        </Hexa>
    );
};

// export const SynergeImgHexa: React.FC = ({ children }) => {
//     return <Hexa width={25}>{children}</Hexa>;
// };

export const SynergeImgHexa: React.FC<{ width: number; tier: number; image: string }> = ({ width, tier, image }) => {
    return (
        <Hexa width={width} color={synergeTierColor[tier]}>
            <img src={image} alt="" />
            {/* <img src={Test} alt="" /> */}
        </Hexa>
    );
};

// export default HexaImg;
