import React, { useEffect } from 'react';
import MatrixItemListContainer from '../../container/item/item2/MatrixItemListContainer';
import ItemDescriptionContainer from '../../container/item/item2/ItemDescriptionContainer';
import ItemPageLayout from '../../component/layout/ItemPageLayout';
import styled from 'styled-components';
import Meta from '../../component/common/Meta';
import ReactGa from 'react-ga';

const ItemTitleBlock = styled.div`
    float: left;
    margin-bottom: 1.2rem;
`;
const ItemTitle = styled.p`
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-top: 1.6rem;
`;

const ContentBlock = styled.div`
    display: flex;
`;

function ItemPage2() {
    const metaData = {
        title: '아이템',
        description: '아이템 조합, 아이템 내용 , 아이템 설명',
    };
    useEffect(() => {
        const pathName = window.location.pathname;
        ReactGa.initialize('G-49LQN3JGJ7');
        ReactGa.set({ page: pathName });
        ReactGa.pageview(pathName);
    });

    return (
        <ItemPageLayout>
            <Meta data={metaData} />
            <ItemTitleBlock>
                <ItemTitle>아이템</ItemTitle>
            </ItemTitleBlock>
            <ContentBlock>
                <MatrixItemListContainer />
                <ItemDescriptionContainer />
            </ContentBlock>
        </ItemPageLayout>
    );
}

export default ItemPage2;
