import styled from 'styled-components';
import palette from '../../lib/style/palette';

const MainPageLayout = styled.div`
    // margin-top: 2rem;
    .flex-item {
        margin: 1em 1em;
    }
    // margin-bottom: 10rem;

    min-height: 50rem;
    background-color: ${palette.GRAY01};
    /* @media (min-width: 768px) {
        justify-content: center;
        align-items: center;
    } */
`;

export default MainPageLayout;
