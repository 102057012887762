import { ofType, StateObservable, combineEpics } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CombinationItem, Item } from '../lib/api/lolcheserver/InitdataApi';
import { createAction, createReducer } from '../lib/reduxHelper';
import { _arrayIncludes } from '../lib/util';
import { filteringBasicItem, sortingKey, getmatrixActiveList } from '../lib/util/itemUtil';
import { RootState } from './rootReducer';
import * as R from 'ramda';

export interface ItemPageState {
    totalItemList: Item[];
    basicItemList: Item[];
    combinationItemList: CombinationItem[];
    indexedItemMap: {
        [key: string]: Item;
    };
    // itemMatrix: [Item[]];
    itemMatrix: Item[][];
    itemDescription: Item;
    isitemDescriptionOpen: boolean;
}

export enum ActionType {
    INITITEMLIST = 'item/initItemList',
    //    SETBASICITEMLIST = 'item/setBasicItemList',
    SETCOMBINATIONTEMLIST = 'item/setCombiNaTionItemList',
    MATRIXMOUSEOVER = 'item/matrixmouseover',
    MATRIXMOUSEOUT = 'item/matrixmouseout',
    SETITEMMATRIX = 'item/setitemmatrix',
    SETITEMDESCRIPTION = 'item/setitemdescription',
    OPENITEMDESC = 'item/openitemdesc',
    CLOSEITEMDESC = 'item/closeitemdesc',
}

export const itemActions = {
    setInitItemList: (itemList: Item[]) => createAction(ActionType.INITITEMLIST, itemList),
    setCombinationItemList: (itemlist: CombinationItem[]) => createAction(ActionType.SETCOMBINATIONTEMLIST, itemlist),
    matrixMouseOver: (param: { yx: Array<number>; item: Item }) => createAction(ActionType.MATRIXMOUSEOVER, param),
    matrixMouseOut: () => createAction(ActionType.MATRIXMOUSEOUT),
    setItemMatrix: (itemMatrix: [Item[]]) => createAction(ActionType.SETITEMMATRIX, itemMatrix),
    setItemDescription: (item: Item) => createAction(ActionType.SETITEMDESCRIPTION, item),
    openItemDesc: () => createAction(ActionType.OPENITEMDESC),
    closeItemDesc: () => createAction(ActionType.CLOSEITEMDESC),
};

type Action = ReturnType<typeof itemActions[keyof typeof itemActions]>;

const intialState: ItemPageState = {
    totalItemList: [],
    basicItemList: [],
    combinationItemList: [],
    indexedItemMap: {},
    itemMatrix: [[]],
    itemDescription: {} as Item,
    isitemDescriptionOpen: false,
};

const ItemMouseOverEpic = (
    action$: Observable<ReturnType<typeof itemActions['matrixMouseOver']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.MATRIXMOUSEOVER),
        mergeMap((action) => {
            const isactive = _arrayIncludes(getmatrixActiveList(action.payload.yx));
            const itemMatrix = state.value.item.itemMatrix;
            const updateItemMatrix = itemMatrix.map((itemlist, row) => {
                return itemlist.map((item, column) => {
                    return isactive([row, column]) ? { ...item, is_active: true } : { ...item, is_active: false };
                });
            }) as [Item[]];
            return of(
                itemActions.setItemMatrix(updateItemMatrix),
                itemActions.setItemDescription(action.payload.item),
                itemActions.openItemDesc(),
            );
        }),
    );
const ItemMouseOutEpic = (
    action$: Observable<ReturnType<typeof itemActions['matrixMouseOut']>>,
    state: StateObservable<RootState>,
) =>
    action$.pipe(
        ofType(ActionType.MATRIXMOUSEOUT),
        mergeMap((action) => {
            const itemMatrix = state.value.item.itemMatrix;
            const updateItemMatrix = itemMatrix.map((itemlist) => {
                return itemlist.map((item) => {
                    return { ...item, is_active: true };
                });
            }) as [Item[]];
            return of(itemActions.setItemMatrix(updateItemMatrix), itemActions.closeItemDesc());
        }),
    );
const reducer = createReducer<ItemPageState, Action>(intialState, {
    [ActionType.INITITEMLIST]: (state, action) => {
        const itemList = action.payload.map((item) => {
            const childItem =
                R.path(['child_item', 'length'], item) === 1
                    ? R.concat(item.child_item, item.child_item)
                    : item.child_item;

            return { ...item, is_active: true, child_item: childItem };
        });
        const basicItemList = itemList.filter(filteringBasicItem).sort((a: Item, b: Item) => a.id - b.id);
        const indexedItemMap = R.indexBy((a: Item) => a.id, itemList);
        const getChildrenCombitionList = R.pipe<Item, Record<string, number>, Array<[string, number]>, any, Item[]>(
            R.prop('item_combination'),
            R.toPairs,
            R.sort(sortingKey),
            R.map(([key, value]: any[]) => indexedItemMap[value]),
        );

        state.totalItemList = itemList;
        state.basicItemList = basicItemList;
        state.indexedItemMap = indexedItemMap;
        state.itemMatrix = R.prepend(
            R.prepend({} as Item, basicItemList),
            R.map((item: Item) => {
                return R.prepend(item, getChildrenCombitionList(item));
            }, basicItemList),
        );
    },
    [ActionType.SETCOMBINATIONTEMLIST]: (state, action) => {
        state.combinationItemList = action.payload;
    },
    [ActionType.MATRIXMOUSEOVER]: (state, action) => {
        // state = state;
    },
    [ActionType.MATRIXMOUSEOUT]: (state, action) => {
        // state = state;
    },
    [ActionType.SETITEMMATRIX]: (state, action) => {
        state.itemMatrix = action.payload;
    },
    [ActionType.SETITEMDESCRIPTION]: (state, action) => {
        state.itemDescription = action.payload;
    },
    [ActionType.OPENITEMDESC]: (state, action) => {
        state.isitemDescriptionOpen = true;
    },
    [ActionType.CLOSEITEMDESC]: (state, action) => {
        state.isitemDescriptionOpen = false;
    },
});

export const itemCombineEpics = combineEpics(ItemMouseOverEpic, ItemMouseOutEpic);

export default reducer;
