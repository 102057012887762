import { Synerge, SynergeType } from '../lib/api/lolcheserver/InitdataApi';
import { createAction, createReducer } from '../lib/reduxHelper';
import * as R from 'ramda';

export interface SynergePageState {
    totalSynergeList: Synerge;
    indexedSynergeMap: {
        [key: string]: SynergeType;
    };
}

export enum ActionType {
    INITSYNERGELIST = 'synerge/initsynergelist',
    //    SETBASICITEMLIST = 'item/setBasicItemList',
}

export const synergeActions = {
    setInitSynergeList: (synergeList: Synerge) => createAction(ActionType.INITSYNERGELIST, synergeList),
};

type Action = ReturnType<typeof synergeActions[keyof typeof synergeActions]>;

const intialState: SynergePageState = {
    totalSynergeList: {
        origin: [],
        class: [],
    },
    indexedSynergeMap: {},
};

const reducer = createReducer<SynergePageState, Action>(intialState, {
    [ActionType.INITSYNERGELIST]: (state, action) => {
        const synergeList = action.payload;
        const concatSynerge = synergeList.class.concat(synergeList.origin);
        state.totalSynergeList = synergeList;
        state.indexedSynergeMap = R.indexBy((synerge: SynergeType) => synerge.id, concatSynerge);
    },
});

export default reducer;
